import { JOBSTATUS } from "@ero/app-common/enums";
import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { MarkerClusterer } from "@react-google-maps/api";
import { MapParcel, MapV2, ZoomControlV2 } from "Components";
import {
  BottomControls,
  TopControls,
} from "Components/map/components/controls";
import { ImportParcelTableRow } from "Screens/parcels/types";
import { AppState } from "Store";
import { useTheme } from "Theme";
import { markerIcons } from "Utils";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SplitMergeControls } from "./controls";
import { SplitLine } from "./figures/splitLine";
import { InfoText } from "./infoText";
import { Legend } from "./legend";
import { POLYGON_EDIT_MODE } from "./utils";

const PARCEL_CLUSTER_MAXZOOM = 15;

interface IMap {
  parcels: ImportParcelTableRow[];
  selectedParcelIds: number[];
  editParcelId: GridRowId | undefined;
  mergeParcelIds: number[];
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
  splitLineRef: React.MutableRefObject<google.maps.Polyline | null>;
  onParcelClick: (id: number) => void;
  editMode: POLYGON_EDIT_MODE;
  polygonOperations: {
    splitParcel: () => void;
    mergeParcels: () => void;
    enableMergeMode: () => void;
    enableSplitMode: () => void;
    cancelOperation: () => void;
  };
}

export const ImportMap: React.FC<IMap> = ({
  parcels,
  selectedParcelIds,
  editParcelId,
  mergeParcelIds,
  mapRef,
  splitLineRef,
  onParcelClick,
  editMode,
  polygonOperations: {
    splitParcel,
    mergeParcels,
    enableMergeMode,
    enableSplitMode,
    cancelOperation,
  },
}) => {
  const colorMode = useSelector((store: AppState) => store.auth.colorMode);
  const companyLocation = useSelector(
    (store: AppState) => store.auth.companyData.machinePosition,
  );

  const theme = useTheme(colorMode as "light" | "dark");

  const [zoom, setZoom] = useState<number>(13);

  const onZoomChanged = useCallback(() => {
    const currentZoom = mapRef?.current?.getZoom();
    if (currentZoom) {
      setZoom(currentZoom);
    }
  }, [mapRef]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      mapRef.current = mapInstance;
      const bounds = new google.maps.LatLngBounds();
      parcels
        .map((parcel) => parcel.shape)
        .flat()
        .forEach((coord) => bounds.extend(coord));
      mapRef.current?.fitBounds(bounds);
    },
    [mapRef, parcels],
  );

  const getFillColorAndCustomIcon = useCallback(
    (id: number) => {
      const parcelInEditMode =
        (editParcelId !== undefined && id === +editParcelId) ||
        (editMode === POLYGON_EDIT_MODE.MERGE && mergeParcelIds.includes(id));
      const parcelSelected =
        editMode === POLYGON_EDIT_MODE.NONE && selectedParcelIds.includes(id);

      if (parcelInEditMode) {
        return {
          fillColor: theme.palette.status.driving,
          customIcon: markerIcons[JOBSTATUS.DRIVING_TO_LOCATION],
        };
      } else if (parcelSelected) {
        return {
          fillColor: theme.palette.status.done,
          customIcon: markerIcons[JOBSTATUS.DONE],
        };
      }

      return {
        fillColor: theme.palette.status.default,
        customIcon: markerIcons[-1],
      };
    },
    [
      editMode,
      editParcelId,
      mergeParcelIds,
      selectedParcelIds,
      theme.palette.status.default,
      theme.palette.status.done,
      theme.palette.status.driving,
    ],
  );

  const showPolygon = useMemo(
    () => (zoom ? zoom > PARCEL_CLUSTER_MAXZOOM : false),
    [zoom],
  );

  const parcelPolygons = useMemo(
    () => (
      <MarkerClusterer
        options={{
          maxZoom: PARCEL_CLUSTER_MAXZOOM,
          averageCenter: true,
        }}
      >
        {(clusterer) => (
          <>
            {parcels.map((parcel, index) => {
              const { fillColor, customIcon } = getFillColorAndCustomIcon(
                parcel._id,
              );
              return (
                <MapParcel
                  key={index}
                  showPolygon={showPolygon}
                  parcel={parcel}
                  itemOnClick={onParcelClick}
                  fillColor={fillColor}
                  markerProps={{
                    clusterer,
                    customIcon,
                  }}
                />
              );
            })}
          </>
        )}
      </MarkerClusterer>
    ),
    [getFillColorAndCustomIcon, onParcelClick, parcels, showPolygon],
  );

  const isSplitMode = useMemo(
    () => editMode == POLYGON_EDIT_MODE.SPLIT,
    [editMode],
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <MapV2
        id="import-selection-map"
        center={companyLocation}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
      >
        {parcelPolygons}
        <SplitLine
          lineRef={splitLineRef}
          isDrawing={isSplitMode}
          mapRef={mapRef}
        />
        <InfoText editMode={editMode} />
        <Legend editMode={editMode} />
        <TopControls>
          {editParcelId !== undefined && (
            <SplitMergeControls
              editMode={editMode}
              cancelOperation={cancelOperation}
              enableMergeMode={enableMergeMode}
              enableSplitMode={enableSplitMode}
              mergeParcels={mergeParcels}
              splitParcel={splitParcel}
            />
          )}
        </TopControls>
        <BottomControls>
          <ZoomControlV2 zoom={zoom} onZoomChanged={setZoom} />
        </BottomControls>
      </MapV2>
    </Box>
  );
};
