import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { Ref } from "react";
import { useTranslation } from "react-i18next";

export type SelectionTableToolbarProps = {
  onAddClick?: () => void;
  addLabel?: string;
  setColumnsButtonEl: Ref<HTMLButtonElement> | undefined;
  hideSearchBox?: boolean;
};

export const SelectionTableToolbar: React.FC<SelectionTableToolbarProps> = ({
  onAddClick,
  addLabel,
  setColumnsButtonEl,
  hideSearchBox = false,
}) => {
  const [t] = useTranslation();
  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between", p: 3 }}>
      {!hideSearchBox && (
        <Box id="table-toolbar-start-slot">
          <GridToolbarQuickFilter />
        </Box>
      )}
      <Box id="table-toolbar-end-slot">
        <GridToolbarColumnsButton ref={setColumnsButtonEl} />
        {onAddClick && (
          <Button
            color="primary"
            variant="contained"
            sx={{ ml: 1 }}
            startIcon={<Add />}
            onClick={onAddClick}
          >
            {addLabel || t("general.buttons.create")}
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
};
