import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import * as Yup from "yup";

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED),
    serviceKind: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
    billingUnit: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
    additionalName: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    description: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    cost: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE)
      .required(VALIDATION_ERRORS.REQUIRED),
    difficulty: Yup.string()
      .matches(
        VALIDATION_REGEX.durationWithSeconds,
        VALIDATION_ERRORS.VALID_TIME_WITH_SECONDS,
      )
      .required(VALIDATION_ERRORS.REQUIRED),
    notes: Yup.string(),
  });
