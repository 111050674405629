import FullCalendar from "@fullcalendar/react";

export const scrollToTime = (
  calendarRef: React.MutableRefObject<FullCalendar | null>,
  customTime?: number,
) => {
  const calendarApi = calendarRef?.current?.getApi();

  if (!calendarApi) return;

  if (customTime) {
    calendarApi.scrollToTime(
      new Date(customTime - 30 * 60 * 1000).toTimeString().split(" ")[0],
    );
    return;
  }

  const currentTimestamp = Date.now();

  if (
    currentTimestamp >= calendarApi.view.currentStart.getTime() &&
    currentTimestamp <= calendarApi.view.currentEnd.getTime()
  ) {
    calendarApi.scrollToTime(
      new Date(currentTimestamp - 30 * 60 * 1000).toTimeString().split(" ")[0],
    );
    return;
  }

  calendarApi.scrollToTime("06:00:00");
};
