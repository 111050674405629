import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { ChevronRight } from "@mui/icons-material";
import { Fab, Grid2 } from "@mui/material";
import { Loader } from "Components";
import { type AppState } from "Store";
import {
  filterOrders,
  getCalendarInitial,
  resetMeta,
  setFilters,
} from "Store/planning";
import { FilterType } from "Store/planning/types";
import { CalendarDateRange } from "Types";
import { getInitialDateRange } from "Utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CalendarWrapper, SelectMachineModal, Sidebar } from "./components";

export const Planning: React.FC = () => {
  const dispatch = useDispatch();
  const { state: routerState } = useLocation();

  const {
    calendarCurrentView,
    initialMeta,
    loading,
    displayedDateRange,
    filters: filtersState,
  } = useSelector((state: AppState) => state.planning);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [plannedJob, setPlannedJob] = useState<JobResponseBody>();
  const [isSelectMachineModalOpen, setIsSelectMachineModalOpen] =
    useState(false);
  const [droppableContainerEl, setDroppableContainerEl] =
    useState<HTMLDivElement>();

  const initalizeComponent = useCallback(() => {
    const initialDate = routerState?.initialDate;
    const orderId = routerState?.orderId;

    const initialDateRange = getInitialDateRange(
      calendarCurrentView,
      initialDate || displayedDateRange.start,
    );
    let ordersDateRange: CalendarDateRange | undefined = initialDateRange;

    if (orderId && !initialDate) {
      ordersDateRange = undefined;
    }

    dispatch(getCalendarInitial(initialDateRange, ordersDateRange));
    return (): void => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarCurrentView, routerState?.initialDate]);

  useEffect(() => initalizeComponent(), [initalizeComponent]);

  useEffect(() => {
    if (filtersState.ordersDateRange) {
      const filters: FilterType = { ...filtersState };
      filters.ordersDateRange = displayedDateRange;
      dispatch(setFilters(filters));
      dispatch(filterOrders(filters));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDateRange]);

  const toggleIsSidebarCollapsed = useCallback(() => {
    setIsSidebarCollapsed((curr) => !curr);
  }, []);

  const openSelectMachineModal = useCallback(
    () => setIsSelectMachineModalOpen(true),
    [],
  );

  const closeSelectMachineModal = useCallback(
    () => setIsSelectMachineModalOpen(false),
    [],
  );

  if (initialMeta.loading) {
    return <Loader />;
  }

  return (<>
    <Grid2 container height="100%">
      <Grid2
        sx={
          isSidebarCollapsed
            ? {
                display: "none",
              }
            : {}
        }
        height="100%"
        size={{
          xs: isSidebarCollapsed ? 0 : 12,
          sm: 5,
          md: 4,
          lg: 3,
          xl: 2
        }}>
        <Sidebar
          toggleSidebar={toggleIsSidebarCollapsed}
          setDroppableContainerEl={setDroppableContainerEl}
        />
      </Grid2>
      <Grid2
        height="100%"
        position="relative"
        size={{
          xs: isSidebarCollapsed ? 12 : 0,
          sm: isSidebarCollapsed ? 12 : 7,
          md: isSidebarCollapsed ? 12 : 8,
          lg: isSidebarCollapsed ? 12 : 9,
          xl: isSidebarCollapsed ? 12 : 10
        }}>
        {isSidebarCollapsed && (
          <Fab
            sx={{
              position: "absolute",
              top: 23,
              left: 23,
              zIndex: 1000,
            }}
            size="small"
            onClick={toggleIsSidebarCollapsed}
          >
            <ChevronRight fontSize="small" />
          </Fab>
        )}
        <CalendarWrapper
          isSidebarCollapsed={isSidebarCollapsed}
          droppableContainerEl={droppableContainerEl}
          openSelectMachineModal={openSelectMachineModal}
          setPlannedJob={setPlannedJob}
          inititalDate={routerState?.initialDate || displayedDateRange.start}
          scrollTime={routerState?.scrollTime}
        />
      </Grid2>
    </Grid2>
    {loading && <Loader />}
    <SelectMachineModal
      isOpen={isSelectMachineModalOpen}
      onClose={closeSelectMachineModal}
      plannedJob={plannedJob}
    />
  </>);
};
