import { VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import * as Yup from "yup";

export type ValuesType = {
  password: string;
  confirmPassword: string;
};

export const initialValues: ValuesType = {
  password: "",
  confirmPassword: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .matches(VALIDATION_REGEX.password, {
        message: VALIDATION_ERRORS.NO_SAFE_PASSWORD,
      })
      .required(VALIDATION_ERRORS.REQUIRED),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], VALIDATION_ERRORS.NOT_MATCHED_PASSWORD)
      .required(VALIDATION_ERRORS.REQUIRED),
  });
