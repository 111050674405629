import {
  type ExternalAccessAction,
  EXTERNAL_ACCESS_ACTION_TYPES,
} from './action-types';

type ExternalAccesStateType = {
  accessToken: string;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: ExternalAccesStateType = {
  accessToken: '',
  success: false,
  loading: true,
  error: false,
};

export const externalAccessReducer = (
  state = initialState,
  action: ExternalAccessAction
) => {
  switch (action.type) {
    case EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_REQUEST:
      return {
        ...state,
        accessToken: '',
        success: false,
        error: false,
        loading: true,
      };
    case EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        success: true,
        error: false,
        loading: false,
      };
    case EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
