import { type Action } from "redux";
import { type DropdownRow } from "Types";

export enum ORGANIZATION_ACTION_TYPES {
  SET_LOADING = "organization/set-loading",
  SET_SUCCESS = "organization/set-success",
  RESET_META = "organization/reset-meta",
  SET_ERROR = "organization/set-error",
  RESET_STATE = "organization/reset-state",
  GET_ALL_DROPDOWNS = "organization/get-all-dropdowns",
  GET_ALL_DROPDOWNS_SUCCESS = "organization/get-all-dropdowns-success",
  ADD_DROPDOWN = "organization/add-dropdown",
  ADD_DROPDOWN_SUCCESS = "organization/add-dropdown-success",
  REMOVE_DROPDOWN = "organization/remove-dropdown",
  REMOVE_DROPDOWN_SUCCESS = "organization/remove-dropdown-success",
  UPDATE_DROPDOWN = "organization/update-dropdown",
  UPDATE_DROPDOWN_SUCCESS = "organization/update-dropdown-success",
}
export interface IGetAllDropdowns extends Action {
  type: ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS;
  key;
}
export interface IGetAllDropdownsSuccess extends Action {
  type: ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS_SUCCESS;
  payload: {
    name: string;
    dropDown: DropdownRow[];
  };
}
export interface IAddDropdown extends Action {
  type: ORGANIZATION_ACTION_TYPES.ADD_DROPDOWN;
  payload: {
    key: string;
    name: string;
    meta: any;
  };
}
export interface IAddDropdownSuccess extends Action {
  type: ORGANIZATION_ACTION_TYPES.ADD_DROPDOWN_SUCCESS;
  payload: {
    key: string;
    name: string;
  };
}
export interface IRemoveDropdown extends Action {
  type: ORGANIZATION_ACTION_TYPES.REMOVE_DROPDOWN;
  payload: {
    key: string;
    id: string;
  };
}
export interface IUpdateDropdown extends Action {
  type: ORGANIZATION_ACTION_TYPES.UPDATE_DROPDOWN;
  payload: {
    id: string;
    name: string;
    key: string;
    meta: any;
  };
}
export interface ISetLoadingAction extends Action {
  type: ORGANIZATION_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: ORGANIZATION_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: ORGANIZATION_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: ORGANIZATION_ACTION_TYPES.RESET_META;
}

export interface IResetStateAction extends Action {
  type: ORGANIZATION_ACTION_TYPES.RESET_STATE;
}

export type OrganizationAction =
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IGetAllDropdowns
  | IGetAllDropdownsSuccess
  | IAddDropdown
  | IRemoveDropdown
  | IUpdateDropdown
  | IAddDropdownSuccess
  | IResetStateAction;
