import { AppLoader } from "Components/AppLoader";
import { type AppState } from "Store";
import { initialAuthRequest } from "Store/auth";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EroDrawer from "./components/drawer/erodrawer";

export const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { initialLoading } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (initialLoading) {
      dispatch(initialAuthRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading]);

  if (initialLoading) {
    return <AppLoader />;
  }

  return <EroDrawer>{children}</EroDrawer>;
};
