import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import { file2base64 } from "Utils";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { getCroppedImg, type AreaPixels, type Point } from "./helpers";

interface IAvatarCropModal {
  open: boolean;
  onClose: () => void;
  selectedImage?: File;
  onSubmit: (value: File) => void;
}

export const AvatarCropModal: React.FC<IAvatarCropModal> = ({
  open,
  onClose,
  selectedImage,
  onSubmit,
}) => {
  const [base64Image, setBase64Image] = useState("");
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<AreaPixels>();

  const [t] = useTranslation();

  useEffect(() => {
    if (selectedImage) {
      file2base64(selectedImage).then((image: string) => setBase64Image(image));
    }
  }, [selectedImage]);

  if (!selectedImage) {
    return null;
  }

  const onCloseHandler = () => {
    setBase64Image("");
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(undefined);
    onClose();
  };

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onCropComplete = (_, newCroppedAreaPixels: AreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const decreaseZoom = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.1);
    }
  };

  const increaseZoom = () => {
    if (zoom < 3) {
      setZoom(zoom + 0.1);
    }
  };

  const onSubmitHandler = async () => {
    try {
      const croppedImage: File = (await getCroppedImg(
        base64Image,
        croppedAreaPixels!,
      )) as File;

      onSubmit(croppedImage);
      onCloseHandler();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <DialogTitle>{t("general.labels.avatarUploadTitle")}</DialogTitle>
      <DialogContent dividers>
        <Cropper
          style={{
            cropAreaStyle: { width: 500 },
            containerStyle: { width: 500, height: 600, position: "relative" },
          }}
          image={base64Image}
          maxZoom={3}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={true}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={4}
          sx={{ width: 400 }}
          alignItems="center"
        >
          <IconButton onClick={decreaseZoom}>
            <RemoveOutlined />
          </IconButton>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom as number)}
          />
          <IconButton onClick={increaseZoom}>
            <AddOutlined />
          </IconButton>
          <Button onClick={onSubmitHandler} fullWidth>
            {t("general.labels.avatarUploadButton")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
