import { Box, BoxProps } from "@mui/material";
import { useFullscreen } from "Hooks/fullscreen";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type ContextType = {
  requestFullscreen: () => Promise<void>;
  isFullscreen: boolean;
  exitFullscreen: () => Promise<void>;
  fullscreenContainer?: HTMLDivElement | null;
};

const Context = createContext<ContextType>({
  requestFullscreen: async () => {},
  exitFullscreen: async () => {},
  isFullscreen: false,
});

export const useFullscreenContext = () => useContext(Context);

export const FullscreenContext: React.FC<
  PropsWithChildren<Omit<BoxProps, "ref">>
> = ({ children, ...props }) => {
  const [fullscreenContainer, setFullscreenContainer] =
    useState<HTMLDivElement | null>(null);

  const { requestFullscreen, exitFullscreen, isFullscreen } =
    useFullscreen(fullscreenContainer);

  return (
    <Context.Provider
      value={{
        fullscreenContainer,
        requestFullscreen,
        exitFullscreen,
        isFullscreen,
      }}
    >
      <Box {...props} ref={setFullscreenContainer}>
        {children}
      </Box>
    </Context.Provider>
  );
};
