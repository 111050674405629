import { ServiceOutside } from "@ero/app-common/models";
import { PickBySpec } from "@ero/app-common/util/Spec";

export const servicesSpec = [
  ["internalId"],
  ["name"],
  ["description"],
  ["cost"],
  ["difficulty"],
  ["billingUnit"],
  ["notes"],
  ["additionalName"],
  ["serviceKind", "_id"],
  ["serviceKind", "name"],
] as const;

export type LocalServiceType = PickBySpec<ServiceOutside, typeof servicesSpec>;
