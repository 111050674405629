import * as Yup from "yup";

import { CARDINAL_DIRECTION } from "@ero/app-common/enums/cardinalDirection";
import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { PlotOutside } from "@ero/app-common/models";
import { Coordinate } from "@ero/app-common/util/Coordinate";
import { MAX_LENGTH, VALIDATION_ERRORS } from "Constants";
import { RequestBody } from "Types";

// re-declaring properties to "X | string" is because of html input fields returning empty fields as empty string (even for type=number)
export type FormikValues = Omit<
  RequestBody<"/parcels/update">,
  | "_id"
  | "sizeManual"
  | "sizeAutomatic"
  | "rowAmount"
  | "rowGap"
  | "plantAmount"
  | "plantGap"
  | "stain"
  | "slope"
  | "slopeDirection"
  | "gemarkungsnummer"
  | "flnr"
> & {
  _id?: number;
  sizeManual: number | string;
  sizeAutomatic: number;
  rowAmount: number | string;
  rowGap: number | string;
  plantAmount: number | string;
  plantGap: number | string;
  stain: number | string;
  slope: number | string;
  slopeDirection: CARDINAL_DIRECTION | string;
  sizeImportedKAFL: number | string;
  sizeImportedNUFL: number | string;
  gemarkungsnummer: number | string;
  flnr: number | string;
  isClosedPolygon: boolean;
};

// validation schema has to be retrieved va a function as VALIDATION_ERRORS is filled with translated errors on loading/language change
export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED),
    customer: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
    grapeVariety: Yup.number(),
    rowAmount: Yup.number()
      .integer(VALIDATION_ERRORS.INTEGER)
      .min(1, VALIDATION_ERRORS.MIN_VALUE({ min: 1 })),
    rowGap: Yup.number().positive(VALIDATION_ERRORS.POSITIVE_NUMBER),
    sizeField: Yup.number().required(),
    sizeManual: Yup.number()
      .positive(VALIDATION_ERRORS.POSITIVE_NUMBER)
      .when("sizeField", {
        is: (sizeField) => sizeField == SIZE_FIELD.SIZE_MANUAL,
        then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
    plantAmount: Yup.number().min(1, VALIDATION_ERRORS.MIN_VALUE({ min: 1 })),
    plantGap: Yup.number().positive(VALIDATION_ERRORS.POSITIVE_NUMBER),
    growingarea: Yup.string(),
    region: Yup.string(),
    largeRegion: Yup.string(),
    separatedRegion: Yup.string(),
    regionette: Yup.string(),
    mark: Yup.string(),
    gemarkungsnummer: Yup.number(),
    flurnummer: Yup.number()
      .integer(VALIDATION_ERRORS.INTEGER)
      .positive(VALIDATION_ERRORS.POSITIVE_NUMBER),
    flurstuecke: Yup.mixed<Partial<PlotOutside>>(),
    position: Yup.mixed<Coordinate>().nullable(),
    shape: Yup.array(Yup.mixed<Coordinate>()).nullable(),
    isClosedPolygon: Yup.boolean().when("shape", {
      is: (val: Coordinate[] | null | undefined) =>
        val === undefined || val === null,
      then: (schema) => schema,
      otherwise: (schema) => schema.oneOf([true]),
    }),
    color: Yup.string(),
    stain: Yup.number(),
    clone: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
    base: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
    plantingDate: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    clearingDate: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    slope: Yup.number().positive(VALIDATION_ERRORS.POSITIVE_NUMBER),
    slopeDirection: Yup.number(),
    soil: Yup.number(),
    trainingType: Yup.number(),
    note: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  });
