import { GRAPEVARIETYCOLOR } from "@ero/app-common/enums";
import { Theme } from "@mui/material";
import {
  CalendarEventColor,
  JobStatus,
  JobStatusColor,
  OrderStatus,
  ParcelKindColor,
} from "Enums";
import i18n from "i18next";

export const getParcelGrapeVarietyColor = (
  grapeVarietyColor: GRAPEVARIETYCOLOR | undefined,
  theme: Theme,
) => {
  if (!grapeVarietyColor) return theme.palette.grey[500];
  switch (grapeVarietyColor) {
    case GRAPEVARIETYCOLOR.RED:
      return ParcelKindColor.RED;
    case GRAPEVARIETYCOLOR.WHITE:
      return ParcelKindColor.WHITE;
    default:
      return theme.palette.grey[500];
  }
};

export const getJobStatusColor = (status?: JobStatus) => {
  switch (status) {
    case JobStatus.OPEN:
      return JobStatusColor.OPEN;
    case JobStatus.DONE:
      return JobStatusColor.DONE;
    case JobStatus.DRIVING_TO_LOCATION:
      return JobStatusColor.DRIVING_TO_LOCATION;
    case JobStatus.IN_WORK:
      return JobStatusColor.IN_WORK;
    case JobStatus.PAUSE:
      return JobStatusColor.PAUSE;
    default:
      return "";
  }
};

export const getJobTextColor = (color: string, theme: Theme) => {
  switch (color) {
    case JobStatusColor.DONE:
    case JobStatusColor.DRIVING_TO_LOCATION:
    case ParcelKindColor.WHITE:
      return theme.palette.text.primary;
    case JobStatusColor.OPEN:
    case JobStatusColor.IN_WORK:
    case JobStatusColor.PAUSE:
    default:
      return "#fff";
  }
};

export const getJobColor = (
  eventColor: CalendarEventColor,
  jobStatus: JobStatus,
  grapeVarietyColor: string | undefined,
  theme: Theme,
) => {
  const [_, status] = (getJobStatusColor(jobStatus) as string).split(".");
  switch (eventColor) {
    case CalendarEventColor.Status:
      return theme.palette["status"][status];
    case CalendarEventColor.GrapeColor:
      return getParcelGrapeVarietyColor(
        grapeVarietyColor as GRAPEVARIETYCOLOR,
        theme,
      );
    default:
      return theme.palette.primary.main;
  }
};

export const getJobStatusLabel = (status?: number) => {
  switch (status) {
    case JobStatus.DONE:
      return i18n.t("general.statuses.done");
    case JobStatus.DRIVING_TO_LOCATION:
      return i18n.t("general.statuses.drivingToLocation");
    case JobStatus.IN_WORK:
      return i18n.t("general.statuses.inWork");
    case JobStatus.PAUSE:
      return i18n.t("general.statuses.pause");
    case JobStatus.OPEN:
      return i18n.t("general.statuses.open");
    default:
      return i18n.t("general.labels.notSelected");
  }
};

export const getOrderStatusLabel = (status?: number) => {
  switch (status) {
    case OrderStatus.OPEN:
      return i18n.t("general.statuses.open");
    case OrderStatus.DONE:
      return i18n.t("general.statuses.done");
    case OrderStatus.IN_WORK:
      return i18n.t("general.statuses.inWork");
    default:
      return "";
  }
};

export const getOrderStatusColor = (status?: OrderStatus) => {
  switch (status) {
    case OrderStatus.OPEN:
      return "status.open";
    case OrderStatus.IN_WORK:
      return "status.inwork";
    case OrderStatus.DONE:
      return "status.done";
    default:
      return "status.open";
  }
};
