import { App } from "App";
import React from "react";
import { createRoot } from "react-dom/client";
import { initSentry } from "Utils/sentry";

initSentry(
  import.meta.env.MODE,
  import.meta.env.VITE_SENTRY_DSN,
  import.meta.env.VITE_SENTRY_RELEASE,
  import.meta.env.VITE_DOMAIN,
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
