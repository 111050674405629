import { api_parcels_all } from "Api";
import { OptionType } from "Types";
import { filterSpecShorthand } from "Utils";

export const useAllParcelOptions = (customerId: number) => {
  const fetchParcelOptions = async (): Promise<OptionType[]> => {
    try {
      const filters = filterSpecShorthand(customerId, ["customer"]) ?? [];
      const {
        data: { data },
      } = await api_parcels_all(
        {
          filters,
          spec: [["_id"], ["name"], ["grapeVariety"]],
        },
        { page: 0, pageLength: -1 },
      );
      return data.map((parcel) => ({
        value: parcel._id,
        label: `${parcel.name}`,
        meta: { color: parcel.grapeVariety?.meta.color },
      }));
    } catch {
      return [];
    }
  };

  return {
    fetchParcelOptions,
  };
};
