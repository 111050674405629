import { CustomerOutside } from "@ero/app-common/models/Customer";
import {
  Create,
  Delete,
  Description,
  Email,
  ExpandMore,
  Phone,
  PhoneAndroid,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  person: CustomerOutside["persons"][0];
  mainContact?: boolean;
  setPersonIdToUpdate: (number) => void;
  setPersonIdToDelete: (number) => void;
  expanded: boolean;
  setExpanded: (id: number) => void;
}

export const PersonItem: React.FC<PropsType> = ({
  person,
  mainContact,
  setPersonIdToUpdate,
  setPersonIdToDelete,
  expanded,
  setExpanded,
}: PropsType) => {
  const [t] = useTranslation();

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(person._id)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography sx={{ width: "55%", flexShrink: 0 }}>
          {`${person.firstName} ${person.lastName}`}
        </Typography>
        {mainContact && (
          <Typography sx={{ color: "text.secondary" }}>
            {t("customers.fields.mainContact")}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {person.phone && (
            <>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={person.phone} secondary={"Telefon"} />
              </ListItem>
              <Divider />
            </>
          )}
          {person.mobile && (
            <>
              <ListItem disablePadding>
                <ListItemIcon>
                  <PhoneAndroid />
                </ListItemIcon>
                <ListItemText primary={person.mobile} secondary={"mobil"} />
              </ListItem>
              <Divider />
            </>
          )}
          {person.email && (
            <>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText primary={person.email} secondary={"Email"} />
              </ListItem>
              <Divider />
            </>
          )}
          {person.description && (
            <>
              <ListItem disablePadding>
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                <ListItemText
                  primary={person.description}
                  secondary={"Beschreibung"}
                />
              </ListItem>
            </>
          )}
        </List>
      </AccordionDetails>
      <AccordionActions disableSpacing>
        <IconButton onClick={() => setPersonIdToUpdate(person._id)}>
          <Create />
        </IconButton>
        <IconButton onClick={() => setPersonIdToDelete(person._id)}>
          <Delete />
        </IconButton>
      </AccordionActions>
    </Accordion>
  );
};
