import { LANGUAGE } from "@ero/app-common/enums/language";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { CalendarSlotDuration } from "Enums";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import "./calendar.css";
import { Toolbar } from "./components/toolbar/toolbar";
import { useEvents } from "./hooks/useEvents";
import { useResources } from "./hooks/useResources";

const slotDurations = {
  [CalendarSlotDuration.FIVE_MINUTES]: 5 * 60 * 1000,
  [CalendarSlotDuration.FIVETEEN_MINUTES]: 15 * 60 * 1000,
  [CalendarSlotDuration.THIRTY_MINUTES]: 30 * 60 * 1000,
  [CalendarSlotDuration.ONE_HOUR]: 60 * 60 * 1000,
};
const slotLabelIntervals = {
  [CalendarSlotDuration.FIVE_MINUTES]: 15 * 60 * 1000,
  [CalendarSlotDuration.FIVETEEN_MINUTES]: 60 * 60 * 1000,
  [CalendarSlotDuration.THIRTY_MINUTES]: 60 * 60 * 1000,
  [CalendarSlotDuration.ONE_HOUR]: 2 * 60 * 60 * 1000,
};

export const Calendar: React.FC = () => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const calendarRef = useRef<FullCalendar>(null);

  const userLanguage = useSelector(
    (state: AppState) => state.auth.selectedLang,
  );
  const {
    users: allUsers,
    selectedUsers,
    sortedUsers,
    events,
    loadingEvents,
    slotDuration,
  } = useSelector((state: AppState) => state.planningV2);

  const {
    calendarEvents,
    eventChange,
    eventClick,
    eventDragStart,
    eventDragStop,
    eventReceive,
    getEventContent,
  } = useEvents(events, loadingEvents);

  const locales = useMemo(
    () => [userLanguage == LANGUAGE.EN ? { code: "en" } : { code: "de" }],
    [userLanguage],
  );

  const users: UserResponseBody[] = useMemo(
    () =>
      allUsers
        .filter((user) => !selectedUsers || selectedUsers.includes(user._id))
        .sort(
          (a, b) =>
            sortedUsers.findIndex((id) => id === a._id) -
            sortedUsers.findIndex((id) => id === b._id),
        ),
    [allUsers, selectedUsers, sortedUsers],
  );

  const { resources, getResourceLabelContent } = useResources(users);

  const onDateChange = useCallback((date: Date) => {
    calendarRef.current?.getApi().gotoDate(date);
  }, []);

  useEffect(() => {
    dispatch(sagaActions.fetchUsers());
    dispatch(sagaActions.fetchEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Toolbar onDateChange={onDateChange} />
      <Box height={"4px"}>{loadingEvents && <LinearProgress />}</Box>
      <Box
        sx={{
          flex: 1,
        }}
        className="planningV2"
      >
        <FullCalendar
          plugins={[
            resourceTimeGridPlugin,
            interactionPlugin,
            scrollGridPlugin,
          ]}
          initialView="resourceTimeGridDay"
          headerToolbar={{ center: "", left: "", right: "" }}
          height="100%"
          allDaySlot={false}
          resourceOrder={"index"}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
          }}
          dayMinWidth={240}
          eventBackgroundColor={theme.palette.primary.main}
          eventBorderColor={theme.palette.primary.main}
          droppable={!loadingEvents}
          editable={!loadingEvents}
          nowIndicator
          ref={calendarRef}
          locales={locales}
          initialDate={Date.now()}
          slotLabelInterval={
            slotLabelIntervals[slotDuration] ??
            slotLabelIntervals[CalendarSlotDuration.FIVETEEN_MINUTES]
          }
          slotDuration={
            slotDurations[slotDuration] ??
            slotDurations[CalendarSlotDuration.FIVETEEN_MINUTES]
          }
          expandRows
          resources={resources}
          resourceLabelContent={getResourceLabelContent}
          eventChange={eventChange}
          eventDragStart={eventDragStart}
          eventDragStop={eventDragStop}
          eventReceive={eventReceive}
          eventClick={eventClick}
          events={calendarEvents}
          eventContent={getEventContent}
          eventOverlap={false}
        />
      </Box>
    </Box>
  );
};
