import FullCalendar from "@fullcalendar/react";
import { useMemo, type RefObject } from "react";
import { useSwipeable } from "react-swipeable";
import { type CalendarProps } from "../types";

export const useInteraction = (
  props: CalendarProps,
  calendarRef: RefObject<FullCalendar>,
) => {
  const {
    enableSwipeGestures = false,
    isInteractive = true,
    isSelectable = true,
  } = props;

  const swipeProps = useSwipeable({
    onSwipedLeft() {
      if (enableSwipeGestures) {
        calendarRef.current?.getApi().next();
      }
    },
    onSwipedRight() {
      if (enableSwipeGestures) {
        calendarRef.current?.getApi().prev();
      }
    },
  });

  const interactionProps = useMemo(
    () => ({
      editable: isInteractive,
      selectable: isInteractive && isSelectable && !enableSwipeGestures,
    }),
    [enableSwipeGestures, isInteractive, isSelectable],
  );

  return {
    swipeProps,
    interactionProps,
  };
};
