import { getFaqs } from "Api";
import { errorToast } from "Services";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { HELP_ACTION_TYPES } from "./action-types";
import { getFaqsError, getFaqsSuccess } from "./actions";

export function* getFaqsSaga() {
  try {
    const { data } = yield call(getFaqs, {});

    yield put(getFaqsSuccess(data));
  } catch (error) {
    yield put(getFaqsError());

    errorToast(undefined, undefined, error);
  }
}

export default function* helpSagas() {
  yield all([takeEvery(HELP_ACTION_TYPES.GET_FAQS_REQUEST, getFaqsSaga)]);
}
