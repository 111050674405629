import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/jobs";
import {
  AddJobRequestBody,
  JobsRequestQuery,
  UpdateJobRequestBody,
  type JobTelemetryUpdateRequest,
} from "@ero/app-common/v2/routes/models/job";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { RequestBody } from "Types";

export const getJobsV2 = async (params: JobsRequestQuery) =>
  (await apiInstanceV2.get(baseUrl, { params })).data;

export const createJobV2 = (data: AddJobRequestBody) =>
  apiInstanceV2.post(baseUrl + SubUrls.ROOT, data);

export const updateJobV2 = (data: UpdateJobRequestBody & { _id: number }) =>
  apiInstanceV2.patch(
    baseUrl + SubUrls.BY_ID.replace(":id", data._id.toString()),
    data,
  );

export const deleteJobV2 = (id: number) =>
  apiInstanceV2.delete(baseUrl + SubUrls.BY_ID.replace(":id", id.toString()));

export const updateJob = (updateData: RequestBody<"/jobs/update">) =>
  apiInstance.post("/jobs/update", updateData);

export const rescheduleJobs = (data: RequestBody<"/jobs/bulkReschedule">) =>
  apiInstance.post("/jobs/bulkReschedule", { edits: data });

export const assignJobEmployees = (
  job: number,
  body: RequestBody<"/jobs/assignEmployees">,
) => apiInstance.post(`/jobs/assignEmployees`, body, { params: { job } });

export const freeJobEmployees = (
  job: number,
  body: RequestBody<"/jobs/freeEmployees">,
) => apiInstance.post(`/jobs/freeEmployees`, body, { params: { job } });

/**
 * @param id job ID
 * @param breakpoints If false, returns all telemetry,
 * otherwise filters out those who have the same status
 * as the last changed before them
 */
export const getJobTelemetry = (id: number, breakPoints = false) =>
  apiInstance.get("/jobs/telemetry", { params: { id, breakPoints } });

export const getJobTelemetryV2 = (id: number, omitTrack?: boolean) =>
  apiInstanceV2.get(baseUrl + SubUrls.TELEMETRY.replace(":id", `${id}`), {
    params: { omitTrack },
  });

export const updateJobTelemetryV2 = (
  id: number,
  telemetry: JobTelemetryUpdateRequest,
) =>
  apiInstanceV2.patch(
    baseUrl + SubUrls.TELEMETRY.replace(":id", `${id}`),
    telemetry,
  );
