import { type APISpec } from "@ero/app-common/routes/enforcer";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import {
  RequestBody,
  type ColumnFilterDataType,
  type GetTableDataParams,
} from "Types";
import { type Action } from "redux";
import { type LocalParcelType, type LocalViewParcelType } from "./specs";

export enum PARCELS_ACTION_TYPES {
  GET_INITIAL_PARCELS_REQUEST = "parcels/get-parcels-initial-request",
  GET_INITIAL_PARCELS_SUCCESS = "parcels/get-parcels-initial-success",
  GET_INITIAL_PARCELS_ERROR = "parcels/get-parcels-initial-error",
  GET_PARCEL_REQUEST = "parcels/get-parcel-request",
  GET_PARCEL_SUCCESS = "parcels/get-parcel-success",
  GET_PARCELS_REQUEST = "parcels/get-parcels-request",
  GET_PARCELS_SUCCESS = "parcels/get-parcels-success",
  CREATE_PARCEL = "parcels/create-parcel",
  UPDATE_PARCEL = "parcels/update-parcel",
  DELETE_PARCELS = "parcels/delete-parcels",
  SET_LOADING = "parcels/set-loading",
  SET_SUCCESS = "parcels/set-success",
  RESET_PARCEL = "parcels/reset-parcel",
  RESET_META = "parcels/reset-meta",
  RESET_SEARCH = "parcels/reset-search",
  SET_ERROR = "parcels/set-error",
  SET_TABLE_HIDDEN_COLUMNS = "parcels/set-table-hidden-columns",
  CHANGE_COLUMNS_ORDER = "parcels/change-columns-order",
  IMPORT = "parcels/import",
  SEND_IMPORT_LINK = "parcels/send-import-link",
}

export interface ISetLoadingAction extends Action {
  type: PARCELS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: PARCELS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: PARCELS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: PARCELS_ACTION_TYPES.RESET_META;
}

export interface IResetSearchAction extends Action {
  type: PARCELS_ACTION_TYPES.RESET_SEARCH;
}

export interface IResetParcelAction extends Action {
  type: PARCELS_ACTION_TYPES.RESET_PARCEL;
}

export interface IGetInitialParcelsRequestAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_REQUEST;
  payload: { params: GetTableDataParams };
}

export interface IGetInitialParcelsSuccessAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_SUCCESS;
  payload: {
    parcels: LocalParcelType[];
    maxCount: number;
    lastParcelId: number;
  };
}

export interface IGetInitialParcelsErrorAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_ERROR;
}

export interface IGetParcelsRequestAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_PARCELS_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
  };
}

export interface IGetParcelsSuccessAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_PARCELS_SUCCESS;
  payload: {
    parcels: LocalParcelType[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
    lastId: number;
  };
}

export interface IGetParcelSuccessAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_PARCEL_SUCCESS;
  payload: {
    parcel: LocalViewParcelType;
  };
}

export interface ICreateParcelAction extends Action {
  type: PARCELS_ACTION_TYPES.CREATE_PARCEL;
  payload: { data: RequestBody<"/parcels/create"> };
}

export interface IUpdateParcelAction extends Action {
  type: PARCELS_ACTION_TYPES.UPDATE_PARCEL;
  payload: {
    updateData: APISpec["/parcels/update"]["body"];
  };
}

export interface IGetParcelAction extends Action {
  type: PARCELS_ACTION_TYPES.GET_PARCEL_REQUEST;
  payload: {
    id: number;
  };
}

export interface IDeleteParcelsAction extends Action {
  type: PARCELS_ACTION_TYPES.DELETE_PARCELS;
  payload: { ids: number[] };
}

export interface ISetTableHiddenColumns extends Action {
  type: PARCELS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: {
    tableId: string;
    hiddenColumns: GridColumnVisibilityModel;
  };
}

export interface IChangeColumnsOrder extends Action {
  type: PARCELS_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export interface IImportParcels extends Action {
  type: PARCELS_ACTION_TYPES.IMPORT;
  payload: { data };
}

export interface ISendImportLink extends Action {
  type: PARCELS_ACTION_TYPES.SEND_IMPORT_LINK;
  payload: { data };
}

export type ParcelsAction =
  | IGetInitialParcelsRequestAction
  | IGetInitialParcelsSuccessAction
  | IGetInitialParcelsErrorAction
  | IGetParcelsRequestAction
  | IGetParcelsSuccessAction
  | ICreateParcelAction
  | IDeleteParcelsAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IResetSearchAction
  | ISetTableHiddenColumns
  | IGetParcelAction
  | IGetParcelSuccessAction
  | IResetParcelAction
  | IChangeColumnsOrder
  | IImportParcels
  | ISendImportLink;
