import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  AddOrderRequestBody,
  DeleteOrdersRequestQuery,
  OrdersRequestQuery,
  OrdersResponseBody,
  UpdateOrderRequestBody,
} from "@ero/app-common/v2/routes/models/order";
import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/order";
import { apiInstanceV2 } from "App/axios";

export const getOrdersV2 = async (
  params: OrdersRequestQuery,
): Promise<OrdersResponseBody<JobResponseBody[]>> =>
  (await apiInstanceV2.get(baseUrl, { params: { ...params, slim: false } }))
    .data;

export const getOrdersV2Slim = async (
  params: OrdersRequestQuery,
): Promise<OrdersResponseBody<number[]>> =>
  (await apiInstanceV2.get(baseUrl, { params: { ...params, slim: true } }))
    .data;

export const createOrder = (order: AddOrderRequestBody) =>
  apiInstanceV2.post("/orders", order);

export const updateOrder = (id: number, body: UpdateOrderRequestBody) =>
  apiInstanceV2.patch(baseUrl + SubUrls.BY_ID.replace(":id", `${id}`), body);

export const deleteOrders = async (params: DeleteOrdersRequestQuery) =>
  await apiInstanceV2.delete(baseUrl, { params });

export const getOrderById = (id: number) =>
  apiInstanceV2.get(baseUrl + SubUrls.BY_ID.replace(":id", `${id}`));

export const downloadDeliveryNote = (orderId: number) =>
  apiInstanceV2.get(
    baseUrl + SubUrls.DELIVERY_NOTE.replace(":id", orderId.toString()),
  );
