import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import { Loader } from "Components";
import React, { forwardRef } from "react";
import { mapContainerStyle, options } from "./config";
import { useGoogleMapsScriptLoader } from "./mapContext";

const eroCoords = { lat: 49.97339737902473, lng: 7.553028990634419 };

const MapV2 = forwardRef<GoogleMap, GoogleMapProps>((props, ref) => {
  const { isLoaded, loadError } = useGoogleMapsScriptLoader();

  return loadError ? (
    <>Error loading map: {loadError}</>
  ) : isLoaded ? (
    <GoogleMap
      {...props}
      ref={ref}
      mapContainerStyle={mapContainerStyle}
      options={{ ...options, ...props.options }}
      center={props.center ?? eroCoords}
    >
      {props.children}
    </GoogleMap>
  ) : (
    <Loader />
  );
});

MapV2.displayName = "MapV2";

export { MapV2 };
