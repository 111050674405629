import { useCallback, useState } from "react";
import { Coordinate } from "Types";

const options = {
  enableHighAccuracy: true,
};

export const useGeoposition = () => {
  const [geoposition, setGeoposition] = useState<Coordinate>();
  const [watching, setWatching] = useState(false);
  const [watcherId, setWatcherId] = useState<number | null>(null);

  const onChange: PositionCallback = useCallback(
    ({ coords: { latitude: lat, longitude: lng } }) => {
      setGeoposition({ lat, lng });
    },
    [],
  );

  const onError: PositionErrorCallback = useCallback(
    ({ message }) =>
      console.error("Unable to get current geolocation:", message),
    [],
  );

  const addWatcher = useCallback(() => {
    const geo = navigator.geolocation;
    if (!geo || watching) return;

    geo.getCurrentPosition(onChange, onError, options);

    const watcher = geo.watchPosition(onChange, onError, options);
    setWatcherId(watcher);
    setWatching(true);
  }, [onChange, onError, watching]);

  const removeWatcher = useCallback(() => {
    if (watcherId !== null) {
      navigator.geolocation.clearWatch(watcherId);
      setWatcherId(null);
      setWatching(false);
    }
  }, [watcherId]);

  return { geoposition, watching, addWatcher, removeWatcher };
};
