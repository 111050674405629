import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { UserDataType } from "Types";

export const setSentryUser = (user: UserDataType | null) => {
  Sentry.setUser({ id: user?._id });
};

export const initSentry = (
  environment?: string,
  sentryDSN?: string,
  release?: string,
  environmentURL?: string,
) => {
  if (environment !== "development") {
    Sentry.init({
      dsn: sentryDSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
      release: release,
      environment: environmentURL,
    });
  }
};
