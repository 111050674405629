import { UserOutside } from "@ero/app-common/models";
import { PickBySpec } from "@ero/app-common/util/Spec";

export const employeesSpec = [
  ["_id"],
  ["firstName"],
  ["lastName"],
  ["permission"],
  ["internalId"],
  ["email"],
  ["phone"],
  ["qualification"],
  ["notes"],
  ["avatar"],
  ["userName"],
  ["language"],
  ["permission"],
] as const;

export const employeesTimeslotSpec = [
  ["_id"],
  ["employee"],
  ["job"],
  ["start"],
  ["end"],
];

export type LocalEmployeeType = PickBySpec<UserOutside, typeof employeesSpec>;
