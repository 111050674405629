import React from "react";
import { Navigate } from "react-router-dom";

import { ROUTES } from "Constants";
import { AppState } from "Store";
import { useSelector } from "react-redux";

const MainEntryPoint: React.FC = () => {
  const isLoggedIn = useSelector(
    (state: AppState) => state.auth.tokens.accessToken,
  );

  return isLoggedIn ? (
    <Navigate to={ROUTES.MAIN.DASHBOARD} />
  ) : (
    <Navigate to={ROUTES.AUTH.LOGIN} />
  );
};

export default MainEntryPoint;
