import { alpha, Box, Divider, Grid2, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { FiltersChips } from "./components/filter/components/filtersChips";
import { Filter } from "./components/filter/filter";
import { OrderList } from "./components/orderList/orderList";

export const Sidebar: React.FC = () => {
  const [t] = useTranslation();

  const orders = useSelector((state: AppState) => state.planningV2.orders);

  return (
    <Box
      sx={(theme) => ({
        height: "100%",
        display: "flex",
        flexFlow: "column",
        borderRight: 1,
        borderColor: theme.palette.divider,
      })}
    >
      <Grid2 container pt={2} pb={1} px={4} alignItems={"center"}>
        <Grid2 size={10}>
          <Typography variant="button">
            {t("planningV2.sidebar.unplannedOrders", {
              count: orders.length,
            })}
          </Typography>
        </Grid2>
        <Grid2 size={2}>
          <Filter />
        </Grid2>
      </Grid2>
      <FiltersChips />
      <Divider />
      <Box
        id="orderListContainer"
        style={{
          overflowY: "scroll",
          flex: 1,
        }}
      >
        <OrderList />
      </Box>
      <div id="dropzoneContainer" style={{ display: "none", flex: 1 }}>
        <Grid2 container padding={2} sx={{ height: "100%" }}>
          <div id="dropzone" style={{ height: "100%", width: "100%" }}>
            <Grid2
              size={12}
              sx={(theme) => ({
                borderStyle: "dashed",
                borderColor: alpha(theme.palette.primary.light, 0.6),
                p: 2,
                height: "100%",
              })}
            >
              <Typography variant="body2" textAlign={"center"}>
                {t("planningV2.dropZone")}
              </Typography>
            </Grid2>
          </div>
        </Grid2>
      </div>
    </Box>
  );
};
