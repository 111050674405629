import { ErrorOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotEditableField } from "../notEditableField";

interface IErrorDialog {
  error: string;
}

export const ErrorDialog: React.FC<IErrorDialog> = ({ error }) => {
  const [t] = useTranslation();

  const onClick = (): void => {
    window.location.replace("/");
  };

  return (
    <Dialog open>
      <DialogTitle>{t("components.errorDialog.title")}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          {t("components.errorDialog.text")}
        </Typography>
        <NotEditableField text={error} startIcon={<ErrorOutlineOutlined />} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClick}>
          {t("components.errorDialog.buttonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
