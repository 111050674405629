import { AccessTime, Speed } from "@mui/icons-material";
import { TelemetryType } from "Enums";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface TelemetryEntry {
  name: string;
  tooltip: string;
  key: string;
  telemetryType?: TelemetryType;
  suffix: string;
  inputType: "time" | "number" | "percent";
  noEdit?: boolean;
  emptyValue?: string;
  icon?: React.ReactElement;
}

export const useTelemetryEntries = (): TelemetryEntry[] => {
  const [t] = useTranslation();
  const telemetryEntries: TelemetryEntry[] = useMemo(
    () => [
      {
        key: "totalTime",
        name: t("telemetry.totalTime"),
        tooltip: t("telemetry.totalTimeTooltip"),
        suffix: "telemetry.units.time",
        inputType: "time",
        noEdit: true,
        icon: <AccessTime />,
      },
      {
        key: "workTime",
        name: t("telemetry.workTime"),
        tooltip: t("telemetry.workTimeTooltip"),
        suffix: t("telemetry.units.time"),
        inputType: "time",
        noEdit: true,
        icon: <AccessTime />,
      },
      {
        key: "meterShaked",
        name: t("telemetry.meterShaked"),
        tooltip: t("telemetry.meterShakedTooltip"),
        telemetryType: TelemetryType.meter_shaked,
        suffix: t("telemetry.units.meters"),
        inputType: "number",
        icon: <Speed />,
      },
      {
        key: "meterHarvested",
        name: t("telemetry.meterHarvested"),
        tooltip: t("telemetry.meterHarvestedTooltip"),
        telemetryType: TelemetryType.meter_harvested,
        suffix: t("telemetry.units.meters"),
        inputType: "number",
        icon: <Speed />,
      },
      {
        key: "timeShaked",
        name: t("telemetry.timeShaked"),
        tooltip: t("telemetry.timeShakedTooltip"),
        telemetryType: TelemetryType.time_shaked,
        suffix: t("telemetry.units.time"),
        inputType: "time",
        icon: <AccessTime />,
      },
      {
        key: "timeHarvested",
        name: t("telemetry.timeHarvested"),
        tooltip: t("telemetry.timeHarvestedTooltip"),
        telemetryType: TelemetryType.time_harvested,
        suffix: t("telemetry.units.time"),
        inputType: "time",
        icon: <AccessTime />,
      },
    ],
    [t],
  );
  return telemetryEntries;
};
