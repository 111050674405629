import { VALIDATION_ERRORS } from "Constants";
import { type FORGOT_PASS_DTO } from "Types";
import * as Yup from "yup";

export const initialValues: FORGOT_PASS_DTO = {
  userName: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    userName: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
  });
