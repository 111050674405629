import { type Action } from 'redux';

export enum EXTERNAL_ACCESS_ACTION_TYPES {
  GET_TOKEN_REQUEST = 'external-access/get-token-request',
  GET_TOKEN_SUCCESS = 'external-access/get-token-success',
  GET_TOKEN_ERROR = 'external-access/get-token-error',
}

export interface IGetTokenRequest extends Action {
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_REQUEST;
  payload: { requestToken: string };
}

export interface IGetTokenSuccess extends Action {
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_SUCCESS;
  payload: { accessToken: string };
}

export interface IGetTokenError extends Action {
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_ERROR;
}

export type ExternalAccessAction =
  | IGetTokenRequest
  | IGetTokenSuccess
  | IGetTokenError;
