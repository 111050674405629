import { Box, LinearProgress } from "@mui/material";
import React from "react";

import logo from "Assets/Logo_EROplan_RGB.png";

export const AppLoader: React.FC = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box>
        <img src={logo} style={{ width: "50rem" }} />
        <LinearProgress />
      </Box>
    </Box>
  );
};
