import { type EventInput } from "@fullcalendar/core";
import lodashIsEmpty from "lodash/isEmpty";
import moment from "moment";
import { type AppointmentType, type MachineType } from "Types";
import { isAllDay } from "Utils";

export const createProperFormatForAppointments = (
  appointments: EventInput[] = [],
  orderMachines: MachineType[] = [],
) => {
  return appointments.map((appointment: EventInput) => {
    const { _id, fixed, order, machine, parcel } = appointment;

    let targetMachine = machine;

    if (Number.isInteger(targetMachine)) {
      targetMachine = orderMachines.find(
        (machine: MachineType) => machine._id === targetMachine,
      );
    }

    return {
      allDay: isAllDay(
        new Date(Number(appointment.start)),
        new Date(Number(appointment.end)),
      ),
      id: String(_id),
      title: targetMachine?.name || "",
      parcelName: parcel.name,
      machineKind: targetMachine?.modelType || "",
      backgroundColor: !fixed ? targetMachine?.color : "#D6D6D6",
      textColor: "#fff",
      machineId: targetMachine?._id,
      orderId: order,
      parcelId: parcel._id,
      ...appointment,
    };
  });
};

export const objectsEqual = (object1: any, object2: any) => {
  if (lodashIsEmpty(object1) || lodashIsEmpty(object2)) {
    return;
  }
  return typeof object1 === "object" && Object.keys(object1).length > 0
    ? Object.keys(object1).length === Object.keys(object2).length &&
        Object.keys(object1).every((property) =>
          objectsEqual(object1[property], object2[property]),
        )
    : object1 === object2;
};

export const arraysEqual = (array1: any[], array2: any[]) =>
  array1.length === array2.length &&
  array1.every((obj, idx) => objectsEqual(obj, array2[idx]));

export const getTimestampWithTimeZoneOffset = (timestamp: number) => {
  return timestamp - (moment().utcOffset() / 60) * 3600000;
};

export const getAppointmentObjectFromCalendarEvent = (event: any) => {
  const { _def, _instance, extendedProps, allDay } = event;

  return {
    _id: _def.publicId,
    allDay,
    ..._instance.range,
    ...extendedProps,
  };
};

export const getAppointmentAfterChangeDate = (event) => {
  const appointment = getAppointmentObjectFromCalendarEvent(event);

  let start, end;

  if (appointment.allDay) {
    start = Number(
      moment(moment(appointment.start).format("YYYY-MM-DD")).format("x"),
    );
    end = Number(
      moment(moment(appointment.end).format("YYYY-MM-DD")).format("x"),
    );
  } else {
    start = getTimestampWithTimeZoneOffset(
      Number(moment(appointment.start).format("x")),
    );
    end = getTimestampWithTimeZoneOffset(
      Number(moment(appointment.end).format("x")),
    );
  }

  return {
    ...appointment,
    start,
    end,
  };
};

export const getClosestAndLatestAppointmentsTime = (
  allAppointments: AppointmentType[],
) => {
  if (!allAppointments.length) {
    return;
  }
  const allStartDates = allAppointments.map(
    (appointment: AppointmentType) => appointment.start,
  );
  const allEndDates = allAppointments.map(
    (appointment: AppointmentType) => appointment.end,
  );
  const start = Math.min(...allStartDates);
  const end = Math.max(...allEndDates);

  return { start, end };
};

export const getDefaultStartEndDateForAppointment = () => {
  const defaultEndDateTime = new Date();
  defaultEndDateTime.setMinutes(defaultEndDateTime.getMinutes() + 30);

  return {
    start: new Date(),
    end: defaultEndDateTime,
  };
};
