import { apiInstance } from "App/axios";
import {
  type ApiListRequestParams,
  type CREATE_RELEASENOTE_DTO,
  type ReleaseNoteType,
  type ReleaseNotesListResponse,
} from "Types";

export const getReleaseNotes = ({
  params,
  search,
  spec,
}: ApiListRequestParams<ReleaseNoteType>) => {
  const response = apiInstance.post<ReleaseNotesListResponse>(
    "/releaseNotes/all",
    { search, spec },
    {
      params: {
        ...params,
      },
    },
  );

  return response;
};

export const deleteReleaseNotes = (ids: number[]) => {
  apiInstance.post("/releaseNotes/delete", { ids });
};

export const createReleaseNote = (data: CREATE_RELEASENOTE_DTO) =>
  apiInstance.post("/releaseNotes/create", data);

export const updateReleaseNote = (
  updateData: Record<string, string | number>,
  id: number,
) => {
  updateData._id = id;
  const response = apiInstance.post(`/releaseNotes/update`, updateData);
  return response;
};
