import * as Yup from 'yup';
import { VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import { type RESET_PASS_DTO } from 'Types';

export const initialValues: RESET_PASS_DTO = {
  password: '',
  repeat: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .matches(VALIDATION_REGEX.password, {
        message: VALIDATION_ERRORS.NO_SAFE_PASSWORD,
      })
      .required(VALIDATION_ERRORS.REQUIRED),
    repeat: Yup.string()
      .oneOf([Yup.ref("password"), ""], VALIDATION_ERRORS.NOT_MATCHED_PASSWORD)
      .required(VALIDATION_ERRORS.REQUIRED),
  });
