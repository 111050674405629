import { useJsApiLoader } from "@react-google-maps/api";
import React, { createContext, useContext } from "react";
import { apiOptions } from "./config";

type ContextType = {
  isLoaded: boolean;
  loadError: Error | undefined;
};

type ProviderProps = {
  children: React.ReactElement;
};

const Context = createContext<ContextType>({
  isLoaded: false,
  loadError: undefined,
});

export const useGoogleMapsScriptLoader = () => useContext(Context);

export const GoogleMapsScriptLoader: React.FC<ProviderProps> = ({
  children,
}) => {
  const { isLoaded, loadError } = useJsApiLoader(apiOptions);

  return (
    <Context.Provider value={{ isLoaded, loadError }}>
      {children}
    </Context.Provider>
  );
};
