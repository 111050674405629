import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Box, Card, CardContent, List, ListSubheader } from "@mui/material";
import { Loader } from "Components";
import { AppState } from "Store";
import React from "react";
import { useSelector } from "react-redux";
import { StatusesList } from "./statusList";

interface IStatusCard {
  statusOperations?: JobResponseBody["statusOperations"];
}

export const StatusCard: React.FC<IStatusCard> = ({ statusOperations }) => {
  const { loading } = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetryStatus,
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <List>
          <ListSubheader>Statusoperationen</ListSubheader>
          <StatusesList statusOperations={statusOperations} />
        </List>
      </CardContent>
      {loading && (
        <Box position={"absolute"} top={0} width={"100%"} height={"100%"}>
          <Loader />
        </Box>
      )}
    </Card>
  );
};
