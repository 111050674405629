import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import dayjs from "dayjs";
import { SelectionTypeEnum } from "Screens/dashboard/constants";
import { Coordinate } from "Types";
import { mapEntityAfterSocketUpdate } from "../helpers";
import { DASHBOARD_ACTION_TYPES, type DashboardAction } from "./action-types";

export type ParcelFilters = {
  timeRange: {
    start: number;
    end: number;
  };
  showUnplanned: boolean;
};

export type DashboardStateType = {
  selectionType: SelectionTypeEnum;
  loading: boolean;
  success: boolean;
  error: boolean;
  machinesList: MachineResponseBody[];
  parcelsList: ParcelResponseBody[];
  drivers: UserResponseBody[];
  showDriversTrack: boolean;
  center?: Coordinate;
  zoom: number;
  parcelFilters?: ParcelFilters;
};

const initialState: DashboardStateType = {
  selectionType: SelectionTypeEnum.Machines,
  loading: false,
  success: false,
  error: false,
  machinesList: [] as MachineResponseBody[],
  parcelsList: [] as ParcelResponseBody[],
  drivers: [],
  showDriversTrack: false,
  center: undefined,
  zoom: 14,
  parcelFilters: {
    timeRange: {
      start: dayjs(Date.now()).startOf("day").toDate().getTime(),
      end: dayjs(Date.now()).endOf("day").toDate().getTime(),
    },
    showUnplanned: false,
  },
};

export const dashboardReducer = (
  state = initialState,
  action: DashboardAction,
): DashboardStateType => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE_SUCCESS:
      return {
        ...state,
        selectionType: action.payload.type,
        loading: false,
      };
    case DASHBOARD_ACTION_TYPES.SET_MACHINES:
      return {
        ...state,
        machinesList: action.payload.list,
      };
    case DASHBOARD_ACTION_TYPES.SET_PARCELS:
      return {
        ...state,
        parcelsList: action.payload.list,
      };
    case DASHBOARD_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case DASHBOARD_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case DASHBOARD_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case DASHBOARD_ACTION_TYPES.UPDATE_MACHINE_LIVE:
      return {
        ...state,
        machinesList: state.machinesList.map((item) =>
          mapEntityAfterSocketUpdate(item, action.payload.message),
        ),
      };
    case DASHBOARD_ACTION_TYPES.SET_DRIVERS:
      return {
        ...state,
        drivers: action.payload.data.map((driver) => {
          return {
            ...driver,
            track: driver.track?.sort((a, b) => a.timestamp - b.timestamp),
          };
        }),
      };
    case DASHBOARD_ACTION_TYPES.UPDATE_DRIVER_LIVE:
      return {
        ...state,
        drivers: state.drivers.map((driver) => {
          const message = action.payload.message;
          const track = message.payload.track;

          if (track && track.length > 0 && driver._id == message.meta) {
            driver.track?.push(
              ...track.sort((a, b) => a.timestamp - b.timestamp),
            );
            // create new array so that all hooks with track in dependency array are re-evaluated
            driver.track = [...(driver.track || [])];
          }
          return driver;
        }),
      };
    case DASHBOARD_ACTION_TYPES.SET_SHOW_DRIVERS_TRACK:
      return {
        ...state,
        showDriversTrack: action.payload.showDriversTrack,
      };
    case DASHBOARD_ACTION_TYPES.SET_CENTER:
      return {
        ...state,
        center: action.payload.center,
      };
    case DASHBOARD_ACTION_TYPES.SET_ZOOM:
      return {
        ...state,
        zoom: action.payload.zoom,
      };
    case DASHBOARD_ACTION_TYPES.SET_PARCEL_FILTERS:
      return {
        ...state,
        parcelFilters: action.payload,
      };
    case DASHBOARD_ACTION_TYPES.RESET_PARCEL_FILTERS:
      return {
        ...state,
        parcelFilters: initialState.parcelFilters,
      };
    default:
      return state;
  }
};
