import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import React, { useCallback, useState } from "react";

type Props = {
  name: string;
  label: string;
  format?: string;
  required?: boolean;
};

export const TimeInput = React.forwardRef<HTMLInputElement, Props>(
  ({ name, label, format, required }, ref) => {
    const [field, meta, helpers] = useField(name);

    const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
      !field.value ? null : dayjs(field.value),
    );

    const onChange = useCallback(
      (dayJsValue: Dayjs | null) => {
        setDayJsValue(dayJsValue);
        helpers.setValue(dayJsValue?.valueOf());
      },
      [helpers],
    );

    return (
      <TimePicker
        value={dayJsValue}
        onChange={onChange}
        ref={ref}
        slotProps={{
          textField: { required, helperText: meta.error, fullWidth: true },
        }}
        format={format}
        label={label}
      />
    );
  },
);

TimeInput.displayName = "TimeInput";
