import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export const CloseButton: React.FC<{ onClose: (() => void) | undefined }> = ({
  onClose,
}) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
    >
      <Close />
    </IconButton>
  );
};
