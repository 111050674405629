import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  createCustomer,
  deleteCustomers,
  getCustomers,
  updateCustomer,
} from "Api";
import { errorToast } from "Services";
import { Response, type ApiResponse } from "Types";
import { getTableParamsAfterDeletion } from "Utils";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import {
  CUSTOMERS_ACTION_TYPES,
  type ICreateCustomerAction,
  type IDeleteCustomersAction,
  type IGetCustomersRequestAction,
  type IGetInitialCustomersRequestAction,
  type IUpdateCustomerAction,
} from "./action-types";
import {
  createCustomerSuccess,
  getCustomersInitialError,
  getCustomersInitialSuccess,
  getCustomersSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { customersListSpec as customersListSpec_ } from "./specs";
const customersListSpec = customersListSpec_ as unknown as z.infer<
  typeof spec_shape
>;

export function* getInitialCustomersSaga({
  payload,
}: IGetInitialCustomersRequestAction) {
  const { params } = payload;

  try {
    const store = yield select();

    const { data }: ApiResponse<Response<"/customers/all">> = yield call(
      getCustomers,
      {
        params,
        search: store.customers.searchData,
        spec: customersListSpec,
      },
    );

    yield put(getCustomersInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getCustomersInitialError());

    errorToast(undefined, undefined, error);
  }
}

export function* getCustomersSaga({ payload }: IGetCustomersRequestAction) {
  const { params, search, withSuccess } = payload;

  try {
    const { data }: ApiResponse<Response<"/customers/all">> = yield call(
      getCustomers,
      {
        params,
        search,
        spec: customersListSpec,
      },
    );

    yield put(
      getCustomersSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
      }),
    );

    if (withSuccess) {
      yield put(setSuccess());

      yield put(resetMeta());
    }
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* createCustomerSaga({ payload }: ICreateCustomerAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    const result = yield call(createCustomer, data);

    yield put(createCustomerSuccess(result.data));

    const store = yield select();

    const customersResult: ApiResponse<Response<"/customers/all">> = yield call(
      getCustomers,
      {
        params: store.customers.listMeta,
        search: store.customers.searchData,
        spec: customersListSpec,
      },
    );

    yield put(
      getCustomersSuccess({
        list: customersResult.data.data,
        maxCount: customersResult.data.maxCount,
        listMeta: store.customers.listMeta,
        search: store.customers.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateCustomerSaga({ payload }: IUpdateCustomerAction) {
  const { updateData, id } = payload;

  try {
    yield put(setLoading());

    yield call(updateCustomer, updateData, id);

    const store = yield select();

    const customersResult: ApiResponse<Response<"/customers/all">> = yield call(
      getCustomers,
      {
        params: store.customers.listMeta,
        search: store.customers.searchData,
        spec: customersListSpec,
      },
    );

    yield put(
      getCustomersSuccess({
        list: customersResult.data.data,
        maxCount: customersResult.data.maxCount,
        listMeta: store.customers.listMeta,
        search: store.customers.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* deleteCustomersSaga({ payload }: IDeleteCustomersAction) {
  const { ids } = payload;

  try {
    yield call(deleteCustomers, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.customers.listMeta,
      store.customers.list.length,
      ids.length,
    );

    const customersResult: ApiResponse<Response<"/customers/all">> = yield call(
      getCustomers,
      {
        params: listMeta,
        search: store.customers.searchData,
        spec: customersListSpec,
      },
    );

    yield put(
      getCustomersSuccess({
        list: customersResult.data.data,
        maxCount: customersResult.data.maxCount,
        listMeta,
        search: store.customers.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export default function* customersSagas() {
  yield all([
    takeEvery(
      CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_REQUEST,
      getInitialCustomersSaga,
    ),
    takeEvery(CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_REQUEST, getCustomersSaga),
    takeEvery(CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER, createCustomerSaga),
    takeEvery(CUSTOMERS_ACTION_TYPES.UPDATE_CUSTOMER, updateCustomerSaga),
    takeEvery(CUSTOMERS_ACTION_TYPES.DELETE_CUSTOMERS, deleteCustomersSaga),
  ]);
}
