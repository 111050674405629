import { useMemo } from "react";

export const useUserTitle = (
  user:
    | {
        firstName?: string;
        lastName?: string;
        userName?: string;
      }
    | null
    | undefined,
) => {
  const title = useMemo(
    () =>
      user
        ? `${user.firstName || ""} ${user.lastName || ""}`.trim() ||
          user.userName
        : undefined,
    [user],
  );

  return title;
};
