import { getDropDownOptionsV2 } from "Api";
import { OptionType } from "Types";
import { useEffect, useMemo, useState } from "react";

export const useDropdownValues = (key: string, accessToken?: string) => {
  return useMemo(() => {
    const fetchDropdownValues: () => Promise<OptionType[]> = async function () {
      try {
        const { data } = (await getDropDownOptionsV2(key, accessToken)).data;
        return data.map((dropdownEntry) => ({
          value: dropdownEntry._id,
          label: dropdownEntry.name,
          meta: dropdownEntry?.meta || undefined,
        }));
      } catch {
        return [];
      }
    };

    return { fetchDropdownValues };
  }, [accessToken, key]);
};

export const useStaticDropdownValues = (key: string, accessToken?: string) => {
  const { fetchDropdownValues } = useDropdownValues(key, accessToken);

  const [values, setValues] = useState<OptionType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const fetchedValues = await fetchDropdownValues();
      setValues(fetchedValues);
      setLoading(false);
    })();
  }, [fetchDropdownValues]);

  return { values, loading };
};