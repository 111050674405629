export type Point = {
  x: number;
  y: number;
};

export type AreaPixels = Point & {
  width: number;
  height: number;
};

const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const getCroppedImg = async (imageSrc: string, crop: AreaPixels) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx === null) {
    throw "canvas.getContext broken.";
  }

  /* setting canvas width & height allows us to
  resize from the original image resolution */
  canvas.width = 380;
  canvas.height = 380;

  ctx.beginPath();
  ctx.arc(190, 190, 190, 0, 6.28, false);
  ctx.clip();
  ctx.stroke();
  ctx.closePath();

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/png");
  });
};
