import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export interface IConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
  onReject?: () => void;
  title?: string;
  text?: string;
  text_2?: string;
  text_3?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
}

export const ConfirmDialog: React.FC<
  PropsWithChildren<IConfirmDialogProps>
> = ({
  isOpen,
  onClose,
  onReject,
  onApprove,
  title,
  text,
  text_2,
  text_3,
  positiveButtonText,
  negativeButtonText,
  children,
}) => {
  const [t] = useTranslation();

  const modalContents = children ?? (
    <>
      <Typography variant="body1">
        {text || t("components.confirmDialog.textSecondLine")}
      </Typography>
      {text_2 && <Typography variant="body1">{text_2}</Typography>}
      {text_3 && <Typography variant="body1">{text_3}</Typography>}
    </>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title || t("components.confirmDialog.text")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent dividers>{modalContents}</DialogContent>
      <DialogActions>
        <Button key="cancel-delete" onClick={onReject ?? onClose}>
          {negativeButtonText || t("components.confirmDialog.buttonTextNo")}
        </Button>
        <Button key="approve-delete" onClick={onApprove} variant="contained">
          {positiveButtonText || t("components.confirmDialog.buttonTextYes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
