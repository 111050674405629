import { type APISpec } from "@ero/app-common/routes/enforcer";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { apiInstance } from "App/axios";
import {
  RequestBody,
  type ColumnFilterDataType,
  type GetTableDataParams,
} from "Types";

type AddPersonAPISpec = APISpec["/customers/addPerson"];

export const api_customers_addPerson = (
  body: RequestBody<"/customers/addPerson">,
  query: AddPersonAPISpec["query"],
) => {
  return apiInstance.post<AddPersonAPISpec["response"]>(
    "/customers/addPerson",
    body,
    { params: query },
  );
};

export const getCustomerEmployees = (
  customerId: number,
  params: GetTableDataParams,
  search?: SearchSpec,
  filters?: ColumnFilterDataType[],
) =>
  apiInstance.post(
    "/customers/allPersons",
    { search, filters },
    { params: { customer: customerId, ...params } },
  );

export const deleteCustomerEmployees = (ids: number[], customer: number) =>
  apiInstance.post(
    "/customers/deletePersons",
    { ids },
    { params: { customer } },
  );

export const updateCustomerEmployee = (
  updateData: RequestBody<"/customers/updatePerson">,
  person: number,
  customer: number,
) =>
  apiInstance.post(
    "/customers/updatePerson",
    { ...updateData },
    { params: { person, customer } },
  );
