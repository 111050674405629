import { LAYOUTS, ROUTES } from "Constants";
import {
  ConfirmRegistration,
  Customers,
  Dashboard,
  Employees,
  ExternalAccess,
  ForgotPassword,
  Help,
  Login,
  Machines,
  OrderDetails,
  Orders,
  Organization,
  Parcels,
  Planning,
  Profile,
  ReleaseNotes,
  ResetPassword,
  Services,
  VerifyPassword,
} from "Screens";
import { PlanningV2 } from "Screens/planningV2/planningV2";
import { type ElementType } from "react";
import MainEntryPoint from "./mainEntryPoint";

export type RouteType = {
  path: string;
  component: ElementType;
  layout?: LAYOUTS;
  private?: boolean;
  notFullHeight?: boolean;
};

const routesConfig: RouteType[] = [
  { path: "", component: MainEntryPoint },
  {
    path: ROUTES.MAIN.DASHBOARD,
    component: Dashboard,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.PLANNING,
    component: Planning,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.PLANNING2,
    component: PlanningV2,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.ORDERS,
    component: Orders,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: `${ROUTES.MAIN.ORDERS}/:id/*`,
    component: OrderDetails,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.ORGANIZATION,
    component: Organization,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.CUSTOMERS,
    component: Customers,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.SERVICES,
    component: Services,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.EMPLOYEES,
    component: Employees,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.MAIN.MACHINES,
    component: Machines,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: `${ROUTES.MAIN.MACHINES}`,
    component: Machines,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: `${ROUTES.MAIN.MACHINES}/:id`,
    component: Machines,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: `${ROUTES.MAIN.PARCELS}`,
    component: Parcels,
    layout: LAYOUTS.PRIVATE,
  },
  {
    path: ROUTES.PROFILE.VIEW,
    component: Profile,
    layout: LAYOUTS.PRIVATE,
    notFullHeight: true,
  },
  {
    path: ROUTES.PROFILE.RELEASENOTES,
    component: ReleaseNotes,
    layout: LAYOUTS.PRIVATE,
    notFullHeight: true,
  },
  {
    path: ROUTES.AUTH.LOGIN,
    component: Login,
    layout: LAYOUTS.PUBLIC,
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD,
    component: ForgotPassword,
    layout: LAYOUTS.PUBLIC,
  },
  {
    path: ROUTES.AUTH.RESET_PASSWORD,
    component: ResetPassword,
    layout: LAYOUTS.PUBLIC,
  },
  {
    path: ROUTES.AUTH.VERIFY_PASSWORD,
    component: VerifyPassword,
    layout: LAYOUTS.PUBLIC,
  },
  {
    path: ROUTES.EXTERNAL,
    component: ExternalAccess,
    layout: LAYOUTS.PUBLIC,
  },
  {
    path: ROUTES.MISC.HELP,
    component: Help,
    layout: LAYOUTS.PRIVATE,
    notFullHeight: true,
  },
  {
    path: ROUTES.AUTH.CONFIRM_REGISTRATION,
    component: ConfirmRegistration,
    layout: LAYOUTS.PUBLIC,
  },
];

export default routesConfig;
