import { Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../formConfig";
import { Steps } from "../steps";

interface IDialogActionButtons {
  stepsExtended: boolean;
  setStepsExtended: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: Steps;
  setActiveStep: React.Dispatch<React.SetStateAction<Steps>>;
  selectedServices: number[];
  selectedParcels: number[];
}

export const DialogActionButtons: React.FC<IDialogActionButtons> = ({
  stepsExtended,
  setStepsExtended,
  activeStep,
  setActiveStep,
  selectedServices,
  selectedParcels,
}) => {
  const [t] = useTranslation();

  const { isValid, dirty, setFieldValue, values } =
    useFormikContext<ValuesType>();

  const resetJobsValues = useCallback(
    () => setFieldValue("jobs", []),
    [setFieldValue],
  );

  const handleNext = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
    [setActiveStep],
  );

  const handlePrevious = useCallback(
    () => setActiveStep((prevActiveStep) => prevActiveStep - 1),
    [setActiveStep],
  );

  const BackButton = useCallback(() => {
    let clickHandler = handlePrevious;
    if (!stepsExtended) {
      clickHandler = () => setActiveStep(Steps.GENERAL);
    }
    return (
      <Button onClick={clickHandler} type="button">
        {t("general.buttons.back")}
      </Button>
    );
  }, [handlePrevious, stepsExtended, t, setActiveStep]);

  useEffect(() => {
    if (activeStep == Steps.PARCELS) {
      setStepsExtended(true);
    }
    if (activeStep == Steps.GENERAL) {
      setStepsExtended(false);
    }
  }, [activeStep, setStepsExtended]);

  const buttons: React.ReactNode = useMemo(() => {
    switch (activeStep) {
      case Steps.PARCELS:
        return (
          <>
            <BackButton />
            <Button
              onClick={handleNext}
              type="button"
              variant="outlined"
              disabled={selectedParcels.length === 0}
              key="nextHandlerParcels"
            >
              {t("general.buttons.next")}
            </Button>
          </>
        );
      case Steps.SERVICES:
        return (
          <>
            <BackButton />
            <Button
              onClick={() => {
                handleNext();
                resetJobsValues();
              }}
              type="button"
              variant="outlined"
              disabled={selectedServices.length === 0}
              key="nextHandlerServices"
            >
              {t("general.buttons.next")}
            </Button>
          </>
        );
      case Steps.OVERVIEW:
        return (
          <>
            <BackButton />
            <Button
              onClick={handleNext}
              type="button"
              variant="outlined"
              disabled={!isValid || !dirty}
              key="nextHandlerServices"
            >
              {t("general.buttons.next")}
            </Button>
          </>
        );
      case Steps.DATE:
        return (
          <>
            <BackButton />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || !dirty}
              key="handleSubmit"
              startIcon={<Save />}
            >
              {t("general.buttons.save")}
            </Button>
          </>
        );
      case Steps.GENERAL:
      default:
        return (
          <>
            <Button
              onClick={async () => {
                setActiveStep(Steps.DATE);
              }}
              type="button"
              key="switchToDateScreen"
              disabled={!values.customer || !values.name}
            >
              {t("orders.createModal.steps.toDate")}
            </Button>
            <Button
              onClick={handleNext}
              type="button"
              disabled={!values.customer || !values.name}
              variant="outlined"
              key="nextHandlerCustomer"
            >
              {t("general.buttons.next")}
            </Button>
          </>
        );
    }
  }, [
    activeStep,
    BackButton,
    handleNext,
    selectedParcels.length,
    t,
    selectedServices.length,
    isValid,
    dirty,
    values.customer,
    values.name,
    resetJobsValues,
    setActiveStep,
  ]);

  return <>{buttons}</>;
};
