import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type GetTableDataParams, type InitialScreenMetaType } from "Types";
import {
  RELEASENOTES_ACTION_TYPES,
  type ReleaseNotesAction,
} from "./action-types";
import { type LocalReleaseNoteType } from "./specs";

type ReleaseNotesStateType = {
  list: LocalReleaseNoteType[];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  listUpdateLoading: boolean;
  hiddenColumns: Record<string, boolean>;
  columnsOrder?: number[];
  searchData?: SearchSpec;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: ReleaseNotesStateType = {
  list: [] as LocalReleaseNoteType[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },

  searchData: undefined,
  columnsOrder: undefined,
  hiddenColumns: {},
  initialMeta: {
    loading: true,
    error: false,
  },

  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const releaseNotesReducer = (
  state = initialState,
  action: ReleaseNotesAction,
) => {
  switch (action.type) {
    case RELEASENOTES_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };

    case RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };

    /*
     * Is fired after a successful query from the API. This action gives also the payload into "list" back.
     */
    case RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_SUCCESS:
      return {
        ...state,
        list: action.payload.releaseNotes,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: true,
        },
      };

    case RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_REQUEST:
      return state;
    /*
     * Is fired after an invocation of delete on the api.
     * Just returns the state back. No modification on state.
     */
    case RELEASENOTES_ACTION_TYPES.DELETE_RELEASENOTES:
      return {
        ...state,
        listUpdateLoading: true,
      };

    /*
     * This action sort of syncronizes the payload of the action with the redux store
     */
    case RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_SUCCESS:
      return {
        ...state,
        list: action.payload.releaseNotes,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        listUpdateLoading: false,
      };

    case RELEASENOTES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };

    case RELEASENOTES_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };

    /*

    case LICENSES_ACTION_TYPES.GET_INITIAL_LICENSES_ERROR:
    return {
      ...state,
      initialMeta: {
        loading: false,
        error: true,
      },
    };



    case LICENSES_ACTION_TYPES.SET_ERROR:
    return {
      ...state,
      success: false,
      loading: false,
      error: true,
      listUpdateLoading: false,
    };



    case LICENSES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
    return {
      ...state,
      hiddenColumns: action.payload.hiddenColumns,
    };

    case LICENSES_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
    return {
      ...state,
      columnsOrder: action.payload.order,

    };
    */
    default:
      return state;
  }
};
