import { PlotOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type GetTableDataParams, type InitialScreenMetaType } from "Types";
import { PLOTS_ACTION_TYPES, type PlotsAction } from "./action-types";

type PlotsStateType = {
  parcelId: string;
  list: PlotOutside[];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  hiddenColumns: Record<string, boolean>;
  columnsOrder?: number[];
  searchData?: SearchSpec;
  listUpdateLoading: boolean;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: PlotsStateType = {
  parcelId: "",
  list: [] as PlotOutside[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  hiddenColumns: {},
  columnsOrder: undefined,
  searchData: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const plotsReducer = (state = initialState, action: PlotsAction) => {
  switch (action.type) {
    case PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_SUCCESS:
      return {
        ...state,
        list: action.payload.plots,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case PLOTS_ACTION_TYPES.GET_PLOTS_REQUEST:
    case PLOTS_ACTION_TYPES.DELETE_PLOTS:
      return {
        ...state,
        listUpdateLoading: true,
      };
    case PLOTS_ACTION_TYPES.GET_PLOTS_FOR_PARCEL_REQUEST: {
      const parcelIdStr: string = action.payload.id as unknown as string;
      return {
        ...state,
        parcelId: parcelIdStr,
      };
    }
    case PLOTS_ACTION_TYPES.GET_PLOTS_SUCCESS:
      return {
        ...state,
        list: action.payload.plots,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        listUpdateLoading: false,
      };
    case PLOTS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case PLOTS_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case PLOTS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case PLOTS_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };
    case PLOTS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
      };
    case PLOTS_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    case PLOTS_ACTION_TYPES.RESET_STATE:
      return {
        ...initialState,
        hiddenColumns: state.hiddenColumns,
      };
    default:
      return state;
  }
};
