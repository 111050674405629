import {
  type SubscribeMessage,
  type UnsubscribeMessage,
} from "@ero/app-common/util/Websocket";
import { type JobMessage } from "Types";
import { unique } from "Utils/unique";
import {
  GENERAL_ACTION_TYPES,
  type IChangeWsSubsctiption,
  type ICloseWs,
  type IOpenWs,
  type IUpdateJobLive,
} from "./action-types";

export const openWs = (): IOpenWs => ({
  type: GENERAL_ACTION_TYPES.OPEN_WEB_SOCKET,
});

export const closeWs = (): ICloseWs => ({
  type: GENERAL_ACTION_TYPES.CLOSE_WEB_SOCKET,
});

export const changeWsSubscription = (
  message: SubscribeMessage | UnsubscribeMessage,
): IChangeWsSubsctiption => {
  if (Array.isArray(message.meta)) {
    message.meta = unique(message.meta);
  }

  return {
    type: GENERAL_ACTION_TYPES.CHANGE_WS_SUBSCRIPTION,
    payload: { subscriptionMessage: JSON.stringify(message) },
  };
};

export const updateJobLive = (message: JobMessage): IUpdateJobLive => ({
  type: GENERAL_ACTION_TYPES.UPDATE_JOB_LIVE,
  payload: { message },
});
