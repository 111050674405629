import { type TELEMATICS } from "@ero/app-common/enums";
import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Input, Loader } from "Components";
import { DurationInput } from "Components/FormikDateInput/DurationInput";
import { CloseButton } from "Components/closeButton/closeButton";
import { TelemetryType } from "Enums";
import { type AppState } from "Store";
import { updateJobTelemetry } from "Store/orderDetails";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTelemetryEntries } from "../../telemetryConfig";

interface EditTelemetryModal {
  open: boolean;
  onClose: () => void;
  jobId: number;
  telemetryType?: TELEMATICS;
}

export const EditTelemetryModal: React.FC<EditTelemetryModal> = ({
  open,
  onClose,
  jobId,
  telemetryType,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { loading, success } = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetryStatus,
  );
  const jobTelemetry = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetry,
  );

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success, onClose]);

  const telemetryEntries = useTelemetryEntries();

  const telemetryConfig = useMemo(
    () =>
      telemetryEntries.find((entry) => entry.telemetryType === telemetryType),
    [telemetryEntries, telemetryType],
  );

  if (!telemetryConfig || telemetryType === undefined) {
    return null;
  }

  const { name, inputType } = telemetryConfig;

  const initialValues = { value: jobTelemetry[telemetryConfig.key] };

  const i18nName = t(name);
  const modalTitle = t("telemetry.editModalTitle", { name: i18nName });

  let inputComponent: React.ReactElement;
  const inputProps = { label: i18nName, name: "value" };

  if (inputType === "time") {
    if (telemetryType === TelemetryType.tracked) {
      inputProps["useMilliseconds"] = true;
    }
    inputComponent = (
      <DurationInput {...inputProps} timeSteps={{ minutes: 1 }} />
    );
  } else {
    inputComponent = <Input type="number" {...inputProps} />;
  }

  const handleFormSubmit = ({ value }) => {
    const telemetry = { ...jobTelemetry };
    delete telemetry.track;
    telemetry[telemetryConfig.key] = value;
    dispatch(updateJobTelemetry(jobId, telemetry));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" scroll="body">
      <DialogTitle>{modalTitle}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ dirty, isValid, submitForm }) => (
          <>
            <DialogContent dividers>
              <Form>{inputComponent}</Form>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!isValid || !dirty || loading}
                onClick={submitForm}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
