import { Chip, Divider, Grid2 } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import {
  FilterOptions,
  FilterType,
  planningV2Slice,
} from "Store/planningV2/planningV2slice";
import { OptionType } from "Types";

export const FiltersChips: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters, filtersOptions } = useSelector(
    (state: AppState) => state.planningV2,
  );

  const getLabel = useCallback(
    (key: keyof FilterOptions, value: OptionType["value"]) => {
      const option = filtersOptions[key].find((o) => o.value === value);
      return option?.label;
    },
    [filtersOptions],
  );

  const onDelete = useCallback(
    async (key: keyof FilterType, value: number | string) => {
      const updatedFilters = { ...filters };
      if (key === "grapeVarietyColor") {
        updatedFilters[key] = undefined;
      } else {
        const changedFilter = updatedFilters[key]?.filter((c) => c !== value);
        updatedFilters[key] = changedFilter;
      }
      dispatch(planningV2Slice.actions.setFilters(updatedFilters));
      dispatch(sagaActions.fetchOrders());
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const filterContent = useMemo(
    () => (
      <>
        {filters.customer?.map((customer) => (
          <Chip
            size="small"
            key={customer}
            label={getLabel("customers", customer)}
            onDelete={() => onDelete("customer", customer)}
          />
        ))}
        {filters.service?.map((service) => (
          <Chip
            size="small"
            key={service}
            label={service}
            onDelete={() => onDelete("service", service)}
          />
        ))}
        {filters.grapeVariety?.map((grapeVariety) => (
          <Chip
            size="small"
            key={grapeVariety}
            label={getLabel("grapeVarieties", grapeVariety)?.split(" (")[0]}
            onDelete={() => onDelete("grapeVariety", grapeVariety)}
          />
        ))}
        {filters.grapeVarietyColor !== undefined && (
          <Chip
            size="small"
            key={"grapeVarietyColor"}
            label={t(`parcels.kind.${filters.grapeVarietyColor}`)}
            onDelete={() =>
              onDelete("grapeVarietyColor", filters.grapeVarietyColor ?? "")
            }
          />
        )}
        {filters.mark?.map((mark) => (
          <Chip
            size="small"
            key={mark}
            label={mark}
            onDelete={() => onDelete("mark", mark)}
          />
        ))}
        {filters.regionette?.map((regionette) => (
          <Chip
            size="small"
            key={regionette}
            label={regionette}
            onDelete={() => onDelete("regionette", regionette)}
          />
        ))}
      </>
    ),
    [
      filters.customer,
      filters.grapeVariety,
      filters.grapeVarietyColor,
      filters.mark,
      filters.regionette,
      filters.service,
      getLabel,
      onDelete,
      t,
    ],
  );

  const hasFilters = useMemo(() => {
    return (
      Object.keys(filters).reduce((curr, key) => {
        if (key === "grapeVarietyColor" && key === undefined) {
          return curr++;
        } else {
          return curr + (filters[key]?.length ?? 0);
        }
      }, 0) > 0
    );
  }, [filters]);

  return (
    <>
      {hasFilters && (
        <>
          <Divider />
          <Grid2
            container
            direction={"row"}
            p={1}
            pl={3}
            pr={3}
            alignItems={"center"}
          >
            <Grid2 container spacing={1}>
              {filterContent}
            </Grid2>
          </Grid2>
        </>
      )}
    </>
  );
};
