import { BILLINGUNIT } from "@ero/app-common/enums";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { UNIT } from "@ero/app-common/util/Units";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useBillingUnitOptions = (unit: UNIT) => {
  const [t] = useTranslation();
  const options = useMemo(
    () => [
      {
        label: t("general.billingUnit.none"),
        value: BILLINGUNIT.NONE,
      },
      {
        label: t("general.billingUnit.meterHarvested"),
        value: BILLINGUNIT.METERHARVESTED,
      },
      {
        label: t("general.billingUnit.meterShaked"),
        value: BILLINGUNIT.METERSHAKED,
      },
      {
        label: t("general.billingUnit.timeHarvested"),
        value: BILLINGUNIT.TIMEHARVESTED,
      },
      {
        label: t("general.billingUnit.timeShaked"),
        value: BILLINGUNIT.TIMESHAKED,
      },
      {
        label: t("general.billingUnit.area", {
          unit: UnitSymbol[unit],
        }),
        value: BILLINGUNIT.AREA,
      },
      {
        label: t("general.billingUnit.fixedRate"),
        value: BILLINGUNIT.FIXEDRATE,
      },
    ],
    [t, unit],
  );
  return options;
};
