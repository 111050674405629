import {
  type CREATE_MACHINE_DTO,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  IResetSearchAction,
  MACHINES_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreateMachineRequestAction,
  type IDeleteMachinesRequestAction,
  type IGetInitialMachinesErrorAction,
  type IGetInitialMachinesRequestAction,
  type IGetInitialMachinesSuccessAction,
  type IGetMachinesRequestAction,
  type IGetMachinesSuccessAction,
  type IResetMetaAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateMachineRequestAction,
} from "./action-types";
import { type LocalMachineType } from "./specs";

export const setLoading = (): ISetLoadingAction => ({
  type: MACHINES_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: MACHINES_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: MACHINES_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: MACHINES_ACTION_TYPES.RESET_META,
});

export const resetSearch = (): IResetSearchAction => ({
  type: MACHINES_ACTION_TYPES.RESET_SEARCH,
});

export const getMachinesInitial = (
  params: GetTableDataParams,
): IGetInitialMachinesRequestAction => {
  return {
    type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_REQUEST,
    payload: {
      params,
    },
  };
};

export const getMachinesInitialSuccess = (
  machines: LocalMachineType[],
  maxCount: number,
): IGetInitialMachinesSuccessAction => ({
  type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_SUCCESS,
  payload: {
    machines,
    maxCount,
  },
});

export const getMachinesInitialError = (): IGetInitialMachinesErrorAction => ({
  type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_ERROR,
});

export const getMachines = ({
  params,
  withSuccess,
  search,
  filters,
}: GetTableEntitiesType): IGetMachinesRequestAction => {
  return {
    type: MACHINES_ACTION_TYPES.GET_MACHINES_REQUEST,
    payload: {
      params,
      withSuccess,
      search,
      filters,
    },
  };
};

export const getMachinesSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
  filters,
}: SetTableEntityType<LocalMachineType>): IGetMachinesSuccessAction => ({
  type: MACHINES_ACTION_TYPES.GET_MACHINES_SUCCESS,
  payload: {
    machines: list,
    maxCount,
    listMeta,
    search,
    filters,
  },
});

export const createMachine = (
  machine: CREATE_MACHINE_DTO,
): ICreateMachineRequestAction => ({
  type: MACHINES_ACTION_TYPES.CREATE_MACHINE_REQUEST,
  payload: {
    machine,
  },
});

export const deleteMachines = (ids: {
  ids: number[];
}): IDeleteMachinesRequestAction => ({
  type: MACHINES_ACTION_TYPES.DELETE_MACHINES_REQUEST,
  payload: {
    ids,
  },
});

export const updateMachine = (
  machine: any,
  id: number,
): IUpdateMachineRequestAction => ({
  type: MACHINES_ACTION_TYPES.UPDATE_MACHINE_REQUEST,
  payload: {
    machine,
    id,
  },
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
): ISetTableHiddenColumns => ({
  type: MACHINES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: MACHINES_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});
