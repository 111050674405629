import { Error } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const AuthError: React.FC = () => {
  const [t] = useTranslation();

  const theme = useTheme();

  return (
    <Box>
      <Box textAlign={"center"} display="flex" flexDirection={"column"}>
        <>
          <Box>
            <Error
              sx={{
                color: theme.palette.error.main,
                fontSize: "75px",
              }}
            />
          </Box>
          <Box>
            <Typography paddingTop="20px" variant="h4">
              {t("notification.error.externalAccessTokenExpired.title")}
            </Typography>
            <Typography paddingY="20px" variant="body1">
              {t("notification.error.externalAccessTokenExpired.description")}
            </Typography>
          </Box>
        </>
      </Box>
    </Box>
  );
};
