import { ServiceOutside } from "@ero/app-common/models";
import { type EntitySpec } from "@ero/app-common/util/Spec";
import { apiInstance } from "App/axios";
import { GET_ALL_PARAMS } from "Constants";
import { RequestBody, type ApiListRequestParams } from "Types";
import {
  type CREATE_SERVICE_DTO,
  type ServicesListResponse,
} from "Types/models/services";

export const getServices = ({
  params,
  search,
  filters,
  spec,
}: ApiListRequestParams<ServiceOutside>) =>
  apiInstance.post<ServicesListResponse>(
    "/services/all",
    { search, filters, spec },
    { params },
  );

export const getAllServices = (spec: EntitySpec<ServiceOutside> = []) =>
  apiInstance.post<ServicesListResponse>(
    "/services/all",
    { spec },
    { params: GET_ALL_PARAMS },
  );

export const createService = (data: CREATE_SERVICE_DTO) =>
  apiInstance.post("/services/create", data);

export const deleteServices = (ids: number[]) =>
  apiInstance.post("/services/delete", { ids });

export const updateService = (
  updateData: RequestBody<"/services/update">,
  id: number,
) => {
  const body = {
    ...updateData,
    _id: id,
  };
  return apiInstance.post("/services/update", body);
};
