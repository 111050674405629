import { type Coordinate } from "@ero/app-common/util/Coordinate";
import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { TrackPoint } from "@ero/app-common/v2/utils/TrackPoint";
import { Marker } from "@react-google-maps/api";
import MapPointGreenSvg from "Assets/icons/map-point-green.svg";
import { TrackV2 } from "Components";
import { useUserTitle } from "Hooks/userTitle";
import React, { Fragment, useCallback, useMemo } from "react";

const getDriverIconOptions = (
  icon: string,
  size = { width: 120, height: 120 },
): google.maps.Icon => ({
  url: icon,
  scaledSize: new google.maps.Size(size.width, size.height),
  anchor: new google.maps.Point(size.width / 2, size.height / 2),
});

/**
 * Convert HSL to RGB color. See https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_RGB_alternative for an explanation
 * @param hue
 * @param saturation
 * @param lightness
 * @returns the rgb values for a given HSL color
 */
const hslToRgb = (hue: number, saturation: number, lightness: number) => {
  saturation /= 100;
  lightness /= 100;
  const k = (n: number) => (n + hue / 30) % 12;
  const a = saturation * Math.min(lightness, 1 - lightness);
  const f = (n: number) =>
    lightness - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [255 * f(0), 255 * f(8), 255 * f(4)];
};

/**
 * Get a track color in RGB by spreading the colors evenly across spectrum based on the total count of drivers
 * @param driverIndex the index of the driver in the list of drivers
 * @param driverCount the length of the list of drivers
 * @returns a bright color for the track
 */
const getBrightRGBColor = (driverIndex: number, driverCount: number) => {
  return hslToRgb((driverIndex / driverCount) * 255, 100, 50);
};

const markerSize = { width: 36, height: 36 };

const DriverMarker: React.FC<{
  position: Coordinate;
  driver: UserResponseBody;
}> = ({ position, driver }) => {
  const userTitle = useUserTitle(driver);
  return (
    <Marker
      position={position}
      icon={getDriverIconOptions(driver.avatar || MapPointGreenSvg, markerSize)}
      title={userTitle}
      zIndex={1001}
    />
  );
};

const DriverTrack: React.FC<{
  driverIndex: number;
  driverCount: number;
  track: TrackPoint[];
}> = ({ driverIndex, driverCount, track }) => {
  const getPath = useCallback(
    (track: UserResponseBody["track"]) =>
      track?.map((t) => ({
        lat: t.latitude,
        lng: t.longitude,
      })),
    [],
  );

  const randomColor = useMemo(
    () => `rgb(${getBrightRGBColor(driverIndex, driverCount).join(",")})`,
    [driverIndex, driverCount],
  );

  const path = useMemo(() => getPath(track), [track, getPath]);

  return (
    <TrackV2
      path={path}
      options={{
        strokeColor: randomColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        zIndex: 999,
      }}
    ></TrackV2>
  );
};

interface IDriversLiveTrack {
  drivers: UserResponseBody[];
}

export const DriversLiveTrack: React.FC<IDriversLiveTrack> = ({ drivers }) => {
  const tracks = useMemo(
    () =>
      drivers
        .sort((a, b) => a._id - b._id)
        .map((driver, index, array) => {
          const { _id, track } = driver;
          const lastEntry = track?.[track.length - 1];
          if (!lastEntry) {
            return <Fragment key={`d${_id}`}></Fragment>;
          }

          const position = {
            lat: lastEntry?.latitude,
            lng: lastEntry?.longitude,
          };
          return (
            <Fragment key={`d${_id}`}>
              <DriverMarker position={position} driver={driver} />
              <DriverTrack
                driverIndex={index}
                driverCount={array.length}
                track={track}
              />
            </Fragment>
          );
        }),
    [drivers],
  );

  return <>{tracks}</>;
};
