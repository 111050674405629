import React from "react";
import { useTranslation } from "react-i18next";

interface IHelpButton {
  showHelpBtn?: boolean;
  onHelpBtnClick?: () => void;
}

export const HelpButton: React.FC<IHelpButton> = ({
  showHelpBtn,
  onHelpBtnClick,
}) => {
  const [t] = useTranslation();

  if (!showHelpBtn) {
    return null;
  }

  return (
    <div>
      <div onClick={onHelpBtnClick}>
        {t("components.calendar.settingsMenu.help")}
      </div>
    </div>
  );
};
