import { LANGUAGE } from "@ero/app-common/enums/language";
import {
  type CHANGE_PASSWORD_DTO,
  type CompanyType,
  type Coordinate,
  type LOGIN_DTO,
  type ResetPassType,
  type TokenType,
  type UserDataType,
  type VerifyPassType,
} from "Types";
import {
  AUTH_ACTION_TYPES,
  IToggleSidebarSubmenu,
  type IChangeColorMode,
  type IChangeLanguage,
  type IChangePassword,
  type IForgotPasswordRequestAction,
  type IGetUserData,
  type IInitialRequest,
  type ILoginRequestAction,
  type ILoginSuccessAction,
  type ILogoutSuccessAction,
  type IRefreshTokenSuccessAction,
  type IResetMetaAction,
  type IResetPasswordRequestAction,
  type ISetCompanyDetails,
  type ISetErrorAction,
  type ISetLanguage,
  type ISetLoadingAction,
  type ISetResetToken,
  type ISetSuccessAction,
  type ISetUserData,
  type IToggleSidebar,
  type IUpdateCompanyData,
  type IUpdateUserData,
  type IVerifyPasswordRequestAction,
} from "./action-types";

export const initialAuthRequest = (): IInitialRequest => ({
  type: AUTH_ACTION_TYPES.INITIAL_REQUEST,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: AUTH_ACTION_TYPES.SET_SUCCESS,
});

export const setResetToken = (resetToken: string): ISetResetToken => ({
  type: AUTH_ACTION_TYPES.SET_RESET_TOKEN,
  payload: {
    resetToken,
  },
});

export const setLoading = (): ISetLoadingAction => ({
  type: AUTH_ACTION_TYPES.SET_LOADING,
});

export const setError = (): ISetErrorAction => ({
  type: AUTH_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: AUTH_ACTION_TYPES.RESET_META,
});

export const login = (credentials: LOGIN_DTO): ILoginRequestAction => ({
  type: AUTH_ACTION_TYPES.LOGIN_REQUEST,
  payload: { credentials },
});

export const loginSuccess = (
  tokens: TokenType,
  userData: UserDataType,
  companyData: CompanyType,
): ILoginSuccessAction => ({
  type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
  payload: { tokens, userData, companyData },
});

export const logout = () => {
  return {
    type: AUTH_ACTION_TYPES.LOGOUT_REQUEST,
  };
};

export const logoutSuccess = (): ILogoutSuccessAction => {
  return {
    type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS,
  };
};

export const verifyPassword = (
  credentials: VerifyPassType,
): IVerifyPasswordRequestAction => ({
  type: AUTH_ACTION_TYPES.VERIFY_PASS_REQUEST,
  payload: { credentials },
});

export const resetPassword = (
  passwordData: ResetPassType,
): IResetPasswordRequestAction => ({
  type: AUTH_ACTION_TYPES.RESET_PASS_REQUEST,
  payload: { passwordData },
});

export const forgotPassword = (
  email: string,
): IForgotPasswordRequestAction => ({
  type: AUTH_ACTION_TYPES.FORGOT_PASSWORD_REQUEST,
  payload: { email },
});

export const refreshAccessToken = (
  accessToken: string,
): IRefreshTokenSuccessAction => ({
  type: AUTH_ACTION_TYPES.REFRESH_TOKEN_SUCCESS,
  payload: {
    accessToken,
  },
});

export const setCompanyDetails = (
  companyData: CompanyType,
): ISetCompanyDetails => ({
  type: AUTH_ACTION_TYPES.SET_COMPANY_DETAILS,
  payload: { companyData },
});

export const updateUserData = (
  values: Record<string, string>,
): IUpdateUserData => ({
  type: AUTH_ACTION_TYPES.UPDATE_USERDATA,
  payload: { values },
});

export const updateCompanyData = (
  values: Record<string, string | Coordinate>,
): IUpdateCompanyData => ({
  type: AUTH_ACTION_TYPES.UPDATE_COMPANY_DETAILS,
  payload: { values },
});

export const setUserData = (userData: UserDataType): ISetUserData => ({
  type: AUTH_ACTION_TYPES.SET_USERDATA,
  payload: { userData },
});

export const getUserData = (): IGetUserData => ({
  type: AUTH_ACTION_TYPES.GET_USERDATA,
});

export const changeLanguage = (lang: LANGUAGE): IChangeLanguage => ({
  type: AUTH_ACTION_TYPES.CHANGE_LANGUAGE,
  payload: { lang },
});

export const setLanguage = (lang: string): ISetLanguage => ({
  type: AUTH_ACTION_TYPES.SET_LANGUAGE,
  payload: { lang },
});

export const changeColorMode = (mode: string): IChangeColorMode => ({
  type: AUTH_ACTION_TYPES.CHANGE_COLORMODE,
  payload: { mode },
});

export const changePassword = (data: CHANGE_PASSWORD_DTO): IChangePassword => ({
  type: AUTH_ACTION_TYPES.CHANGE_PASSWORD,
  payload: { data },
});

export const toggleSidebar = (value: boolean): IToggleSidebar => ({
  type: AUTH_ACTION_TYPES.TOGGLE_SIDEBAR,
  payload: { value },
});

export const toggleSidebarSubmenu = (
  value: boolean,
): IToggleSidebarSubmenu => ({
  type: AUTH_ACTION_TYPES.TOGGLE_SIDEBAR_SUBMENU,
  payload: { value },
});
