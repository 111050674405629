import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { setSelectedEmployees } from "Store/planning";
import { type CalendarResourceType } from "Types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface IUseResources {
  employees: UserResponseBody[];
  selectedEmployees: number[];
}

export const useResources = (props: IUseResources) => {
  const { employees, selectedEmployees } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [resources, setResources] = useState<CalendarResourceType[]>([]);

  const makeResources = useCallback(
    (employees: UserResponseBody[]) => {
      const resources = selectedEmployees.includes(0)
        ? [{ id: "0", title: t("general.labels.any") }]
        : [];

      employees.forEach((employee) => {
        const title =
          `${employee.firstName || ""} ${employee.lastName || ""}`.trim() ||
          employee.userName;
        resources.push({
          id: `${employee._id}`,
          title,
        });
      });

      return resources;
    },
    [t, selectedEmployees],
  );

  const updateResources = useCallback(() => {
    const filteredEmployees =
      selectedEmployees.length > 0
        ? employees.filter((e) => selectedEmployees.includes(e._id))
        : employees;
    const resources = makeResources(filteredEmployees);
    setResources(resources);
  }, [employees, selectedEmployees, makeResources]);

  useEffect(() => {
    if (selectedEmployees.length === 0)
      dispatch(
        setSelectedEmployees(
          isInitialLoad ? [0, ...employees.map((e) => e._id)] : [0],
        ),
      );
    setIsInitialLoad(false);
    updateResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, selectedEmployees.length, updateResources]);

  const resourceDidMount = useCallback(
    (params: { resource: CalendarResourceType; el: Element }) => {
      const { el } = params;
      el.children[0].children[0].setAttribute("style", "height: 40px");
    },
    [],
  );

  return { resources, resourceDidMount };
};
