import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FilterOptions } from "Store/planningV2/planningV2slice";

export const useFilterOptions = (
  orders: OrderResponseBody<number[]>[],
): FilterOptions => {
  const [t] = useTranslation();

  const filterOptions = useMemo(() => {
    const rawFilterOptions = orders
      .map((order) => ({
        customer: order.customer,
        services: order.jobDetails?.services || [],
        grapeVarieties: order.jobDetails?.grapeVarieties || [],
        gemarkungen: order.jobDetails?.gemarkungen || [],
        regionettes: order.jobDetails?.regionettes || [],
      }))
      .filter((f) => f !== undefined)
      .flat();
    const customers = rawFilterOptions
      .map((f) => f.customer)
      .filter(
        (customer, index, customers) =>
          customers.findIndex((c) => c._id === customer._id) === index,
      )
      .map((customer) => ({
        label: customer.companyName,
        value: customer._id,
      }));
    const services = [
      ...new Set(rawFilterOptions.map((f) => f.services).flat()),
    ].map((service) => ({ label: service, value: service }));
    const grapeVarieties = rawFilterOptions
      .map((f) => f.grapeVarieties)
      .flat()
      .filter(
        (grapeVariety, index, grapeVarieties) =>
          grapeVarieties.findIndex((g) => g._id === grapeVariety._id) === index,
      )
      .map((grapeVariety) => ({
        label: grapeVariety.name,
        value: grapeVariety._id,
      }));
    const grapeVarietyColors = rawFilterOptions
      .map((f) => f.grapeVarieties)
      .flat()
      .filter(
        (grapeVariety, index, grapeVarieties) =>
          grapeVarieties.findIndex((c) => c.color === grapeVariety.color) ===
          index,
      )
      .map((grapeVariety) => ({
        label: t("parcels.kind." + grapeVariety.color),
        value: grapeVariety.color,
      }));
    const regionettes = [
      ...new Set(rawFilterOptions.map((f) => f.regionettes).flat()),
    ].map((regionette) => ({ label: regionette, value: regionette }));
    const gemarkungen = [
      ...new Set(rawFilterOptions.map((f) => f.gemarkungen).flat()),
    ].map((mark) => ({ label: mark, value: mark }));
    return {
      customers,
      services,
      grapeVarieties,
      grapeVarietyColors,
      regionettes,
      gemarkungen,
    };
  }, [orders, t]);
  return filterOptions;
};
