import { useSearchParams } from "react-router-dom";

/** typing here is a bit wild, but what it acutally does is:
 * 1. take some names of URL query parameters
 * 2. get the value for that parameter
 * 3. return it in an object that has (type-safe!) keys reflecting the given query parameters
 */
export const useQueryParams = <T extends string[]>(...keys: T) => {
  const [queryParams] = useSearchParams();
  return keys.reduce((acc, curr) => {
    acc[curr] = queryParams.get(curr);
    return acc;
  }, {} as { [k in (typeof keys)[number]]: string | null });
};
