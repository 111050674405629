import { Box, Card, CardContent, List, ListSubheader } from "@mui/material";
import { Loader } from "Components";
import { AppState } from "Store";
import React from "react";
import { useSelector } from "react-redux";
import { TelemetryList } from "./components/telemetryList/telemetryList";

interface ITelemetryCard {
  jobId: number;
}

export const TelemetryCard: React.FC<ITelemetryCard> = ({ jobId }) => {
  const { loading } = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetryStatus,
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <List>
          <ListSubheader>Telemetriewerte</ListSubheader>
          <TelemetryList jobId={jobId} />
        </List>
      </CardContent>
      {loading && (
        <Box position={"absolute"} top={0} width={"100%"} height={"100%"}>
          <Loader />
        </Box>
      )}
    </Card>
  );
};
