import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { DeleteOutline } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useMemo } from "react";
import { getJobStatusColor } from "Utils";

interface IJobItem {
  job: JobResponseBody;
  onClick: (event, id: number) => void;
  onDelete: (jobId: number) => void;
}

export const JobItem: React.FC<IJobItem> = ({ job, onClick, onDelete }) => {
  const jobStatusColor = useMemo(
    () => getJobStatusColor(job.status).split(".")[1] ?? "default",
    [job.status],
  );

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => onDelete(job._id)}
          >
            <DeleteOutline />
          </IconButton>
        }
        sx={(theme) => ({
          borderLeft: `5px solid ${theme.palette.status[jobStatusColor]}`,
        })}
      >
        <ListItemButton onClick={(event) => onClick(event, job._id)}>
          <ListItemText primary={job.name} secondary={job.parcel.name} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};
