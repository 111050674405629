import { CompanyDropdown } from "@ero/app-common/models";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useMachineDropdownOptions } from "Utils/machines";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const { kindOptions } = useMachineDropdownOptions();
  const [t] = useTranslation();
  return [
    {
      field: "internalId",
      headerName: t("machines.fields.id"),
    },
    {
      field: "name",
      headerName: t("general.fields.name"),
    },
    {
      field: "kind",
      headerName: t("general.fields.type"),
      valueGetter: (value) =>
        kindOptions.find((opt) => opt.value === value)?.label,
    },
    {
      field: "manufacturer",
      headerName: t("machines.fields.manufacturer"),
      valueGetter: (value: CompanyDropdown) =>
        value._id !== -1 ? value.name : "-",
    },
    {
      field: "licensePlate",
      headerName: t("machines.fields.licensePlate"),
    },
    {
      field: "intact",
      headerName: t("general.fields.status"),
      valueGetter: (value) =>
        value ? t("machines.state.active") : t("machines.state.broken"),
    },
  ];
};
