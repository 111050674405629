import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { FilterContainer } from "./FilterContainer";

export const PlannedUnplannedSwitch: React.FC<{
  showUnplanned: boolean;
  onChange: (showUnplanned: boolean) => void;
}> = ({ showUnplanned, onChange }) => {
  return (
    <FilterContainer>
      <Stack direction="row" alignItems="center">
        <Typography>Geplante</Typography>
        <Switch
          color="default"
          checked={showUnplanned}
          onChange={(_, checked) => onChange(checked)}
        />
        <Typography>Ungeplante</Typography>
      </Stack>
    </FilterContainer>
  );
};
