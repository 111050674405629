import { ParcelHistoryResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { getParcelHistory } from "Api";
import { LocalParcelType } from "Store/parcels/specs";
import React, { useEffect, useState } from "react";
import { HistoryList } from "./components";

interface IProps {
  parcel?: Partial<LocalParcelType>;
  values?: any;
  setFieldValue?: any;
}

export const History: React.FC<IProps> = ({ parcel }) => {
  const [parcelHistory, setParcelHistory] =
    useState<ParcelHistoryResponseBody["jobHistory"]>();

  useEffect(() => {
    const fetchParcelHistory = async () => {
      if (parcel?._id) {
        const {
          data: { jobHistory },
        } = await getParcelHistory(parcel._id);
        setParcelHistory(jobHistory);
      }
    };
    fetchParcelHistory();
  }, [parcel?._id]);

  return (
    <>
      <HistoryList historyItems={parcelHistory} info={parcel?.jobInfo} />
    </>
  );
};
