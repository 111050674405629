import { SqMeter } from "@ero/app-common/util/Units";

export const getJobDurationInMs = (
  size: SqMeter | -1 | undefined,
  difficulty: number,
) => {
  if (size === undefined || size === -1) {
    return 3600;
  }

  return Math.round(size * difficulty * 1000);
};
