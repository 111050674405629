import { Settings as SettingsIcon } from "@mui/icons-material";
import { Box, IconButton, Popover, Slider, Typography } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import { CalendarSlotDuration } from "Enums";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";

const marks = [
  { value: CalendarSlotDuration.FIVE_MINUTES, label: "5 min" },
  { value: CalendarSlotDuration.FIVETEEN_MINUTES, label: "15 min" },
  { value: CalendarSlotDuration.THIRTY_MINUTES, label: "30 min" },
  { value: CalendarSlotDuration.ONE_HOUR, label: "1 h" },
];

export const Settings: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { slotDuration } = useSelector((state: AppState) => state.planningV2);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onCalendarZoomChange = useCallback(
    (_: Event, value: number | number[]) => {
      if (!Array.isArray(value)) {
        dispatch(sagaActions.setSlotInterval(value));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={fullscreenContainer}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box m={1} width="400px">
          <Typography fontWeight={"500"}>
            {t("planningV2.slotDuration")}
          </Typography>
          <Box m={3}>
            <Slider
              defaultValue={CalendarSlotDuration.FIVETEEN_MINUTES}
              value={slotDuration}
              marks={marks}
              onChange={onCalendarZoomChange}
              size="small"
              step={null}
              track={false}
              min={0}
              max={3}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};
