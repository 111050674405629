import { Coordinate } from "@ero/app-common/util/Coordinate";
import { polygon as createPolygon, lineString } from "@turf/turf";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPolygonArea } from "Utils";
import { useCutPolygon } from "./turf/polygonCut";
import { PolygonWithSize } from "./types";

export const useSplit = () => {
  const [t] = useTranslation();

  const cutPolygon = useCutPolygon();

  const split = useMemo(
    () =>
      (
        parcelPolygon: Coordinate[],
        splitLineCoords: google.maps.LatLng[],
      ): PolygonWithSize[] => {
        const geoJSONCoordsParcel = parcelPolygon.map((coord) => [
          coord.lng,
          coord.lat,
        ]);

        const lineGeoJSON = lineString(
          splitLineCoords.map((coord) => [coord.lng(), coord.lat()]),
        );

        const newPolygons = cutPolygon(
          createPolygon([geoJSONCoordsParcel]),
          lineGeoJSON,
        );

        if (newPolygons) {
          const parcelsWithShapeAndArea: PolygonWithSize[] = [];

          newPolygons.features.forEach((poly) => {
            const shape = poly.geometry.coordinates.map((coordinates) =>
              coordinates.map((coord) => {
                return {
                  lng: coord[0],
                  lat: coord[1],
                } as Coordinate;
              }),
            )[0];

            const size = getPolygonArea(shape);

            parcelsWithShapeAndArea.push({ shape, size });
          });

          return parcelsWithShapeAndArea;
        }

        throw new Error("Unable to split polygon", {
          cause: t("notification.error.polygonSplitting.cause.savingFailed"),
        });
      },
    [cutPolygon, t],
  );

  return split;
};
