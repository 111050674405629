import { VALIDATION_ERRORS } from "Constants";
import { type VERIFY_PASS_DTO } from "Types";
import * as Yup from "yup";

export const initialValues: VERIFY_PASS_DTO = {
  password: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
  });
