import { getServices } from "Api";
import { OptionType } from "Types";

export const useAllServiceOptions = () => {
  const fetchServiceOptions = async (): Promise<OptionType[]> => {
    try {
      const {
        data: { data },
      } = await getServices({
        params: { page: 0, pageLength: -1 },
        spec: [["name"], ["additionalName"]],
      });
      return data.map((service) => ({
        value: service._id,
        label: `${service.name}`,
      }));
    } catch {
      return [];
    }
  };

  return {
    fetchServiceOptions,
  };
};
