import { RegularButton } from "Components";
import { AppState } from "Store";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const SubmitButton: React.FC = () => {
  const { isValid } = useFormikContext();
  const [t] = useTranslation();
  const loading = useSelector((state: AppState) => state.orderDetails.loading);

  return (
    <RegularButton type="submit" loading={loading} disabled={!isValid}>
      {t("general.buttons.save")}
    </RegularButton>
  );
};
