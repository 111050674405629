import { Box } from "@mui/material";
import React from "react";
import loadingSpinner from "../../Assets/ERO-hexagon-spinner.gif";

type LoaderProps = {
  size?: string | number;
};

export const Loader: React.FC<LoaderProps> = ({ size = 80 }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        src={loadingSpinner}
        alt="Loading indicator"
        width={size}
      />
    </Box>
  );
};
