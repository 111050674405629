import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import React from "react";
import { useTranslation } from "react-i18next";

export const ImportHelpModal: React.FC<{
  open: boolean;
  onClose: () => void;
  videoUrl: string;
  helpText?: React.JSX.Element;
}> = ({ open, onClose, videoUrl, helpText }) => {
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle>{t("parcels.upload.importHelpModal.title")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent sx={{ p: 0 }} dividers={!!helpText}>
        {helpText && <Box sx={{ m: 2 }}>{helpText}</Box>}
        <Box
          textAlign={"center"}
          mt={2}
          sx={{
            p: 0,
            aspectRatio: "16/9",
            width: helpText ? "100%" : 840,
            overflow: "hidden",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
      </DialogContent>
      {helpText && (
        <DialogActions>
          <Button onClick={onClose}>{t("general.buttons.next")}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
