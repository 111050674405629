import { CalendarView } from "Enums";
import { useMemo } from "react";

export const useCalendarButtons = (
  showLeftButtons: boolean,
  showMenu?: boolean,
) => {
  const headerToolbar = useMemo(
    () => ({
      left: "prev,title,next today",
      right:
        (showLeftButtons ? "" : "") +
        CalendarView.MONTH +
        "," +
        CalendarView.WEEK +
        "," +
        CalendarView.DAY +
        "," +
        CalendarView.TIMELINE +
        (showMenu ? " expandMenuButton" : ""),
    }),
    [showMenu, showLeftButtons],
  );

  return {
    headerToolbar,
  };
};
