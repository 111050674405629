import deTranslation from "@fullcalendar/core/locales/de";
import enTranslation from "@fullcalendar/core/locales/es-us";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { Theme } from "@mui/material";

const plugins = [
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  resourceTimeGridPlugin,
  scrollGridPlugin,
];

export const getBaseCalendarConfig = (theme: Theme) => ({
  allDaySlot: false,
  dayMaxEvents: true,
  dayMinWidth: 130,
  dragRevertDuration: 0,
  expandRows: true,
  eventColor: theme.palette.grey[500],
  firstDay: 1,
  handleWindowResize: true,
  height: "100%",
  lazyFetching: true,
  locales: [deTranslation, enTranslation],
  plugins,
  resourceAreaWidth: 205,
  resourcesInitiallyExpanded: false,
  selectMirror: true,
  schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
  scrollTimeReset: false,
  slotDuration: "00:05:00",
  slotEventOverlap: false,
  timeZone: "local",
  weekends: true,
});
