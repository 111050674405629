import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { Edit, Event } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AppState } from "Store";
import { displayDateRestriction } from "Utils/date";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateModal } from "../dateModal";

export const Deadline: React.FC = () => {
  const [t] = useTranslation();

  const [openDateModal, setOpenDateModal] = useState(false);

  const { order } = useSelector((state: AppState) => state.orderDetails);
  const dateRestrictionsContent = displayDateRestriction(
    order?.dateRestrictions,
    t("orders.createModal.toDo"),
    t("orders.createModal.until"),
  );

  return (
    <ListItem
      secondaryAction={
        <IconButton
          sx={{ width: 32, height: 32 }}
          onClick={() => setOpenDateModal(true)}
        >
          <Edit fontSize="small" />
        </IconButton>
      }
    >
      <ListItemIcon>
        <Event />
      </ListItemIcon>
      <ListItemText
        primary={<>{dateRestrictionsContent}</>}
        secondary={t("orders.createModal.date")}
      />
      <DateModal
        isOpen={openDateModal}
        onClose={() => {
          setOpenDateModal(false);
        }}
        givenDate={
          typeof order?.dateRestrictions === "object" &&
          order?.dateRestrictions !== null
            ? order?.dateRestrictions
            : {
                dateMode: DateMode.DATERANGE,
                timeMode: DateTimeMode.WITHTIME,
                startDate:
                  order?.start && order?.start > 0
                    ? order?.start
                    : (0 as Milliseconds),
                endDate:
                  order?.end && order?.end > 0
                    ? order?.end
                    : (0 as Milliseconds),
              }
        }
      />
    </ListItem>
  );
};
