import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { Field } from "formik";
import React, { useCallback, useState } from "react";

type Props = {
  name: string;
  label: string;
};

export const PasswordInput: React.FC<Props> = ({ name, label }) => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  const handleMouseEvents = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <TextField
          {...field}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          label={label}
          variant="outlined"
          fullWidth
          error={!!meta.error}
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          helperText={meta.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseEvents}
                  onMouseUp={handleMouseEvents}
                  edge="end"
                  sx={{ marginRight: "0.4px" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Field>
  );
};
