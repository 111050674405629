import { Box } from "@mui/material";
import React from "react";

interface IProps {
  title: string;
  message: string;
}

export const Notification: React.FC<IProps> = ({
  title = "",
  message = "",
}) => {
  return (
    <Box>
      <Box component="h3" margin={0}>
        {title}
      </Box>
      <Box component="p" margin={0}>
        {message}
      </Box>
    </Box>
  );
};
