import { CloudUpload } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  CircularProgress,
  IconButton,
  styled,
} from "@mui/material";
import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AvatarCropModal } from "./avatarCropModal";

interface IAvatarUpload {
  name: string;
  image?: string;
  letterAvatar: string;
  loading?: boolean;
  onSubmit: (value: File) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledBadge = styled(Badge)({
  ["& .MuiBadge-badge"]: {
    bottom: "4%",
    right: "4%",
  },
});

export const AvatarUpload: React.FC<IAvatarUpload> = ({
  name,
  image,
  letterAvatar,
  loading = false,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const [isCropModalOpen, toggleCropModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File>();

  const inputRef = createRef<HTMLInputElement>();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files) {
      setSelectedImage(event.target.files[0]);
      toggleCropModal(true);
    }
  };

  const closeModalHandler = () => {
    inputRef.current!.value = "";
    setSelectedImage(undefined);
    toggleCropModal(false);
  };

  return (
    <>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // variant="dot"
        badgeContent={
          loading ? (
            <CircularProgress variant="indeterminate" />
          ) : (
            <IconButton
              aria-label={t("general.buttons.upload")}
              color="primary"
              onClick={() => inputRef.current?.click()}
            >
              <CloudUpload fontSize={"large"} />
            </IconButton>
          )
        }
      >
        <Avatar src={image} sx={{ width: 112, height: 112 }}>
          {letterAvatar}
        </Avatar>
      </StyledBadge>
      <VisuallyHiddenInput
        id={name}
        type="file"
        ref={inputRef}
        accept="image/png,image/jpeg"
        onChange={onChangeHandler}
      />
      <AvatarCropModal
        selectedImage={selectedImage}
        open={isCropModalOpen}
        onClose={closeModalHandler}
        onSubmit={onSubmit}
      />
    </>
  );
};
