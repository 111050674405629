import { Fab, SxProps, Theme } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const FilterContainer: React.FC<
  PropsWithChildren<{ sx?: SxProps<Theme> }>
> = ({ children, sx }) => {
  return (
    <Fab variant="extended" component="div" disableRipple sx={sx}>
      {children}
    </Fab>
  );
};
