import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserSelection } from "Screens/planningV2/components/calendar/components/toolbar/components/userSelection/userSelection";
import { DateSelect } from "./components/dateSelect";
import { Settings } from "./components/settings";

type ToolbarProps = {
  onDateChange: (date: Date) => void;
};

export const Toolbar: React.FC<ToolbarProps> = ({ onDateChange }) => {
  const [t] = useTranslation();

  const { requestFullscreen, isFullscreen, exitFullscreen } =
    useFullscreenContext();

  return (
    <Stack direction="row" justifyContent={"space-between"} sx={{ p: 1 }}>
      <DateSelect onDateChange={onDateChange} />
      <Stack direction="row" sx={{ p: 1 }}>
        <Tooltip
          title={
            isFullscreen
              ? t("general.buttons.fullscreenOff")
              : t("general.buttons.fullscreen")
          }
        >
          <IconButton
            onClick={isFullscreen ? exitFullscreen : requestFullscreen}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Tooltip>
        <UserSelection />
        <Settings />
      </Stack>
    </Stack>
  );
};
