import { type InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { type LanguageType } from "Types";
import { useField, type FieldConfig } from "formik";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import React, { forwardRef, useEffect, useState } from "react";
import { type IInput } from ".";

import { Error } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

interface IProps extends FieldConfig, IInput {
  locale?: LanguageType;
  format?: string;
  onDateChange?: (date: Date | number) => void;
  setFieldValue?: (a: any, b: any) => void;
}

const DateTimeInputComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IProps
> = (props, ref) => {
  const {
    format = "DD.MM.YYYY",
    StartIcon,
    EndIcon,
    disabled,
    mandatory,
    label,
    placeholder,
    locale = "us",
    onDateChange = () => null,
    ...rest
  } = props;
  const [isPickerError, setIsPickerError] = useState(false);
  const [field, meta, helpers] = useField<Date | number | string>(rest.name);
  const isError = Boolean((meta.error && meta.touched) || isPickerError);
  const [momentValue, setMomentValue] = useState(
    field.value ? moment(field.value) : null,
  );

  const datePickerLocale = locale === "us" ? "en" : locale;

  useEffect(() => {
    moment.updateLocale(datePickerLocale, { week: { dow: 1 } });
  }, [datePickerLocale]);

  useEffect(() => {
    if (field.value) {
      setMomentValue(moment(field.value));
    }

    if (field.value === 0 || field.value === "") {
      setMomentValue(null);
    }
  }, [field.value]);

  const inputProps: InputProps = {
    onBlur: () => {
      if (!momentValue?.isValid() && field.value) {
        setMomentValue(moment(field.value));
      }
    },
  };

  const inputLabelProps = {
    shrink: true,
  };

  if (StartIcon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{StartIcon}</InputAdornment>
    );
  }

  if (EndIcon) {
    inputProps.endAdornment = (
      <InputAdornment position="end">{EndIcon}</InputAdornment>
    );
  }

  const changeMuiHandler = (date: any) => {
    setMomentValue(date);

    if (!date) {
      helpers.setValue("");
      onDateChange(0);
    }

    if (date?.isValid()) {
      const timestamp = +moment(date).format("x");
      helpers.setValue(timestamp);
      onDateChange(timestamp);
    }
  };

  const pickerProps = {
    onChange: changeMuiHandler,
    error: isError,
    disabled,
    label,
    placeholder,
    inputProps: { step: 1800 },
    fullWidth: true,
    InputProps: inputProps,
    InputLabelProps: inputLabelProps,
    inputRef: ref,
  };

  const renderError = (text?: string) => (
    <span>
      <Error />
      {text}
    </span>
  );

  const onError = (error) => {
    if (error && !isPickerError) {
      setIsPickerError(true);
      return;
    }

    if (!error && isPickerError) {
      setIsPickerError(false);
    }
  };

  return (
    <div>
      <DatePicker
        value={momentValue}
        onError={onError}
        format={format}
        {...pickerProps}
        slotProps={{
          textField: {
            required: mandatory,
          },
        }}
        sx={{ width: "100%" }}
      />
      {isError && !isPickerError && renderError(meta.error)}
    </div>
  );
};

export const DateInput = forwardRef(DateTimeInputComponent);
