import {
  EXTERNAL_ACCESS_ACTION_TYPES,
  type IGetTokenRequest,
  type IGetTokenSuccess,
  type IGetTokenError,
} from './action-types';

export const getExternalToken = (requestToken: string): IGetTokenRequest => ({
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_REQUEST,
  payload: { requestToken },
});

export const getExternalTokenSuccess = (
  accessToken: string
): IGetTokenSuccess => ({
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_SUCCESS,
  payload: { accessToken },
});

export const getExternalTokenError = (): IGetTokenError => ({
  type: EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_ERROR,
});
