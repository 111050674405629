import { Box, Grid2 } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppState } from "Store";
import {
  ParcelFilters as ParcelFiltersType,
  resetParcelFilters,
  setParcelFilters,
} from "Store/dashboard";
import { DayFilter } from "./DayFilter";
import { PlannedUnplannedSwitch } from "./PlannedUnplannedSwitch";

export const ParcelFilters: React.FC<{
  mapContainer: HTMLElement | undefined;
}> = ({ mapContainer }) => {
  const dispatch = useDispatch();

  const [params] = useSearchParams();

  const noFilters = useMemo(() => params.get("noFilters") !== null, [params]);

  useEffect(() => {
    if (noFilters) {
      dispatch(setParcelFilters(undefined));
    } else if (filters === undefined) {
      dispatch(resetParcelFilters());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noFilters]);

  const filters = useSelector(
    (state: AppState) => state.dashboard.parcelFilters,
  );

  const setDayFilter = useCallback(
    (timeRange: ParcelFiltersType["timeRange"]) =>
      void (
        filters &&
        dispatch(
          setParcelFilters({
            timeRange,
            showUnplanned: filters?.showUnplanned,
          }),
        )
      ),
    [dispatch, filters],
  );

  const setPlannedUnplannedFilter = useCallback(
    (showUnplanned: boolean) =>
      void (
        filters &&
        dispatch(
          setParcelFilters({ timeRange: filters?.timeRange, showUnplanned }),
        )
      ),
    [dispatch, filters],
  );

  if (noFilters || !filters) {
    return <></>;
  }

  return (
    (<Box
      sx={{
        position: "absolute",
        top: 15,
        left: 10,
      }}
    >
      <Grid2 container spacing={1} alignItems="end">
        <Grid2>
          <DayFilter
            mapContainer={mapContainer}
            value={filters.timeRange.start}
            onChange={setDayFilter}
          />
        </Grid2>
        <Grid2>
          <PlannedUnplannedSwitch
            onChange={setPlannedUnplannedFilter}
            showUnplanned={filters.showUnplanned}
          />
        </Grid2>
      </Grid2>
    </Box>)
  );
};
