import { convertAreaToCustom } from "@ero/app-common/util/convertArea";
import { SqMeter, UNIT } from "@ero/app-common/util/Units";
import { Coordinate } from "Types";

export const getPolygonArea = (
  coords: Coordinate[],
  unitOfMeasurement?: UNIT,
) => {
  const polygon = new google.maps.Polygon({ paths: coords });
  const area = google.maps.geometry.spherical?.computeArea(polygon.getPath());

  return unitOfMeasurement
    ? convertAreaToCustom(area as SqMeter, unitOfMeasurement)
    : area;
};
