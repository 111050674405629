import { PickBySpec } from "@ero/app-common/util/Spec";
import { type ReleaseNoteType } from "Types";

export const releaseNotesSpec = [
  ["_id"],
  ["name"],
  ["date"],
  ["text"],
] as const;

export type LocalReleaseNoteType = PickBySpec<
  ReleaseNoteType,
  typeof releaseNotesSpec
>;
