import {
  RELEASENOTES_ACTION_TYPES,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type IResetMetaAction,
  type ISetErrorAction,
  type IGetInitialReleaseNotesRequestAction,
  type IGetInitialReleaseNotesSuccessAction,
  type IGetInitialReleaseNotesErrorAction,
  type IGetReleaseNotesRequestAction,
  type IGetReleaseNotesSuccessAction,
  type IDeleteReleaseNotesAction,
  type ICreateReleaseNoteAction,
  type IUpdateReleaseNotesAction,
} from './action-types';
import {
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
  type CREATE_RELEASENOTE_DTO,
} from 'Types';
import { type LocalReleaseNoteType } from './specs';

export const setLoading = (): ISetLoadingAction => ({
  type: RELEASENOTES_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: RELEASENOTES_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: RELEASENOTES_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: RELEASENOTES_ACTION_TYPES.RESET_META,
});

export const getReleaseNotesInitial = (params: GetTableDataParams): IGetInitialReleaseNotesRequestAction => {
  return ({
    type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_REQUEST,
    payload: {
      params,
    }
  })
};

export const getReleaseNotesInitialSuccess = (
  releaseNotes: LocalReleaseNoteType[],
  maxCount: number
): IGetInitialReleaseNotesSuccessAction => ({
  type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_SUCCESS,
  payload: {
    releaseNotes,
    maxCount,
  }
});

export const getReleaseNotesInitialError = (): IGetInitialReleaseNotesErrorAction => ({
  type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_ERROR,
});

export const getReleaseNotes = (
  { params, search }: GetTableEntitiesType
): IGetReleaseNotesRequestAction => ({
  type: RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_REQUEST,
  payload: { params, search },
});

export const getReleaseNotesSuccess = (
  { list, maxCount, listMeta, search }: SetTableEntityType<LocalReleaseNoteType>
): IGetReleaseNotesSuccessAction => ({
  type: RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_SUCCESS,
  payload: { releaseNotes: list, maxCount, listMeta, search },
});

export const deleteReleaseNotes = (ids: number[]): IDeleteReleaseNotesAction => ({
  type: RELEASENOTES_ACTION_TYPES.DELETE_RELEASENOTES,
  payload: { ids },
});

export const createReleaseNote = (data: CREATE_RELEASENOTE_DTO): ICreateReleaseNoteAction => ({
  type: RELEASENOTES_ACTION_TYPES.CREATE_RELEASENOTE,
  payload: { data },
});


export const updateReleaseNote = (updateData: Record<string, string>, id: number): IUpdateReleaseNotesAction => ({
  type: RELEASENOTES_ACTION_TYPES.UPDATE_RELEASENOTE,
  payload: { updateData, id },
});
