import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from "@mui/material";
import { Input } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { Form, Formik } from "formik";
import { useAllServiceOptions } from "Hooks/serviceOptions";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { errorToast } from "Services";
import { updateJob } from "Store/orderDetails";
import * as Yup from "yup";
import { FIELDS } from "../../helper";

interface IUpdateDialog {
  open: boolean;
  onClose: () => void;
  fieldToEdit: FIELDS | undefined;
  job: JobResponseBody | undefined;
}

type FormikValues = {
  service: number | undefined;
  notes: string | undefined;
};
export const UpdateDialog: React.FC<IUpdateDialog> = ({
  open,
  onClose,
  fieldToEdit,
  job,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fetchServiceOptions } = useAllServiceOptions();

  const initialValues: FormikValues = useMemo(() => {
    return { service: job?.originalService, notes: job?.notes ?? "" };
  }, [job?.notes, job?.originalService]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        service: Yup.number().optional(),
        notes: Yup.string().optional(),
      }),
    [],
  );

  const handleFormSubmit = useCallback(
    (values: FormikValues) => {
      try {
        if (job?._id) {
          dispatch(
            updateJob({
              _id: job._id,
              service:
                fieldToEdit === FIELDS.SERVICE ? values.service : undefined,
              notes: fieldToEdit === FIELDS.NOTES ? values.notes : undefined,
            }),
          );
        }
        onClose();
      } catch (error) {
        errorToast(undefined, undefined, error);
      }
    },
    [dispatch, fieldToEdit, job?._id, onClose],
  );

  const formContent = useMemo(
    () => (
      <>
        {fieldToEdit === FIELDS.SERVICE && (
          <Grid2 size={12}>
            <SelectWithAutoComplete
              name="service"
              label={t("general.service_one")}
              fetchOptions={fetchServiceOptions}
            />
          </Grid2>
        )}
        {fieldToEdit === FIELDS.NOTES && (
          <Grid2 size={12}>
            <Input name="notes" label="Notizen" multiline rows={6} />
          </Grid2>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchServiceOptions, fieldToEdit],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t("general.buttons.edit")}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => (
          <Form>
            <DialogContent dividers>
              <Grid2 alignItems="center" container spacing={3}>
                {formContent}
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={!isValid || !dirty}>
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
