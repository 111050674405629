import { FaqsResponseBody } from "@ero/app-common/v2/routes/models/faq";
import {
  HELP_ACTION_TYPES,
  type IGetFaqsError,
  type IGetFaqsRequest,
  type IGetFaqsSuccess,
} from "./action-types";

export const getFaqs = (): IGetFaqsRequest => ({
  type: HELP_ACTION_TYPES.GET_FAQS_REQUEST,
});

export const getFaqsSuccess = (
  faqs: FaqsResponseBody["data"],
): IGetFaqsSuccess => ({
  type: HELP_ACTION_TYPES.GET_FAQS_SUCCESS,
  payload: { faqs },
});

export const getFaqsError = (): IGetFaqsError => ({
  type: HELP_ACTION_TYPES.GET_FAQS_ERROR,
});
