import { TelemetryType } from "Enums";
import React, { useCallback, useState } from "react";
import {
  TelemetryEntry as TelemtryEntryType,
  useTelemetryEntries,
} from "../../telemetryConfig";
import { EditTelemetryModal } from "../editTelemetryModal/editTelemetryModal";
import { TelemetryEntry } from "./telemetryEntry";

interface ITelemetryList {
  jobId: number;
}

export const TelemetryList: React.FC<ITelemetryList> = ({ jobId }) => {
  const [showEditTelemetry, setShowEditTelemetry] = useState(false);
  const [selectedTelemetryType, setSelectedTelemetryType] =
    useState<TelemetryType>();

  const telemetryEntries = useTelemetryEntries();

  const openEditTelemetryModal = useCallback((config: TelemtryEntryType) => {
    setSelectedTelemetryType(config.telemetryType);
    setShowEditTelemetry(true);
  }, []);

  const closeEditTelemetryModal = useCallback(
    () => setShowEditTelemetry(false),
    [],
  );

  return (
    <>
      <EditTelemetryModal
        open={showEditTelemetry}
        onClose={closeEditTelemetryModal}
        jobId={jobId}
        telemetryType={selectedTelemetryType}
      />
      {telemetryEntries.map((item) => (
        <TelemetryEntry
          key={item.name}
          item={item}
          openEditTelemetryModal={openEditTelemetryModal}
        />
      ))}
    </>
  );
};
