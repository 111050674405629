import { FilterList } from "@mui/icons-material";
import { Box, IconButton, Popover } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useCallback, useState } from "react";
import { FilterForm } from "./components/form";

const Filter: React.FC = () => {
  const { fullscreenContainer } = useFullscreenContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleClick} color="error">
        <FilterList />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        container={fullscreenContainer}
      >
        <Box width={300}>
          <FilterForm handleClose={handleClose} />
        </Box>
      </Popover>
    </>
  );
};

Filter.displayName = "PlanningSidebarFilter";

export { Filter };
