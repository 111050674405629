import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/company";
import { apiInstance, apiInstanceV2 } from "App/axios";

export const getAllDropdowns = (key: string) =>
  apiInstance.post("company/allDropdowns", { key });

export const addDropdown = (payload) =>
  apiInstance.post("/company/addDropdown", payload);

export const removeDropdown = (payload) =>
  apiInstance.post("/company/removeDropdown", payload);

export const updateDropdown = (payload) =>
  apiInstance.post("/company/updateDropdown", payload);

export const getDropDownOptionsV2 = async (
  key: string,
  accessToken?: string,
) => {
  const config = {};
  if (accessToken) {
    config["headers"] = { Authorization: `Bearer ${accessToken}` };
  }

  return await apiInstanceV2.get(
    baseUrl + SubUrls.DROPDOWN.replace(":id", "self") + "?key=" + key,
    config,
  );
};
