import { Box, Grid2 } from "@mui/material";
import { Loader } from "Components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { NoOrdersPlaceholder } from "./components/noOrdersPlaceholder/noOrderPlaceholder";
import { OrderItem } from "./components/orderItem";

export const OrderList: React.FC = () => {
  const dispatch = useDispatch();

  const { orders, loadingOrders } = useSelector(
    (state: AppState) => state.planningV2,
  );

  useEffect(() => {
    dispatch(sagaActions.fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingOrders && (
        <Box
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </Box>
      )}
      {!loadingOrders && (
        <Grid2
          container
          alignItems={"center"}
          alignContent={"flex-start"}
          sx={{ height: "100%", mx: 3, pt: 3 }}
        >
          {orders.length > 0 &&
            orders.map((order) => <OrderItem key={order._id} order={order} />)}
          {orders.length === 0 && <NoOrdersPlaceholder />}
        </Grid2>
      )}
    </>
  );
};
