import { AccessTime } from "@mui/icons-material";
import {
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const dateTimeFormat = new Intl.DateTimeFormat([], {
  dateStyle: "medium",
  timeStyle: "short",
});

interface ITimeInfo {
  jobStart: number | undefined;
  jobEnd: number | undefined;
}

export const TimeInfo: React.FC<ITimeInfo> = ({ jobStart, jobEnd }) => {
  const [t] = useTranslation();

  const jobStartFormatted = useMemo(
    () =>
      jobStart && jobStart !== -1
        ? dateTimeFormat.format(new Date(jobStart))
        : "-",
    [jobStart],
  );

  const jobEndFormatted = useMemo(
    () =>
      jobEnd && jobEnd !== -1 ? dateTimeFormat.format(new Date(jobEnd)) : "-",
    [jobEnd],
  );

  return (
    (<Grid2 container>
      <Grid2 size={12}>
        <List>
          <ListItem disablePadding>
            <ListItemIcon>
              <AccessTime />
            </ListItemIcon>
            <ListItemText
              primary={jobStartFormatted}
              secondary={t("general.fields.start")}
            />
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemIcon>
              <AccessTime />
            </ListItemIcon>
            <ListItemText
              primary={jobEndFormatted}
              secondary={t("general.fields.end")}
            />
          </ListItem>
        </List>
      </Grid2>
    </Grid2>)
  );
};
