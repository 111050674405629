import { type EventContentArg } from "@fullcalendar/core";
import React from "react";
import { type CalendarProps } from "../types";

export const useRenderEvent = (props: CalendarProps) => {
  const { renderEventContent = () => <div /> } = props;

  const renderEventContentLocal = (data: EventContentArg) => {
    const { originalItem } = data.event.extendedProps;

    if (!originalItem) {
      return <div />;
    }

    return (
      <div className="event-content">
        {renderEventContent(originalItem, data.event)}
      </div>
    );
  };

  return renderEventContentLocal;
};
