import { JOBSTATUS } from "@ero/app-common/enums";
import { Marker, Polyline } from "@react-google-maps/api";
import { MapParcel } from "Components/map";
import { FormikValues } from "Components/parcelInformationModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { markerIcons } from "Utils";
import { DRAWINGMODE, lineSymbol } from "./utils";

interface IGeoElements {
  drawingMode: DRAWINGMODE | undefined;
  handleMarkerDrag: (e: google.maps.MapMouseEvent) => void;
  handlePolyVertexDrag: (e: google.maps.MapMouseEvent, index: number) => void;
  handleMapClick: (e: google.maps.MapMouseEvent) => void;
  handlePolyVertexDragEnd: (
    e: google.maps.MapMouseEvent,
    index: number,
  ) => void;
}

export const GeoElements: React.FC<IGeoElements> = ({
  drawingMode,
  handleMarkerDrag,
  handlePolyVertexDrag,
  handleMapClick,
  handlePolyVertexDragEnd,
}) => {
  const { values } = useFormikContext<FormikValues>();

  const currentMarkerPosition = useMemo(
    () => values.position,
    [values.position],
  );

  return (
    <>
      {drawingMode === DRAWINGMODE.POLYGON && (
        <>
          {values.isClosedPolygon && (
            <MapParcel
              showPolygon
              // For faster rerendering of the parcelFigure and proper naming
              parcel={{
                ...values,
                shape: values.shape,
                name: values.name || " ",
              }}
            />
          )}
          <Polyline
            visible={!values.isClosedPolygon}
            path={values.shape || []}
            options={{
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            }}
          />
          {values.shape &&
            values.shape.map((coord, index) => {
              return (
                <Marker
                  draggable
                  icon={{
                    url: markerIcons[JOBSTATUS.OPEN],
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  onDrag={(e) => handlePolyVertexDrag(e, index)}
                  onDragEnd={(e) => handlePolyVertexDragEnd(e, index)}
                  key={index}
                  position={coord}
                  {...(index === 0
                    ? // Handle click on first marker to complete polygon
                      {
                        onClick: (e) => {
                          handleMapClick(e);
                        },
                      }
                    : // others are not clickable
                      { clickable: false })}
                />
              );
            })}
        </>
      )}
      {drawingMode === DRAWINGMODE.MARKER && currentMarkerPosition && (
        <Marker
          draggable
          position={currentMarkerPosition}
          onDrag={handleMarkerDrag}
          icon={{
            url: markerIcons[JOBSTATUS.OPEN],
            scaledSize: new google.maps.Size(36, 36),
          }}
        />
      )}
    </>
  );
};
