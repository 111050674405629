import { type Milliseconds } from "@ero/app-common/util/Milliseconds";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { type EventApi } from "@fullcalendar/core";
import {
  BookmarkBorderOutlined,
  LockOpenOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { type MarkedEventType } from "../../helpers";

interface IJobIcons {
  job: JobResponseBody;
  markedEvent: MarkedEventType;
  eventsInMarkedRange: (string | undefined)[];
  calendarEvent: EventApi;
  calendarViewType: string;
  importantIconOnClick: (
    id: number,
    start: Milliseconds,
    end: Milliseconds,
    selectedResource: string,
  ) => void;
  updateJobLockHandler: (id: number, locked: boolean) => void;
}

export const JobIcons: React.FC<IJobIcons> = ({
  job,
  markedEvent,
  eventsInMarkedRange,
  calendarEvent,
  importantIconOnClick,
  updateJobLockHandler,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Fix needed, when we add functionality for multiple employees
  const selectedResource = useMemo(
    () =>
      calendarEvent
        .getResources()
        .map((item) => item?.id)
        .pop() || "",
    [calendarEvent],
  );

  return (
    <Stack spacing={1} ref={wrapperRef}>
      <Tooltip title={"Gemeinsam mit folgenden Arbeitsschritten verschieben"}>
        <BookmarkBorderOutlined
          onClick={() =>
            !job.locked &&
            importantIconOnClick(
              job._id,
              job.start as Milliseconds,
              job.end as Milliseconds,
              selectedResource,
            )
          }
          sx={{
            color:
              job._id === markedEvent.id ||
              eventsInMarkedRange.includes(`${job._id}`)
                ? "yellow"
                : calendarEvent.textColor,
          }}
        />
      </Tooltip>
      {job.locked ? (
        <Tooltip title={"Verschieben erlauben"}>
          <LockOutlined
            onClick={() => updateJobLockHandler(job._id, !job.locked)}
          />
        </Tooltip>
      ) : (
        <Tooltip title={"Verschieben sperren"}>
          <LockOpenOutlined
            onClick={() => updateJobLockHandler(job._id, !job.locked)}
          />
        </Tooltip>
      )}
    </Stack>
  );
};
