export enum DRAWINGMODE {
  MARKER = 0,
  POLYGON = 1,
}

export const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 4,
  strokeColor: "#FF0000",
};
