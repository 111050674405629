import { Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import { EllipsisText } from "Components";
import { AppState } from "Store";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EditNoteModal } from "./editNoteModal";

export const NotesCard: React.FC = () => {
  const [t] = useTranslation();

  const { order } = useSelector((state: AppState) => state.orderDetails);

  const [isEditNoteModalOpen, setIsEditNoteModalOpen] =
    useState<boolean>(false);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid2 container>
          <Grid2 size={11}>
            <Typography variant="h6">{t("general.fields.notes")}</Typography>
            <EllipsisText
              text={order?.notes || "-"}
              variant="body1"
              color="text.secondary"
            ></EllipsisText>
          </Grid2>
          <Grid2 size={1}>
            <IconButton onClick={() => setIsEditNoteModalOpen(true)}>
              <Edit fontSize="small" />
            </IconButton>
          </Grid2>
        </Grid2>
        <EditNoteModal
          open={isEditNoteModalOpen}
          onClose={() => setIsEditNoteModalOpen(false)}
        />
      </CardContent>
    </Card>
  );
};
