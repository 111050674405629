import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import { ORGANIZATION_TYPE } from "Screens/organization/config";
import { AppState } from "Store";
import React from "react";
import { useSelector } from "react-redux";
import { ListItems } from "../listItems/listItems";

interface IAccordionWrapper {
  expanded: string | false;
  category: {
    title: string;
    id: string;
    route: ORGANIZATION_TYPE;
  };
  onChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

export const AccordionWrapper: React.FC<IAccordionWrapper> = ({
  expanded,
  category,
  onChange,
}) => {
  const dropdowns = useSelector((state: AppState) => state.organization);
  const loading = useSelector((state: AppState) => state.organization.loading);

  return (
    <Accordion
      expanded={expanded === category.id}
      onChange={onChange(category.id)}
      elevation={3}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {category.title}
      </AccordionSummary>
      <AccordionDetails>
        {loading && <CircularProgress />}
        {!loading && (
          <ListItems type={category.route} data={dropdowns[category.route]} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
