import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { Marker, type MarkerProps } from "@react-google-maps/api";
import MapMarkerOnlineSvg from "Assets/icons/map-marker-anim.svg";
import MapMarkerOfflineSvg from "Assets/icons/map-point-grey.svg";
import React, { forwardRef, useCallback, useMemo } from "react";

interface IMachineV2 extends MarkerProps {
  machine: MachineResponseBody;
  itemOnClick?: (id: number) => void;
}

const MachineV2 = forwardRef<Marker, IMachineV2>((props, ref) => {
  const { machine, itemOnClick, onClick: propsOnClick } = props;

  const icon: google.maps.Icon = useMemo(() => {
    return machine.active
      ? {
          url: MapMarkerOnlineSvg,
          scaledSize: new google.maps.Size(100, 100),
          anchor: new google.maps.Point(50, 50),
        }
      : {
          url: MapMarkerOfflineSvg,
          scaledSize: new google.maps.Size(24, 24),
          anchor: new google.maps.Point(12, 12),
        };
  }, [machine.active]);

  const onClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (itemOnClick) itemOnClick(machine._id);
      if (propsOnClick) propsOnClick(e);
    },
    [itemOnClick, propsOnClick, machine._id],
  );

  return (
    <Marker
      {...props}
      ref={ref}
      position={machine.position}
      icon={icon}
      onClick={onClick}
    ></Marker>
  );
});

MachineV2.displayName = "MachineV2";

export { MachineV2 };
