import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/faq";
import {
  FaqsRequestQuery,
  FaqsResponseBody,
} from "@ero/app-common/v2/routes/models/faq";
import { apiInstanceV2 } from "App/axios";

export const getFaqs = async (params: FaqsRequestQuery) =>
  (
    await apiInstanceV2.get<FaqsResponseBody>(baseUrl + SubUrls.ROOT, {
      params,
    })
  ).data;
