import * as Yup from "yup";

import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";

export const getValidationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED),
    lastName: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED),
    description: Yup.string().max(
      MAX_LENGTH.LONG_STRING,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    phone: Yup.string().matches(
      VALIDATION_REGEX.phone,
      VALIDATION_ERRORS.PHONE,
    ),
    mobile: Yup.string().matches(
      VALIDATION_REGEX.phone,
      VALIDATION_ERRORS.PHONE,
    ),
    email: Yup.string()
      .matches(VALIDATION_REGEX.email, VALIDATION_ERRORS.VALID_EMAIL)
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
  });
