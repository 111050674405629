import { constructEntityFromSocket } from 'Utils';
import { type HasId, type WsMessage } from 'Types';

export const mapEntityAfterSocketUpdate = <T extends HasId>(
  item: T,
  message: WsMessage
) => {
  const isUpdatedItem = item._id === message.meta;

  if (!isUpdatedItem) {
    return item;
  }

  return constructEntityFromSocket(item, message.payload);
};
