import { PlotOutside } from "@ero/app-common/models";
import { SqMeter } from "@ero/app-common/util/Units";
import {
  UnitSymbol,
  convertAreaToCustom,
  convertAreaToDefault,
} from "@ero/app-common/util/convertArea";
import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Input, Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import { createPlot, resetMeta, updatePlot } from "Store/plots";
import { LanguageType } from "Types";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getValidationSchema } from "./formConfig";

interface PlotModalProps {
  plot?: PlotOutside;
  isOpen: boolean;
  onClose: () => void;
  submitHandler?: (value: PlotOutside) => void;
}

export const PlotModal: React.FC<PlotModalProps> = ({
  plot,
  onClose,
  isOpen,
  submitHandler,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { success, loading } = useSelector((store: AppState) => store.plots);
  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );
  const selectedLang = useSelector(
    (state: AppState) => state.auth.selectedLang,
  ) as LanguageType;

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success, onClose]);

  const onSubmit = (values: PlotOutside) => {
    const data: PlotOutside = { ...values };
    if (data.size !== undefined) {
      data.size = convertAreaToDefault(
        data.size,
        unitOfMeasurement,
        selectedLang,
      );
    }
    if (submitHandler) {
      submitHandler(data);
      onClose();
    } else {
      if (plot) {
        dispatch(updatePlot(data, plot._id));
      } else {
        dispatch(createPlot(data));
      }
    }
  };

  const initialValues = useMemo(() => {
    const initialValues = {
      flurstuecksnummer: "",
      ...plot,
    } as PlotOutside;
    if (plot && plot.size) {
      initialValues.size = convertAreaToCustom(
        plot.size as SqMeter,
        unitOfMeasurement,
      );
    }
    return initialValues;
  }, [plot, unitOfMeasurement]);

  const title = useMemo(() => {
    if (!plot) {
      return t("plots.modalTitle.create");
    } else {
      return t("plots.modalTitle.edit");
    }
  }, [plot, t]);

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="body">
      <DialogTitle>{title}</DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        validationSchema={getValidationSchema()}
        isInitialValid={!!plot}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <DialogContent dividers>
              <Form>
                <Input
                  name="flurstuecksnummer"
                  label={t("plots.fields.flurstuecksnummer")}
                  sx={{ mb: 1, mt: 1 }}
                />
                <Input
                  name={"size"}
                  type="number"
                  label={t("plots.fields.size", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                  sx={{ mb: 1, mt: 1 }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={props.submitForm}
                disabled={!props.dirty || !props.isValid || loading}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
