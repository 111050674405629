import {
  genericAllBody_shape,
  searchSpec_shape,
} from "@ero/app-common/routes/validators";
import z from "zod";

type SearchSpec = z.infer<typeof searchSpec_shape>;
type Filters = z.infer<typeof genericAllBody_shape>["filters"];

export const searchSpecShorthand = (
  searchPayload: string | undefined,
  properties: SearchSpec["properties"] | undefined,
): SearchSpec | undefined => {
  if (searchPayload === undefined || searchPayload === "") {
    return;
  }
  if (properties === undefined) {
    return;
  }

  return {
    searchPayload,
    properties,
    timezoneOffset: new Date().getTimezoneOffset(),
  };
};

export const filterSpecShorthand = (
  id: number | boolean | undefined,
  access: string[],
): Filters => {
  if (id === undefined) {
    return;
  }
  if (access.length === 0) {
    return;
  }

  return [{ access: access as [string, ...string[]], allowed: [id] }];
};

export const filterSpecShorthandMultiple = (
  options: (string | number)[],
  access: [string, ...string[]],
) => {
  const allowed = options;
  return { access, allowed };
};
