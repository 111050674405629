import { JOBSTATUS } from "@ero/app-common/enums";
import { Coordinate } from "@ero/app-common/util/Coordinate";
import { PolygonF, type PolygonProps } from "@react-google-maps/api";
import { markerIcons } from "Utils";
import React, { useCallback, useMemo } from "react";
import { useParcelCenter } from "../hooks/centerOfMass";
import { ParcelMarkerProps, ParcelMarkerV2 } from "./ParcelMarker";

export type ParcelData = {
  _id?: number;
  name?: string;
  shape?: Coordinate[] | null;
  position?: Coordinate | null;
  status?: JOBSTATUS | -1;
};

type ParcelDataWithShape = ParcelData & {
  shape: Coordinate[];
};

type ParcelDataWithPosition = ParcelData & {
  position: Coordinate;
};

export const isParcelWithShape = (
  parcel: ParcelData,
): parcel is ParcelDataWithShape =>
  (parcel.shape?.length ?? 0) > 0 && !!parcel.shape;

export const isParcelWithPosition = (
  parcel: ParcelData,
): parcel is ParcelDataWithPosition => !!parcel.position;

interface MapParcelProps extends PolygonProps {
  showPolygon: boolean;
  showLabel?: boolean;
  parcel: ParcelData;
  itemOnClick?: (id: number) => void;
  markerProps?: Omit<
    ParcelMarkerProps,
    "label" | "parcel" | "showIcon" | "itemOnClick"
  >;
  fillColor?: string;
}

export const MapParcel: React.FC<MapParcelProps> = (props) => {
  const {
    showPolygon,
    showLabel = true,
    parcel,
    itemOnClick,
    onClick: propsOnClick,
    markerProps,
    fillColor,
  } = props;

  const center = useParcelCenter(parcel);

  const options = useMemo(() => {
    const baseOptions = {
      fillColor: "#fff",
      fillOpacity: 0.5,
      strokeColor: "#fff",
      strokeWeight: 1,
      clickable: true,
    };

    if (fillColor) {
      baseOptions.fillColor = fillColor;
      baseOptions.strokeColor = fillColor;
    }

    return baseOptions;
  }, [fillColor]);

  const onClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (itemOnClick && parcel._id) itemOnClick(parcel._id);
      if (propsOnClick) propsOnClick(e);
    },
    [propsOnClick, itemOnClick, parcel._id],
  );

  const markerParcel = useMemo(
    () => ({
      ...parcel,
      position: center,
      name: parcel.name !== "" ? parcel.name : " ",
    }),
    [parcel, center],
  );

  return (
    <>
      {isParcelWithShape(parcel) && showPolygon && (
        <PolygonF
          {...props}
          options={options}
          path={parcel.shape}
          onClick={onClick}
        />
      )}
      {isParcelWithPosition(markerParcel) && (
        <ParcelMarkerV2
          parcel={markerParcel}
          showIcon={!showPolygon || !!parcel.position}
          showLabel={showLabel}
          clusterer={showPolygon ? undefined : markerProps?.clusterer}
          onClick={onClick}
          customIcon={markerIcons[parcel.status ?? -1]}
          {...markerProps}
        />
      )}
    </>
  );
};
