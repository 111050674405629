import { ServiceOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { type Action } from "redux";
import {
  RequestBody,
  type ColumnFilterDataType,
  type CREATE_SERVICE_DTO,
  type GetTableDataParams,
} from "Types";

export enum SERVICES_ACTION_TYPES {
  CHANGE_COLUMNS_ORDER = "services/change-columns-order",
  CREATE_SERVICE = "services/create-services",
  DELETE_SERVICES = "services/delete-services",
  GET_INITIAL_SERVICES_ERROR = "services/get-services-error",
  GET_INITIAL_SERVICES_REQUEST = "services/get-services-initial-request",
  GET_INITIAL_SERVICES_SUCCESS = "services/get-services-initial-success",
  GET_SERVICES_REQUEST = "services/get-services-request",
  GET_SERVICES_SUCCESS = "services/get-services-success",
  RESET_META = "services/reset-meta",
  RESET_SEARCH = "services/reset-search",
  SET_ERROR = "services/set-error",
  SET_LOADING = "services/set-loading",
  SET_SUCCESS = "services/set-success",
  SET_TABLE_HIDDEN_COLUMNS = "services/set-table-hidden-columns",
  UPDATE_SERVICE = "services/update-services",
}

export interface ISetLoadingAction extends Action {
  type: SERVICES_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: SERVICES_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: SERVICES_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: SERVICES_ACTION_TYPES.RESET_META;
}
export interface IResetSearchAction extends Action {
  type: SERVICES_ACTION_TYPES.RESET_SEARCH;
}

export interface IGetInitialServicesRequestAction extends Action {
  type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_REQUEST;
  payload: {
    params: GetTableDataParams;
  };
}

export interface IGetInitialServicesSuccessAction extends Action {
  type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_SUCCESS;
  payload: {
    services: ServiceOutside[];
    maxCount: number;
  };
}

export interface IGetInitialServicesErrorAction extends Action {
  type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_ERROR;
}

export interface IGetServicesRequestAction extends Action {
  type: SERVICES_ACTION_TYPES.GET_SERVICES_REQUEST;
  payload: {
    params: GetTableDataParams;
    withSuccess?: boolean;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
  };
}

export interface IGetServicesSuccessAction extends Action {
  type: SERVICES_ACTION_TYPES.GET_SERVICES_SUCCESS;
  payload: {
    services: ServiceOutside[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
  };
}

export interface ICreateServiceAction extends Action {
  type: SERVICES_ACTION_TYPES.CREATE_SERVICE;
  payload: { data: CREATE_SERVICE_DTO };
}

export interface IUpdateServiceAction extends Action {
  type: SERVICES_ACTION_TYPES.UPDATE_SERVICE;
  payload: {
    updateData: RequestBody<"/services/update">;
    id: number;
  };
}

export interface IDeleteServicesAction extends Action {
  type: SERVICES_ACTION_TYPES.DELETE_SERVICES;
  payload: { ids: number[] };
}

export interface ISetTableHiddenColumns extends Action {
  type: SERVICES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { tableId: string; hiddenColumns: GridColumnVisibilityModel };
}

export interface IChangeColumnsOrder extends Action {
  type: SERVICES_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type ServicesAction =
  | IChangeColumnsOrder
  | ICreateServiceAction
  | IDeleteServicesAction
  | IGetInitialServicesErrorAction
  | IGetInitialServicesRequestAction
  | IGetInitialServicesSuccessAction
  | IGetServicesRequestAction
  | IGetServicesSuccessAction
  | IResetMetaAction
  | IResetSearchAction
  | ISetErrorAction
  | ISetLoadingAction
  | ISetSuccessAction
  | ISetTableHiddenColumns
  | IUpdateServiceAction;
