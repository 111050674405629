import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { IconButton } from "@mui/material";
import React from "react";

export const FullscreenButton: React.FC<{
  toggleFullscreen: (() => void) | undefined;
  isFullscreen: boolean;
}> = ({ toggleFullscreen, isFullscreen }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={toggleFullscreen}
      sx={{
        position: "absolute",
        right: 48,
        top: 8,
      }}
    >
      {!isFullscreen && <FullscreenIcon />}
      {isFullscreen && <FullscreenExitIcon />}
    </IconButton>
  );
};
