import { Button, Grid2 } from "@mui/material";
import { StaticMultiSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticMultiSelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { FilterType, planningV2Slice } from "Store/planningV2/planningV2slice";
import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFilterOptions } from "../hooks/filterOptions";
import { initialValues, validationSchema, ValuesType } from "./formConfig";

type IFilterForm = {
  handleClose: () => void;
};

export const FilterForm: React.FC<IFilterForm> = ({ handleClose }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<ValuesType>>(null);

  const { loadingOrders, filters, filtersOptions, orders } = useSelector(
    (state: AppState) => state.planningV2,
  );
  const options = useFilterOptions(orders);

  useEffect(() => {
    if (orders.length > 0)
      dispatch(planningV2Slice.actions.setFiltersOptions(options));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, options]);

  useEffect(() => {
    formRef.current?.setValues({
      customer: filters.customer,
      service: filters.service,
      grapeVariety: filters.grapeVariety,
      grapeVarietyColor: filters.grapeVarietyColor,
      regionette: filters.regionette,
      mark: filters.mark,
    });
  }, [
    filters.grapeVariety,
    filters.grapeVarietyColor,
    filters.customer,
    filters.regionette,
    filters.mark,
    filters.service,
  ]);

  const onSubmit = useCallback(
    (values: ValuesType) => {
      const { grapeVarietyColor, ...filterValues } = values;
      const filters: FilterType = {
        ...filterValues,
        grapeVarietyColor:
          grapeVarietyColor && grapeVarietyColor.length > 0
            ? grapeVarietyColor
            : undefined,
      };

      dispatch(sagaActions.setFilters(filters));
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose],
  );

  const onReset = useCallback(() => {
    if (filters) {
      dispatch(
        planningV2Slice.actions.setFilters({
          customer: [],
          grapeVariety: [],
          grapeVarietyColor: undefined,
          mark: [],
          regionette: [],
          service: [],
        }),
      );
      dispatch(sagaActions.fetchOrders());
    }
    handleClose();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, handleClose]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid2
            container
            justifyContent={"center"}
            spacing={1.5}
            paddingX={1}
            paddingY={1.5}
          >
            <>
              <Grid2 size={12}>
                <StaticMultiSelectWithAutoComplete
                  name="customer"
                  options={filtersOptions.customers}
                  label={t("general.fields.customer")}
                  size="small"
                />
              </Grid2>
              <Grid2 size={12}>
                <StaticMultiSelectWithAutoComplete
                  name="service"
                  options={filtersOptions.services}
                  label={t("general.labels.service")}
                  valueSource="label"
                  size="small"
                />
              </Grid2>
              <Grid2 size={12}>
                <StaticMultiSelectWithAutoComplete
                  name="grapeVariety"
                  options={filtersOptions.grapeVarieties}
                  label={t("parcels.fields.grapeVariety")}
                  size="small"
                />
              </Grid2>
              <Grid2 size={12}>
                <StaticSelectWithAutoComplete
                  name="grapeVarietyColor"
                  options={filtersOptions.grapeVarietyColors}
                  label={t("general.labels.grapeColor")}
                  size="small"
                />
              </Grid2>
              <Grid2 size={12}>
                <StaticMultiSelectWithAutoComplete
                  name="regionette"
                  options={filtersOptions.regionettes}
                  label={t("parcels.fields.regionette")}
                  size="small"
                />
              </Grid2>
              <Grid2 size={12}>
                <StaticMultiSelectWithAutoComplete
                  name="mark"
                  options={filtersOptions.gemarkungen}
                  label={t("parcels.fields.mark")}
                  size="small"
                />
              </Grid2>
            </>
            <Grid2>
              <Button
                type="reset"
                variant="text"
                size="small"
                disabled={
                  (loadingOrders || !dirty) && Object.keys(filters).length === 0
                }
              >
                {t("general.buttons.reset")}
              </Button>
            </Grid2>
            <Grid2>
              <Button
                type="submit"
                variant="contained"
                size="small"
                disabled={loadingOrders || !isValid}
              >
                {t("general.buttons.apply")}
              </Button>
            </Grid2>
          </Grid2>
        </Form>
      )}
    </Formik>
  );
};

FilterForm.displayName = "FilterForm";
