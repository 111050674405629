import { CustomerOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type GetTableDataParams, type InitialScreenMetaType } from "Types";
import { CUSTOMERS_ACTION_TYPES, type CustomersAction } from "./action-types";
import { type LocalCustomerType } from "./specs";

type CustomersStateType = {
  list: LocalCustomerType[];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  listUpdateLoading: boolean;
  hiddenColumns: Record<string, boolean>;
  isHiddenColumnsSet: boolean;
  searchData?: SearchSpec;
  columnsOrder?: number[];
  newCustomer?: CustomerOutside;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: CustomersStateType = {
  list: [] as LocalCustomerType[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  searchData: undefined,
  columnsOrder: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  hiddenColumns: {},
  isHiddenColumnsSet: false,
  listUpdateLoading: false,
  newCustomer: undefined,
  success: false,
  loading: false,
  error: false,
};

export const customersReducer = (
  state = initialState,
  action: CustomersAction,
): CustomersStateType => {
  switch (action.type) {
    case CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        list: action.payload.customers,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_REQUEST:
    case CUSTOMERS_ACTION_TYPES.DELETE_CUSTOMERS:
      return {
        ...state,
        listUpdateLoading: true,
      };
    case CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        list: action.payload.customers,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        listUpdateLoading: false,
      };
    case CUSTOMERS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case CUSTOMERS_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case CUSTOMERS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case CUSTOMERS_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
        newCustomer: undefined,
      };

    case CUSTOMERS_ACTION_TYPES.RESET_SEARCH:
      return {
        ...state,
        searchData: undefined,
      };
    case CUSTOMERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
        isHiddenColumnsSet: true,
      };
    case CUSTOMERS_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    case CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        newCustomer: action.payload.data,
      };
    default:
      return state;
  }
};
