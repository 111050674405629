import { MACHINEALIGNMENT } from "@ero/app-common/enums";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { MAX_LENGTH, VALIDATION_ERRORS } from "Constants";
import * as Yup from "yup";

export type ValuesType = {
  _id?: number;
  internalId?: number;

  name?: string;
  kind?: number | string;
  manufacturer?: number | string;
  model?: string;
  serialNumber?: string;
  licensePlate?: string;
  features?: string;
  nextMaintenance?: Milliseconds | string;
  tuv?: Milliseconds | string;
  maintenanceInformation?: string;
  alignment?: MACHINEALIGNMENT[];
  loadingCapacity?: number | string;
  length?: number | string;
  height?: number | string;
  width?: number | string;
  workingWidth?: number | string;
  performance?: number | string;
  intact?: number | string;
  notes?: string;
  avatar?: string;
};

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED)
      .min(1),
    kind: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
    manufacturer: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    model: Yup.string().max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
    serialNumber: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    licensePlate: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    features: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    performance: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE),
    width: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE),
    length: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE),
    height: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE),
    workingWidth: Yup.number()
      .min(0, VALIDATION_ERRORS.MIN_VALUE)
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE),
    nextMaintenance: Yup.number().transform((value) =>
      isNaN(value) ? undefined : value,
    ),
    tuv: Yup.number().transform((value) => (isNaN(value) ? undefined : value)),
    intact: Yup.number().min(0).max(1).required(VALIDATION_ERRORS.REQUIRED),
    alignment: Yup.array(Yup.number()),
    loadingCapacity: Yup.number()
      .max(MAX_LENGTH.NUMBER, VALIDATION_ERRORS.MAX_VALUE)
      .min(0, VALIDATION_ERRORS.MIN_VALUE),
  });
