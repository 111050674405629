import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useMemo } from "react";
import { FilterContainer } from "./FilterContainer";

export const DayFilter: React.FC<{
  mapContainer: HTMLElement | undefined;
  value: number;
  onChange: ({ start, end }: { start: number; end: number }) => void;
}> = ({ mapContainer, value, onChange: callback }) => {
  const onChange = useCallback(
    (dayJsValue: Dayjs | null) => {
      if (dayJsValue) {
        callback({
          start: dayJsValue?.startOf("day").unix() * 1000,
          end: dayJsValue?.endOf("day").unix() * 1000,
        });
      }
    },
    [callback],
  );

  const dayJsValue = useMemo(() => dayjs(value), [value]);

  const goBack = useCallback(() => {
    const newDayJsValue = dayJsValue.subtract(1, "day");
    callback({
      start: newDayJsValue.startOf("day").unix() * 1000,
      end: newDayJsValue.endOf("day").unix() * 1000,
    });
  }, [callback, dayJsValue]);

  const goForward = useCallback(() => {
    const newDayJsValue = dayJsValue.add(1, "day");
    callback({
      start: newDayJsValue.startOf("day").unix() * 1000,
      end: newDayJsValue.endOf("day").unix() * 1000,
    });
  }, [callback, dayJsValue]);

  return (
    <FilterContainer sx={{ pl: 1, pr: 1 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton onClick={goBack}>
          <ChevronLeft />
        </IconButton>
        <DatePicker
          value={dayJsValue}
          onChange={onChange}
          slotProps={{
            textField: {
              size: "small",
              variant: "standard",
            },
            popper: {
              container: mapContainer,
            },
            actionBar: {
              actions: ["today"],
            },
          }}
          views={["year", "month", "day"]}
          sx={{ width: 130 }}
        />
        <IconButton onClick={goForward}>
          <ChevronRight />
        </IconButton>
      </Stack>
    </FilterContainer>
  );
};
