import { AddCircleTwoTone } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { ROUTES } from "Constants";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const NoOrdersPlaceholder: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const createOrder = useCallback(() => {
    navigate(`${ROUTES.MAIN.ORDERS}`, { state: { create: true } });
  }, [navigate]);

  return (
    <Box mt={4} textAlign="center">
      <IconButton color="primary" size="large" onClick={createOrder}>
        <AddCircleTwoTone fontSize="large" color="primary" />
      </IconButton>
      <Typography variant="h6">
        {t("components.calendar.sidebar.noOrdersTitle")}
      </Typography>
      <Typography variant="body1">
        {t("components.calendar.sidebar.noOrdersText")}
      </Typography>
    </Box>
  );
};
