import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useField, type FieldConfig } from "formik";
import React from "react";

interface IFormCheckbox extends FieldConfig {
  label?: string;
}

export const FormCheckbox: React.FC<IFormCheckbox> = ({ label, ...rest }) => {
  const [field, _, helpers] = useField(rest.name);

  const onClick = () => {
    helpers.setValue(!field.value);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={field.value} onClick={onClick} />}
        label={label}
      />
    </FormGroup>
  );
};
