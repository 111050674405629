import FullCalendar from "@fullcalendar/react";
import { RefObject, useEffect } from "react";

export const useSidebarToggle = (calendarRef: RefObject<FullCalendar>) => {
  useEffect(() => {
    const sidebarToggles = [
      ...document.getElementsByClassName("sidebar-toggle-button"),
    ];
    const sidebarToggleHandler = () => {
      setTimeout(() => calendarRef.current?.getApi().updateSize(), 500);
    };
    sidebarToggles.forEach((el) =>
      el.addEventListener("click", sidebarToggleHandler),
    );

    return () => {
      sidebarToggles.forEach((el) =>
        el.removeEventListener("click", sidebarToggleHandler),
      );
    };
  }, [calendarRef]);
};
