import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { ViewWeek } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Switch,
} from "@mui/material";
import { setSelectedEmployees } from "Store/planning";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const getEmployeeTitle = (employee: UserResponseBody) =>
  `${employee.firstName || ""} ${employee.lastName || ""}`.trim() ||
  employee.userName;

interface IToggleResources {
  employees: UserResponseBody[];
  selectedEmployees: number[];
}

export const ToggleResources: React.FC<IToggleResources> = ({
  employees,
  selectedEmployees,
}) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleToggle = useCallback(
    (id: number) => () => {
      const filteredEmployees = selectedEmployees.includes(id)
        ? selectedEmployees.filter((eId) => eId !== id)
        : [...selectedEmployees, id];

      dispatch(setSelectedEmployees(filteredEmployees));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEmployees],
  );

  const open = Boolean(anchorEl);
  const id = open ? "toggle-resources-popover" : undefined;

  return (
    <Box textAlign={"end"}>
      <Button
        variant="outlined"
        startIcon={<ViewWeek />}
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          border: "2px solid #ff5445",
          borderRadius: "21px",
          height: "42px",
          marginRight: "8px",
          marginTop: "6px",
        }}
      >
        {t("general.labels.columns")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List dense disablePadding>
          <ListItem key={0} dense sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemText primary={t("general.labels.any")} />
            <Switch
              edge="end"
              onChange={handleToggle(0)}
              checked={selectedEmployees.includes(0)}
            />
          </ListItem>
          {employees.map((e) => (
            <ListItem
              key={e._id}
              dense
              sx={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListItemText primary={getEmployeeTitle(e)} />
              <Switch
                edge="end"
                onChange={handleToggle(e._id)}
                checked={selectedEmployees.includes(e._id)}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};
