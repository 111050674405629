import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Button, Divider, Grid2, Stack, Typography } from "@mui/material";
import logo from "Assets/logo.svg";
import { AvatarUpload } from "Components";
import { type AppState } from "Store";
import { resetMeta, updateCompanyData, updateUserData } from "Store/auth";
import { resizeTo } from "Utils";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CompanyInformation } from "./components/companyInformation/companyInformation";
import { OrganisationMapForm } from "./components/organisationMapForm";
import { UserInformation } from "./components/userInformation/userInformation";

export const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { success, loading, userData, companyData } = useSelector(
    (state: AppState) => state.auth,
  );
  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(resetMeta());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onUpdate = (valueObj: { [name: string]: string }) => {
    dispatch(updateUserData(valueObj));
  };

  const onCompanyAvatarUpdate = (valueObj: { [name: string]: string }) => {
    dispatch(updateCompanyData(valueObj));
  };

  const handleAvatarUpload = (value: File) => {
    const resizedImage = resizeTo(value, 150);
    resizedImage.then((image: string) => {
      onUpdate({ avatar: image });
    });
  };

  const handleCompanyAvatarUpload = (value: File) => {
    const resizedImage = resizeTo(value, 150);
    resizedImage.then((image: string) => {
      onCompanyAvatarUpdate({ avatar: image });
    });
  };

  return (
    (<Grid2 container spacing={4} alignItems="center" pt={2}>
      <Grid2 textAlign={"center"} size={2}>
        <AvatarUpload
          name={"user"}
          image={userData.avatar || undefined}
          loading={loading}
          onSubmit={handleAvatarUpload}
          letterAvatar={
            (userData.firstName?.[0] ?? "") + (userData.lastName?.[0] ?? "")
          }
        />
      </Grid2>
      <Grid2 size={9}>
        <UserInformation />
      </Grid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
      <Grid2 textAlign={"center"} size={2}>
        <AvatarUpload
          name={"company"}
          image={companyData.avatar || undefined}
          loading={loading}
          onSubmit={handleCompanyAvatarUpload}
          letterAvatar={companyData?.contactData?.name[0]}
        />
      </Grid2>
      <Grid2 size={9}>
        <CompanyInformation />
      </Grid2>
      <Grid2 size={1}></Grid2>
      <Grid2 size={2}></Grid2>
      <Grid2 height={400} size={9}>
        <OrganisationMapForm />
      </Grid2>
      <Grid2 mt={2} size={12}>
        <Stack alignItems="center" spacing={2}>
          <img width={84} src={logo} alt="contacts" />
          <Typography>{t("general.contactsText1")}</Typography>
          <Typography>{t("general.contactsText2")}</Typography>
          <Button
            startIcon={<LocalPhoneIcon />}
            href={"tel:" + import.meta.env.VITE_ERO_SERVICE_PHONE}
          >
            {import.meta.env.VITE_ERO_SERVICE_PHONE}
          </Button>
        </Stack>
      </Grid2>
    </Grid2>)
  );
};
