import { Box, Typography } from "@mui/material";
import React from "react";

interface IResponseContent {
  icon: JSX.Element;
  heading: string;
  body: string;
  children?: React.ReactNode;
}

export const ResponseContent: React.FC<IResponseContent> = ({
  icon,
  heading,
  body,
  children,
}) => {
  return (
    <>
      <Box>{icon}</Box>
      <Box>
        <Typography paddingTop="20px" variant="h4">
          {heading}
        </Typography>
        <Typography paddingY="20px" variant="body1">
          {body}
        </Typography>
        {children && <Box paddingTop="20px">{children}</Box>}
      </Box>
    </>
  );
};
