import { ServiceOutside } from "@ero/app-common/models";
import { GridColumnVisibilityModel, GridRowId } from "@mui/x-data-grid";
import { ServiceInformationModal } from "Components";
import { BaseDataTable } from "Components/tableV2/baseDataTable/BaseDataTable";
import { type AppState } from "Store";
import {
  deleteServices,
  getServices,
  getServicesInitial,
  resetSearch,
  setTableHiddenColumns,
  TABLE_TYPES,
} from "Store/services";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quickSearchColumns, useColumns } from "./tableConfig";

export const Services: React.FC = () => {
  const dispatch = useDispatch();
  const {
    initialMeta,
    listMeta,
    list: servicesList,
    maxCount: listMaxCount,
    listUpdateLoading: tableLoading,
    searchData,
    filters,
    loading,
    hiddenColumns,
  } = useSelector((state: AppState) => state.services);

  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceToUpdate, setServiceToUpdate] = useState<ServiceOutside>();

  const { unitOfMeasurement } = useSelector(
    (state: AppState) => state.auth.companyData,
  );

  useEffect(() => {
    dispatch(getServicesInitial(listMeta));

    return (): void => {
      dispatch(resetSearch());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableRowsDelete = useCallback(
    (deletedIds: readonly GridRowId[]) => {
      dispatch(deleteServices(deletedIds as number[]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(
        getServices({
          params: {
            ...listMeta,
            page: currentPage,
            pageLength: numberOfRows,
          },
          search: searchData,
          filters,
        }),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta, searchData, filters],
  );

  const handleTableSearch = useCallback(
    (query: string) => {
      const config = {
        params: listMeta,
        search: searchSpecShorthand(query, quickSearchColumns),
      };

      dispatch(getServices(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta],
  );

  const onRowClick = useCallback(
    (id: number) => {
      const service = servicesList.find((service) => service._id === id);
      setServiceToUpdate(service);
      setShowServiceModal(true);
    },
    [servicesList],
  );

  const onChangeColumnsView = useCallback(
    (values: GridColumnVisibilityModel) => {
      dispatch(setTableHiddenColumns(TABLE_TYPES.SERVICES_TABLE, values));
    },
    [dispatch],
  );

  const columns = useColumns(unitOfMeasurement);

  return (
    <>
      <BaseDataTable
        columnVisibilityModel={hiddenColumns[TABLE_TYPES.SERVICES_TABLE]}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={loading || tableLoading || initialMeta.loading}
        maxCount={listMaxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={onRowClick}
        rows={servicesList}
        toolbarProps={{
          onAddClick: () => setShowServiceModal(true),
          onDeleteClick: handleTableRowsDelete,
        }}
        paginationModel={{ page: listMeta.page, pageSize: listMeta.pageLength }}
      />
      <ServiceInformationModal
        isOpen={showServiceModal}
        onClose={() => {
          setShowServiceModal(false);
          setServiceToUpdate(undefined);
        }}
        service={serviceToUpdate}
      />
    </>
  );
};
