import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { reducer } from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // ignore non-serializability check for revert function of planningV2 calendar
        ignoredActionPaths: ["payload.revert"],
      },
    }).concat(sagaMiddleware);
    return middleware;
  },
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof store.getState>;

export { persistor, store };
