import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { formatTime } from "@ero/app-common/v2/utils/dateTime";
import { useMemo } from "react";

export const useTotalWorkTime = (jobs: JobResponseBody[]) => {
  const totalWorkTime = useMemo(() => {
    if (jobs) {
      const totalDuration = jobs
        .map((job) => {
          const jobDuration =
            (job.parcel.size !== null && job.parcel.size !== undefined
              ? job.parcel.size
              : 0) * job.difficulty;
          return jobDuration;
        })
        .reduce((acc: number, curr: number) => acc + curr, 0);
      const convertedDuration = formatTime(
        (totalDuration * 1000) as Milliseconds,
        false,
      );
      return convertedDuration + " h";
    } else {
      return "-";
    }
  }, [jobs]);

  return totalWorkTime;
};
