import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import {
  type ColumnFilterDataType,
  type GetTableDataParams,
  type InitialScreenMetaType,
} from "Types";
import { EMPLOYEES_ACTION_TYPES, type EmployeesAction } from "./action-types";
import { type LocalEmployeeType } from "./specs";

type EmployeesStateType = {
  list: LocalEmployeeType[];
  maxCount: number;
  hiddenColumns: Record<string, boolean>;
  listMeta: GetTableDataParams;
  onlineStates: { _id: number; online: boolean }[];
  initialMeta: InitialScreenMetaType;
  searchData?: SearchSpec;
  columnsOrder?: number[];
  filters?: ColumnFilterDataType[];
  listUpdateLoading: boolean;
  isHiddenColumnsSet: boolean;
  success: boolean;
  loading: boolean;
  error: boolean;
  editPasswordSuccess: boolean;
};

const initialState: EmployeesStateType = {
  list: [] as LocalEmployeeType[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  onlineStates: [],
  searchData: undefined,
  columnsOrder: undefined,
  filters: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  hiddenColumns: {},
  isHiddenColumnsSet: false,
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
  editPasswordSuccess: false,
};

export const employeesReducer = (
  state = initialState,
  action: EmployeesAction,
): EmployeesStateType => {
  switch (action.type) {
    case EMPLOYEES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case EMPLOYEES_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };

    case EMPLOYEES_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };

    case EMPLOYEES_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
        editPasswordSuccess: false,
      };

    case EMPLOYEES_ACTION_TYPES.RESET_SEARCH:
      return {
        ...state,
        searchData: undefined,
      };

    case EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        listUpdateLoading: true,
      };

    case EMPLOYEES_ACTION_TYPES.UPDATE_EMPLOYEE_LIVE: {
      const current: Map<number, boolean> = new Map();
      for (const entry of state.onlineStates) {
        current.set(entry._id, entry.online);
      }

      for (const update of action.payload) {
        current.set(update._id, update.online);
      }

      const onlineStates: EmployeesStateType["onlineStates"] = [];
      for (const [_id, online] of current.entries()) {
        onlineStates.push({ _id, online });
      }

      return { ...state, onlineStates };
    }

    case EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };

    case EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        list: action.payload.employees,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        list: action.payload.employees,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        filters: action.payload.filters,
        listUpdateLoading: false,
      };
    case EMPLOYEES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
        isHiddenColumnsSet: true,
      };
    case EMPLOYEES_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    case EMPLOYEES_ACTION_TYPES.SET_EDIT_PASSWORD_SUCCESS:
      return {
        ...state,
        editPasswordSuccess: true,
      };
    default:
      return state;
  }
};
