import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { Draggable } from "@fullcalendar/interaction";
import { PendingActions } from "@mui/icons-material";
import { Grid2, IconButton, Paper } from "@mui/material";
import { ROUTES } from "Constants";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { EventContent } from "Screens/planningV2/components/eventContent";
import { displayDateRestriction } from "Utils/date";

interface IOrderItem {
  order: OrderResponseBody<number[]>;
}

export const OrderItem: React.FC<IOrderItem> = ({ order }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { fullscreenContainer } = useFullscreenContext();

  const draggableRef = useRef<Draggable | null>(null);

  const [paperRef, setPaperRef] = useState<HTMLDivElement | null>(null);

  const storePaperRef = useCallback(
    (instance: HTMLDivElement | null) => setPaperRef(instance),
    [],
  );

  const orderInformation = useMemo(() => {
    return {
      _id: order._id,
      name: order.name,
      notes: order.notes ?? "",
      customer: order.customer,
      parcelsCount: order.jobDetails?.parcelCount,
      parcelsSize: order.jobDetails?.parcelSize,
      grapeVarieties: order.jobDetails?.grapeVarieties || [],
      gemarkungen: order.jobDetails?.gemarkungen || [],
      jobs: order.jobDetails?.jobs,
    };
  }, [
    order._id,
    order.customer,
    order.jobDetails?.parcelCount,
    order.jobDetails?.parcelSize,
    order.jobDetails?.grapeVarieties,
    order.jobDetails?.gemarkungen,
    order.jobDetails?.jobs,
    order.name,
    order.notes,
  ]);

  useEffect(() => {
    if (paperRef && !draggableRef.current) {
      draggableRef.current = new Draggable(paperRef, {
        eventData: {
          id: `${order._id}`,
          create: true,
          duration: order.remainingTime,
          extendedProps: { order: orderInformation },
        },
        appendTo: fullscreenContainer ?? undefined,
      });
      return () => {
        try {
          draggableRef.current?.destroy();
        } catch (e) {
          console.warn("Unable to destroy calendar draggable", e);
        }
      };
    }
  }, [
    fullscreenContainer,
    order._id,
    order.end,
    order.name,
    order.remainingTime,
    order.start,
    orderInformation,
    paperRef,
  ]);

  const dateRestrictionsContent = useMemo(
    () =>
      displayDateRestriction(
        order.dateRestrictions,
        t("orders.createModal.toDo"),
        t("orders.createModal.until"),
      ),
    [order.dateRestrictions, t],
  );

  const goToOrderDetails = useCallback(
    () => navigate(`${ROUTES.MAIN.ORDERS}/${order._id}`),
    [navigate, order._id],
  );

  return (
    <Grid2 size={12}>
      <Paper
        sx={{
          width: "100%",
          p: 2,
          cursor: "pointer",
          mb: 3,
          position: "relative",
        }}
        elevation={3}
        ref={storePaperRef}
      >
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={goToOrderDetails}
        >
          <PendingActions />
        </IconButton>
        <EventContent
          dateRestrictions={dateRestrictionsContent}
          orderInfo={orderInformation}
          showAllInfo
        />
      </Paper>
    </Grid2>
  );
};
