import {
  JobResponseBody,
  JobsResponseBody,
} from "@ero/app-common/v2/routes/models/job";
import { OrdersResponseBody } from "@ero/app-common/v2/routes/models/order";
import { UsersResponseBody } from "@ero/app-common/v2/routes/models/user";
import { CalendarEventColor, CalendarView } from "Enums";
import { type CalendarDateRange, type InitialScreenMetaType } from "Types";
import { uniqByKey } from "Utils";
import {
  PLANNING_ACTION_TYPES,
  type OrderCalendarAction,
} from "./action-types";
import { FilterType } from "./types";

export type PlanningStateType = {
  initialMeta: InitialScreenMetaType;
  loading: boolean;
  success: boolean;
  error: boolean;
  orders: OrdersResponseBody["data"];
  jobs: JobsResponseBody["data"];
  employees: UsersResponseBody["data"];
  filters: FilterType;
  calendarCurrentView: string;
  dateRange: CalendarDateRange;
  displayedDateRange: CalendarDateRange;
  scrollPosition: number | undefined;
  eventColor: CalendarEventColor;
  rowScale: number;
  assignMachineStatus: { error: boolean; success: boolean };
  updateJobLoading: boolean;
  selectedEmployees: number[];
};

const initialState: PlanningStateType = {
  initialMeta: {
    loading: true,
    error: false,
  },
  loading: false,
  success: false,
  error: false,
  orders: [],
  jobs: [],
  employees: [],
  filters: {},
  calendarCurrentView: CalendarView.DAY,
  dateRange: { start: 0, end: 0 },
  displayedDateRange: { start: 0, end: 0 },
  scrollPosition: undefined,
  eventColor: CalendarEventColor.SingleColor,
  rowScale: 25,
  assignMachineStatus: { error: false, success: false },
  updateJobLoading: false,
  selectedEmployees: [],
};

export const planningReducer = (
  state = initialState,
  action: OrderCalendarAction,
): PlanningStateType => {
  switch (action.type) {
    case PLANNING_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case PLANNING_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case PLANNING_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        updateJobLoading: false,
      };
    case PLANNING_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        filters: {},
      };
    case PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        jobs: action.payload.jobs,
        employees: action.payload.employees,
        dateRange: action.payload.dateRange,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
        dateRange: action.payload.dateRange,
      };
    case PLANNING_ACTION_TYPES.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case PLANNING_ACTION_TYPES.SET_JOBS:
      return {
        ...state,
        dateRange: action.payload.dateRange,
        jobs: action.payload.jobs,
      };
    case PLANNING_ACTION_TYPES.RESCHEDULE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: uniqByKey(
          [...state.jobs, ...action.payload.jobs],
          "_id",
        ) as JobResponseBody[],
      };
    case PLANNING_ACTION_TYPES.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.map((item) =>
          item._id === action.payload.job._id ? action.payload.job : item,
        ),
      };
    case PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS_SUCCESS:
      return {
        ...state,
        jobs: uniqByKey(
          [...state.jobs, ...action.payload.newJobs],
          "_id",
        ) as JobResponseBody[],
      };
    case PLANNING_ACTION_TYPES.ADD_CALENDAR_JOB:
      return {
        ...state,
        jobs: [...state.jobs, action.payload.job],
      };
    case PLANNING_ACTION_TYPES.REMOVE_CALENDAR_JOB: {
      return {
        ...state,
        jobs: state.jobs.filter((item) => item._id !== action.payload.job._id),
      };
    }
    case PLANNING_ACTION_TYPES.FILTER_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        filters: action.payload.filters,
      };
    case PLANNING_ACTION_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case PLANNING_ACTION_TYPES.SET_SHOW_STATUS_COLOR:
      return {
        ...state,
        eventColor: action.payload.eventColor,
      };
    case PLANNING_ACTION_TYPES.SET_ROW_SCALE:
      return {
        ...state,
        rowScale: action.payload.value,
      };

    case PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW:
      return {
        ...state,
        calendarCurrentView: action.payload.view,
      };
    case PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW_RANGE:
      return {
        ...state,
        displayedDateRange: action.payload.dateRange,
      };
    case PLANNING_ACTION_TYPES.UPDATE_JOB_LOADING:
      return {
        ...state,
        updateJobLoading: action.payload.loading,
      };
    case PLANNING_ACTION_TYPES.ASSIGN_MACHINE_STATUS:
      return {
        ...state,
        assignMachineStatus: action.payload,
        updateJobLoading: false,
      };
    case PLANNING_ACTION_TYPES.SET_SELECTED_EMPLOYEES:
      return {
        ...state,
        selectedEmployees: action.payload,
      };
    case PLANNING_ACTION_TYPES.SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    default:
      return state;
  }
};
