import { apiInstance } from "App/axios";
import { RequestBody, type ApiListRequestParams, type PlotType } from "Types";

export const getPlots = ({
  fieldId,
  params,
  search,
  spec,
}: ApiListRequestParams<PlotType> & { fieldId: string }): Promise<any> =>
  apiInstance.post(
    "/plots/all",
    { search, spec },
    {
      params: {
        field: fieldId,
        ...params,
      },
    },
  );

export const getPlotsOfParcel = ({
  parcelId,
  search,
  spec,
}: ApiListRequestParams<PlotType> & { parcelId: string }): Promise<any> =>
  apiInstance.post(
    "/plots/all",
    { search, spec },
    {
      params: {
        parcel: parcelId,
        pageLength: -1,
        page: -1,
      },
    },
  );

export const createPlot = (
  fieldId: string,
  data: RequestBody<"/plots/create">,
): Promise<any> =>
  apiInstance.post(`/plots/create?field=${fieldId}`, { ...data });

export const createPlotForParcel = (
  parcelId: string,
  data: RequestBody<"/plots/create">,
): Promise<any> =>
  apiInstance.post(`/plots/create?parcel=${parcelId}`, { ...data });

export const deletePlots = (ids: number[]): Promise<any> =>
  apiInstance.post("/plots/delete", { ids });

export const updatePlot = (
  updateData: RequestBody<"/plots/update">,
  id: number,
): Promise<any> => apiInstance.post(`date?id=${id}`, { ...updateData });
