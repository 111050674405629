import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/import";
import { apiInstanceV2 } from "App/axios";

export const importParcels = (
  data: FormData,
  onUploadProgress: (progressEvent) => void,
  externalAccessToken?: string,
) => {
  const config = { onUploadProgress, timeout: 120000 };
  if (externalAccessToken) {
    config["headers"] = { Authorization: `Bearer ${externalAccessToken}` };
  }
  return apiInstanceV2.post(baseUrl + SubUrls.ROOT, data, config);
};
