import { EventResponseBody } from "@ero/app-common/v2/routes/models/event";
import {
  EventChangeArg,
  EventClickArg,
  EventContentArg,
  EventInput,
} from "@fullcalendar/core";
import {
  EventDragStartArg,
  EventDragStopArg,
  EventReceiveArg,
} from "@fullcalendar/interaction";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { errorToast } from "Services";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { Event } from "../components/event/event";

export const useEvents = (
  events: EventResponseBody[],
  loadingEvents: boolean,
) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);

  const calendarEvents = useMemo(
    () =>
      events.map(
        (event): EventInput => ({
          id: `${event._id}`,
          title: `${event.order?.name}`,
          resourceId: `${event.user}`,
          start: event.start,
          end: event.end,
          extendedProps: event,
          groupId: selectedEvents.includes(event._id)
            ? "selection"
            : `${event._id}`,
          editable: !event.locked,
          startEditable: !event.locked,
          resourceEditable: !event.locked,
        }),
      ),
    [events, selectedEvents],
  );

  const eventDragStart = useCallback((arg: EventDragStartArg) => {
    setSelectedEvents((curr) => {
      if (curr.includes(Number(arg.event.id))) {
        return curr;
      }
      return [];
    });

    const orderListContainer = document.getElementById("orderListContainer");
    if (orderListContainer) {
      orderListContainer.style.display = "none";
    }
    const dropzoneContainer = document.getElementById("dropzoneContainer");
    if (dropzoneContainer) {
      dropzoneContainer.style.display = "block";
    }
  }, []);

  const eventDragStop = useCallback(
    (arg: EventDragStopArg): void => {
      if (
        arg.jsEvent.target instanceof HTMLElement &&
        arg.jsEvent.target.closest("#dropzone")
      ) {
        const eventId = Number(arg.event.id);
        const deleteIds = selectedEvents.includes(eventId)
          ? selectedEvents
          : [eventId];
        dispatch(sagaActions.deleteEvent({ ids: deleteIds }));
      }

      const orderListContainer = document.getElementById("orderListContainer");
      if (orderListContainer) {
        orderListContainer.style.display = "block";
      }
      const dropzoneContainer = document.getElementById("dropzoneContainer");
      if (dropzoneContainer) {
        dropzoneContainer.style.display = "none";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const eventReceive = useCallback(
    (arg: EventReceiveArg) => {
      setSelectedEvents([]);
      if (arg.event.start && arg.event.end) {
        const user = Number(arg.event.getResources()[0].id);
        dispatch(
          sagaActions.addEvent({
            event: {
              order: Number(arg.event.id),
              jobs: arg.event.extendedProps.order.jobs,
              start: arg.event.start.getTime(),
              end: arg.event.end.getTime(),
              user,
            },
            revert: arg.revert,
          }),
        );
      } else {
        arg.revert();
        errorToast(t("errors.unableToAddEvent"));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const eventChange = useCallback(
    (arg: EventChangeArg) => {
      const calendarEventsForUpdate = [arg.event, ...arg.relatedEvents];
      dispatch(
        sagaActions.updateEvents({
          events: calendarEventsForUpdate.map((event) => ({
            id: Number(event.id),
            update: {
              start: event.start?.getTime(),
              end: event.end?.getTime(),
              user: Number(event.getResources()[0].id),
            },
          })),
          revert: arg.revert,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const eventClick = useCallback((arg: EventClickArg) => {
    setSelectedEvents((curr) => {
      if (arg.event.extendedProps.locked) {
        return curr;
      }

      const eventId = Number(arg.event.id);
      if (!arg.jsEvent.ctrlKey && !curr.includes(eventId)) {
        return [];
      }
      if (arg.jsEvent.ctrlKey) {
        const index = curr.findIndex((id) => eventId === id);
        if (index !== -1) {
          return curr.toSpliced(index, 1);
        } else {
          return [...curr, eventId];
        }
      }
      return curr;
    });
  }, []);

  const getEventContent = useCallback(
    (arg: EventContentArg) => {
      const event = arg.event.extendedProps as EventResponseBody;
      return (
        <Event
          start={arg.event.start}
          end={arg.event.end}
          event={event}
          loading={loadingEvents}
          selected={selectedEvents.includes(event._id)}
        />
      );
    },
    [loadingEvents, selectedEvents],
  );

  const result = useMemo(
    () => ({
      calendarEvents,
      eventDragStart,
      eventDragStop,
      eventReceive,
      eventChange,
      eventClick,
      getEventContent,
    }),
    [
      calendarEvents,
      eventChange,
      eventClick,
      eventDragStart,
      eventDragStop,
      eventReceive,
      getEventContent,
    ],
  );

  return result;
};
