import { buffer, featureCollection, union } from "@turf/turf";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MERGE_BUFFER_DISTANCE } from "../../utils";

const isPolygonFeature = (
  polygon: Feature<Polygon | MultiPolygon> | null | undefined,
): polygon is Feature<Polygon> =>
  polygon !== undefined &&
  polygon !== null &&
  polygon.geometry.type === "Polygon";

export const useMergePolygons = () => {
  const [t] = useTranslation();

  const merge = useMemo(
    () =>
      (polygons: Feature<Polygon>[]): Feature<Polygon> => {
        const result = union(featureCollection(polygons));
        if (result === null) {
          throw new Error("Unable to merge parcels", {
            cause: t("notification.error.polygonMerging.cause.operationFailed"),
          });
        }

        const bufferedResult = buffer(result, -MERGE_BUFFER_DISTANCE, {
          units: "meters",
        });
        if (!isPolygonFeature(bufferedResult)) {
          throw new Error("Unable to merge parcels", {
            cause: t("notification.error.polygonMerging.cause.notContiguous"),
          });
        }

        return bufferedResult;
      },
    [t],
  );

  return merge;
};
