import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { List, Map } from "@mui/icons-material";
import { Tab, Tabs, TabsActions } from "@mui/material";
import {
  gridExpandedSortedRowIdsSelector,
  gridFilterModelSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { getParcelsV2 } from "Api";
import { useFormikContext } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../../formConfig";
import { SelectParcelMap } from "./components/selectParcelMap";
import { SelectParcelTable } from "./components/selectParcelTable";

interface ISelectParcelScreen {
  selectedParcels: number[];
  setSelectedParcels: Dispatch<SetStateAction<number[]>>;
  allParcelsOfCustomer: ParcelsResponseBody["data"];
  setAllParcelsOfCustomer: Dispatch<
    React.SetStateAction<ParcelsResponseBody["data"]>
  >;
  isFullScreen: boolean;
}

export const SelectParcelScreen: React.FC<ISelectParcelScreen> = ({
  selectedParcels,
  setSelectedParcels,
  allParcelsOfCustomer,
  setAllParcelsOfCustomer,
  isFullScreen,
}) => {
  const [t] = useTranslation();

  const [view, setView] = useState(0);

  const [currentSearchQuery, setCurrentSearchQuery] = useState<string>("");
  const [mapParcels, setMapParcels] =
    useState<ParcelsResponseBody["data"]>(allParcelsOfCustomer);

  const tableRef = useGridApiRef();

  const {
    values: { customer },
  } = useFormikContext<ValuesType>();

  useEffect(() => {
    const setParcels = async () => {
      const parcelsResponse = await getParcelsV2({
        limit: 9999,
        includeJobs: false,
        customer: +customer,
      });
      setAllParcelsOfCustomer(parcelsResponse.data);
      setMapParcels(parcelsResponse.data);
    };
    setParcels();
  }, [setAllParcelsOfCustomer, customer]);

  const tabsActions = useRef<TabsActions | null>(null);

  useEffect(() => {
    if (tabsActions.current) {
      tabsActions.current.updateIndicator();
    }
  }, [isFullScreen]);

  const onTabChange = useCallback((_: any, newVal: number) => {
    setView(newVal);
  }, []);

  useEffect(() => {
    const handleFilteredRowsChanged = () => {
      const searchQuery = gridFilterModelSelector(tableRef).quickFilterValues;

      if (searchQuery) setCurrentSearchQuery(searchQuery.join(" "));

      const ids = gridExpandedSortedRowIdsSelector(tableRef) as number[];
      setMapParcels(
        allParcelsOfCustomer.filter((parcel) => ids.includes(parcel._id)),
      );
    };

    return tableRef.current.subscribeEvent(
      "filteredRowsSet",
      handleFilteredRowsChanged,
    );
  }, [allParcelsOfCustomer, setCurrentSearchQuery, tableRef]);

  const handleMapSearch = useCallback(
    (query: string) => {
      tableRef.current.setFilterModel({
        items: [],
        quickFilterValues: [query],
      });
    },
    [tableRef],
  );

  const handleMapSelect = useCallback(
    (id: number) => {
      if (selectedParcels.filter((parcel) => parcel === id).length > 0) {
        setSelectedParcels([
          ...selectedParcels.filter((parcel) => parcel !== id),
        ]);
      } else {
        setSelectedParcels([...selectedParcels, id]);
      }
    },
    [selectedParcels, setSelectedParcels],
  );

  return (
    <>
      <Tabs
        value={view}
        onChange={onTabChange}
        variant="fullWidth"
        action={tabsActions}
      >
        <Tab
          icon={<List />}
          iconPosition="start"
          label={t("general.labels.list")}
        />
        <Tab
          icon={<Map />}
          iconPosition="start"
          label={t("general.navigation.map")}
        />
      </Tabs>
      <SelectParcelTable
        parcels={allParcelsOfCustomer}
        selectedParcels={selectedParcels}
        setSelectedParcels={setSelectedParcels}
        tableRef={tableRef}
        showComponent={view === 0}
      />
      <SelectParcelMap
        parcels={mapParcels}
        selectedParcels={selectedParcels}
        handleSelect={handleMapSelect}
        showComponent={view === 1}
        currentSearchQuery={currentSearchQuery}
        handleSearch={handleMapSearch}
      />
    </>
  );
};
