import { type EventInput } from "@fullcalendar/core";
import { type CalendarProps } from "../types";

const noop = () => {};

export const useEventChange = (
  props: CalendarProps,
  {
    internalEvents,
    setInternalEvents,
  }: {
    internalEvents: EventInput[];
    setInternalEvents: (value: EventInput[]) => void;
  },
) => {
  const { loading = false, onEventDrag = noop } = props;

  const handleEventChange = (params) => {
    const { event, revert, oldEvent, relatedEvents } = params;
    if (loading) {
      revert();
      return;
    }

    const localEvents = [...internalEvents];
    const index = localEvents.findIndex(
      (app: EventInput) => app.id === event.id,
    );

    if (index !== -1) {
      localEvents[index] = {
        ...localEvents[index],
        start: new Date(event.start),
        end: new Date(event.end),
        resourceIds: event
          .getResources()
          .filter((item) => item)
          .map((resource) => resource.id),
      };

      relatedEvents.forEach((relatedEvent) => {
        const relatedIndex = localEvents.findIndex(
          (app: EventInput) => app.id === relatedEvent.id,
        );

        if (relatedIndex !== -1) {
          localEvents[relatedIndex] = {
            ...localEvents[relatedIndex],
            start: new Date(relatedEvent.start),
            end: new Date(relatedEvent.end),
            resourceIds: relatedEvent
              .getResources()
              .filter((item) => item)
              .map((resource) => resource.id),
          };
        }
      });

      setInternalEvents(localEvents);
    } else {
      revert();
    }

    onEventDrag(event, revert, oldEvent, relatedEvents, localEvents);
  };

  return handleEventChange;
};
