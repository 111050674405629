import {
  IResetStateAction,
  ORGANIZATION_ACTION_TYPES,
  type IAddDropdown,
  type IAddDropdownSuccess,
  type IGetAllDropdowns,
  type IGetAllDropdownsSuccess,
  type IRemoveDropdown,
  type IResetMetaAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type IUpdateDropdown,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: ORGANIZATION_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: ORGANIZATION_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: ORGANIZATION_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: ORGANIZATION_ACTION_TYPES.RESET_META,
});

export const getAllDropdowns = (key: string): IGetAllDropdowns => ({
  type: ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS,
  key,
});

export const getAllDropdownsSuccess = (payload): IGetAllDropdownsSuccess => ({
  type: ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS_SUCCESS,
  payload,
});

export const addDropdown = (payload): IAddDropdown => ({
  type: ORGANIZATION_ACTION_TYPES.ADD_DROPDOWN,
  payload,
});

export const addDropdownSuccess = (payload): IAddDropdownSuccess => ({
  type: ORGANIZATION_ACTION_TYPES.ADD_DROPDOWN_SUCCESS,
  payload,
});

export const removeDropdown = (payload): IRemoveDropdown => ({
  type: ORGANIZATION_ACTION_TYPES.REMOVE_DROPDOWN,
  payload,
});

export const updateDropdown = (payload): IUpdateDropdown => ({
  type: ORGANIZATION_ACTION_TYPES.UPDATE_DROPDOWN,
  payload,
});

export const resetState = (): IResetStateAction => ({
  type: ORGANIZATION_ACTION_TYPES.RESET_STATE,
});
