import {
  UserRequestBody,
  UserResponseBody,
} from "@ero/app-common/v2/routes/models/user";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/users";
import { apiInstance, apiInstanceV2 } from "App/axios";
import {
  type CHANGE_PASSWORD_DTO,
  type Coordinate,
  type FORGOT_PASS_DTO,
  type LOGIN_DTO,
  type ResetPassType,
  type VerifyPassType,
} from "Types";

export const login = (data: LOGIN_DTO): Promise<any> =>
  apiInstance.post("/persons/login", data);

export const logout = (token: string) =>
  apiInstance.post("/persons/logout", { token });

export const verifyPassword = (data: VerifyPassType) =>
  apiInstance.post("/persons/verifyAccount", data);

export const resetPassword = (data: ResetPassType): Promise<any> =>
  apiInstance.post("/persons/passwordReset", data);

export const forgotPassword = (data: FORGOT_PASS_DTO): Promise<any> =>
  apiInstance.post("/persons/passwordResetLink", data);

export const changePassword = (data: CHANGE_PASSWORD_DTO) =>
  apiInstance.post("/persons/passwordChange", data);

export const getSelf = (token?: string) => {
  const headers = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return apiInstance.get("/persons/self", { headers });
};

export const updateUserProfile = async (id: number, body: UserRequestBody) =>
  (
    await apiInstanceV2.patch<UserResponseBody>(
      baseUrl + SubUrls.BY_ID.replace(":id", `${id}`),
      body,
    )
  ).data;

export const refreshToken = (refreshToken: string) =>
  apiInstance.post("/persons/keep-alive", { refreshToken });

export const getCompanyDetails = (token: string) =>
  apiInstance.get("/company/view", {
    headers: { Authorization: `Bearer ${token}` },
  });

export const getProfileDetails = (token: string) =>
  apiInstance.get("/persons/self", {
    headers: { Authorization: `Bearer ${token}` },
  });

export const updateCompanyDetails = (
  values: Record<string, string | Coordinate>,
) => apiInstance.post("/company/update", values);
