import { InfoWindow, InfoWindowProps } from "@react-google-maps/api";
import React, { forwardRef, PropsWithChildren } from "react";

const InfoWindowV2 = forwardRef<InfoWindow, PropsWithChildren<InfoWindowProps>>(
  (props, ref) => {
    return (
      <InfoWindow {...props} ref={ref}>
        {props.children}
      </InfoWindow>
    );
  },
);

InfoWindowV2.displayName = "InfoWindowV2";

export { InfoWindowV2 };
