import { InputAdornment, TextField } from "@mui/material";
import React from "react";

interface INotEditableField {
  label?: string;
  text: string;
  disabled?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  onClick?: (event?: React.MouseEvent) => void;
  isHighlighted?: boolean;
  required?: boolean;
}

export const NotEditableField: React.FC<INotEditableField> = ({
  label,
  text,
  startIcon,
  endIcon,
  disabled = false,
  onClick,
  required = false,
}) => {
  return (
    <TextField
      disabled
      onClick={!disabled ? onClick : () => {}}
      required={required}
      label={label}
      value={text}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
      }}
    ></TextField>
  );
};
