import { Button } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

interface ParcelButtons {
  goBackToMainContent: () => void;
}

export const ParcelButtons: React.FC<ParcelButtons> = ({
  goBackToMainContent,
}) => {
  const [t] = useTranslation();
  const { values } = useFormikContext<FormikValues>();

  return (
    <>
      <Button onClick={goBackToMainContent}>{t("general.buttons.back")}</Button>
      <Button
        variant="contained"
        disabled={!values.parcel}
        onClick={goBackToMainContent}
      >
        {t("orders.details.overview.selectParcel")}
      </Button>
    </>
  );
};
