import { CustomerOutside } from "@ero/app-common/models/Customer";
import {
  RequestBody,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  CUSTOMER_PERSONS_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreateCustomerPersonAction,
  type IDeleteCustomerPersonAction,
  type IGetCustomerPersonsRequestAction,
  type IGetCustomerPersonsSuccessAction,
  type IGetInitialCustomerPersonsErrorAction,
  type IGetInitialCustomerPersonsRequestAction,
  type IGetInitialCustomerPersonsSuccessAction,
  type IResetMetaAction,
  type IResetState,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateCustomerPersonAction,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.RESET_META,
});

export const getCustomerPersonsInitial = (
  customerId: number,
  params: GetTableDataParams,
): IGetInitialCustomerPersonsRequestAction => {
  return {
    type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_REQUEST,
    payload: {
      params,
      customerId,
    },
  };
};

export const getCustomerPersonsInitialSuccess = (
  customerEmployees: CustomerOutside["persons"],
  maxCount: number,
  customerId: number,
): IGetInitialCustomerPersonsSuccessAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_SUCCESS,
  payload: {
    customerPersons: customerEmployees,
    maxCount,
    customerId,
  },
});

export const getCustomerPersonsInitialError =
  (): IGetInitialCustomerPersonsErrorAction => ({
    type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_ERROR,
  });

export const createCustomerPerson = (
  data: RequestBody<"/customers/addPerson">,
): ICreateCustomerPersonAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.CREATE_CUSTOMER_PERSON,
  payload: { data },
});

export const updateCustomerPerson = (
  updateData: RequestBody<"/customers/updatePerson">,
  id: number,
): IUpdateCustomerPersonAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.UPDATE_CUSTOMER_PERSON,
  payload: { updateData, id },
});

export const deleteCustomerPerson = (
  ids: number[],
): IDeleteCustomerPersonAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.DELETE_CUSTOMER_PERSON,
  payload: { ids },
});

export const getCustomerPerson = ({
  params,
  withSuccess,
  search,
}: GetTableEntitiesType): IGetCustomerPersonsRequestAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_REQUEST,
  payload: { params, withSuccess, search },
});

export const getCustomerPersonSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
}: SetTableEntityType<
  CustomerOutside["persons"][0]
>): IGetCustomerPersonsSuccessAction => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_SUCCESS,
  payload: { customerPersons: list, maxCount, listMeta, search },
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
): ISetTableHiddenColumns => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});

export const resetState = (): IResetState => ({
  type: CUSTOMER_PERSONS_ACTION_TYPES.RESET_STATE,
});
