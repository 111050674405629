import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Box } from "@mui/material";
import { FullScreenControlV2, MapV2, ZoomControlV2 } from "Components/map";
import {
  BottomControls,
  TopControls,
} from "Components/map/components/controls";
import { AppState } from "Store";
import { useField } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ParcelFigures } from "../components/parcelFigures";
import { IContentProps } from "./types";

const DEFAULT_ZOOM = 12;

export const ParcelSelectionMap: React.FC<
  IContentProps & { name: string; parcels: ParcelResponseBody[] }
> = ({ name, parcels }) => {
  const map = useRef<google.maps.Map>();

  const [field, _, helpers] = useField<string | number>(name);

  const companyLocation = useSelector(
    (state: AppState) => state.auth.companyData.machinePosition,
  );

  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);

  const center = useMemo(() => companyLocation, [companyLocation]);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    map.current = mapInstance;
  }, []);

  const onZoomChanged = useCallback(
    (zoom?: number) => {
      const currentZoom = zoom ?? map.current?.getZoom() ?? DEFAULT_ZOOM;
      if (zoom) map.current?.setZoom(currentZoom);
      setZoom(currentZoom);
    },
    [map],
  );

  const parcelOnClick = useCallback(
    (id: number) => {
      if (field.value === id) {
        helpers.setValue("");
      } else {
        helpers.setValue(id);
      }
    },
    [helpers, field.value],
  );

  return (
    <Box height={500}>
      <MapV2
        id="add-job-map"
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
      >
        <ParcelFigures
          zoom={zoom}
          parcels={parcels}
          itemOnClick={parcelOnClick}
          selectedParcel={field.value}
        />
        <TopControls>
          <FullScreenControlV2 mapRef={map} />
        </TopControls>
        <BottomControls>
          <ZoomControlV2 zoom={zoom} onZoomChanged={onZoomChanged} />
        </BottomControls>
      </MapV2>
    </Box>
  );
};
