import { Box, Container, Typography } from "@mui/material";
import { Loader } from "Components";
import { AppState } from "Store";
import { getFaqs } from "Store/help";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Question } from "./question";

export const Help: React.FC = () => {
  const { faqs, initialMeta } = useSelector((state: AppState) => state.help);

  const [isExpanded, setIsExpanded] = useState<false | string>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!faqs.length) {
      dispatch(getFaqs());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqs.length]);

  if (initialMeta.loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Container maxWidth={"lg"} sx={{ pt: 2 }}>
        <Typography variant="h4">FAQ</Typography>
        {faqs.map((question) => (
          <Box key={question._id} sx={{ py: 2 }}>
            <Question
              question={question}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          </Box>
        ))}
      </Container>
    </Box>
  );
};
