import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import {
  ColumnFilterDataType,
  type GetTableDataParams,
  type InitialScreenMetaType,
} from "Types";
import { MACHINES_ACTION_TYPES, type MachinesAction } from "./action-types";
import { type LocalMachineType } from "./specs";

type MachinesStateType = {
  list: LocalMachineType[];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  searchData?: SearchSpec;
  columnsOrder?: number[];
  filters?: ColumnFilterDataType[];
  listUpdateLoading: boolean;
  hiddenColumns: Record<string, boolean>;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: MachinesStateType = {
  list: [] as LocalMachineType[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  filters: undefined,
  searchData: undefined,
  columnsOrder: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  hiddenColumns: {},
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const machinesReducer = (
  state = initialState,
  action: MachinesAction,
): MachinesStateType => {
  switch (action.type) {
    case MACHINES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case MACHINES_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case MACHINES_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case MACHINES_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };
    case MACHINES_ACTION_TYPES.RESET_SEARCH:
      return {
        ...state,
        searchData: undefined,
      };
    case MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_SUCCESS:
      return {
        ...state,
        list: action.payload.machines,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case MACHINES_ACTION_TYPES.GET_MACHINES_REQUEST:
      return {
        ...state,
        listUpdateLoading: true,
      };
    case MACHINES_ACTION_TYPES.GET_MACHINES_SUCCESS:
      return {
        ...state,
        list: action.payload.machines,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        filters: action.payload.filters,
        listUpdateLoading: false,
      };
    case MACHINES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
      };
    case MACHINES_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    default:
      return state;
  }
};
