import Button from "@mui/material/Button";
import { type SizeType } from "Types";
import React, { PropsWithChildren } from "react";
import { Loader } from "../loader";

interface IProps {
  onClick?: () => void;
  type?: "button" | "submit";
  fullWidth?: boolean;
  disabled?: boolean;
  size?: SizeType;
  loading?: boolean;
  formKey?: string;
}

export const RegularButton: React.FC<PropsWithChildren<IProps>> = ({
  size = "medium",
  type = "button",
  children,
  disabled = false,
  fullWidth = false,
  onClick,
  loading = false,
  formKey,
}) => {
  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (loading) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      type={type}
      size={size === "big" ? "large" : size}
      disabled={disabled}
      onClick={clickHandler}
      form={formKey}
      fullWidth={fullWidth}
    >
      {children}
      {loading && <Loader size={30} />}
    </Button>
  );
};
