import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { Edit } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { type AppState } from "Store";
import { millisecondsToTime } from "Utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TelemetryEntry as TelemtryEntryType } from "../../telemetryConfig";

interface ITelemetryEntry {
  item: TelemtryEntryType;
  openEditTelemetryModal: (value: TelemtryEntryType) => void;
}

export const TelemetryEntry: React.FC<ITelemetryEntry> = ({
  item,
  openEditTelemetryModal,
}) => {
  const [t] = useTranslation();
  const { jobTelemetry } = useSelector((state: AppState) => state.orderDetails);

  const { inputType, suffix, key, noEdit, emptyValue } = item;

  const telemetryValue = jobTelemetry[key];

  const getValueString = (value: number | Milliseconds | undefined) => {
    if (value === undefined) {
      return emptyValue || t("telemetry.NA");
    }
    switch (inputType) {
      case "time":
        return millisecondsToTime(value as Milliseconds, true);
      default:
        return `${value.toFixed(0)} ${suffix}`;
    }
  };

  return (
    <Tooltip title={item.tooltip}>
      <>
        <ListItem
          disablePadding
          secondaryAction={
            !noEdit && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => openEditTelemetryModal(item)}
              >
                <Edit fontSize="small" />
              </IconButton>
            )
          }
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primary={getValueString(telemetryValue)}
            secondary={item.name}
          />
        </ListItem>
        <Divider />
      </>
    </Tooltip>
  );
};
