import {
  DataGridProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowClassNameParams,
  GridRowId,
  GridRowSelectionModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ConfirmDialog, Loader } from "Components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StripedDataGrid } from "../components/StripedDataGrid";
import {
  BaseDataTableToolbar,
  BaseDataTableToolbarProps,
} from "./components/BaseDataToolbar";

// augment the props for the toolbar slot
declare module "@mui/x-data-grid" {
  interface LoadingOverlayPropsOverrides {
    size?: string | number;
  }
}

const columnDefaults: Omit<GridColDef, "field"> = {
  flex: 1,
  minWidth: 70,
  sortable: false,
};

export type BaseTableProps = {
  loading: boolean;
  columns: GridColDef<GridValidRowModel>[];
  rows: GridValidRowModel[];
  rowIdField?: string;
  maxCount?: number;
  handlePagination: (page: number, pageSize: number) => void;
  onRowClick: (id: number) => void;
  toolbarProps: Omit<
    BaseDataTableToolbarProps,
    "showDelete" | "onDeleteClick" | "setColumnsButtonEl"
  > & { onDeleteClick?: (ids: readonly GridRowId[]) => void };
  handleQuickSearch: (query: string) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (newMode: GridColumnVisibilityModel) => void;
  paginationModel?: GridPaginationModel;
  searchQuery?: string;
};

export const BaseDataTable: React.FC<BaseTableProps> = ({
  loading,
  columns,
  rows,
  rowIdField = "_id",
  maxCount,
  handlePagination,
  onRowClick,
  toolbarProps,
  handleQuickSearch,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  paginationModel,
  searchQuery,
}) => {
  const [t] = useTranslation();

  const [columnsButtonEl, setColumnsButtonEl] =
    useState<HTMLButtonElement | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const columnsWithDefaults: GridColDef<GridValidRowModel>[] = useMemo(() => {
    const columnsWithDefaults = columns.map((column) => ({
      ...columnDefaults,
      ...column,
    }));
    const selectBoxColumn = {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
    };
    return [selectBoxColumn, ...columnsWithDefaults];
  }, [columns]);

  const showDelete = useMemo(
    () => toolbarProps.onDeleteClick && selectedRows.length > 0,
    [selectedRows.length, toolbarProps.onDeleteClick],
  );

  const initialState = useMemo(
    () => ({
      pagination: {
        paginationModel: {
          ...{
            pageSize: 25,
          },
          ...paginationModel,
        },
      },
      filter: {
        filterModel: {
          items: [],
          quickFilterExcludeHiddenColumns: true,
          quickFilterValues: searchQuery ? [searchQuery] : [],
        },
      },
    }),
    [paginationModel, searchQuery],
  );

  const slots = useMemo(
    () => ({ toolbar: BaseDataTableToolbar, loadingOverlay: Loader }),
    [],
  );

  const onDeleteClick = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const slotProps: DataGridProps["slotProps"] = useMemo(
    () => ({
      panel: {
        anchorEl: columnsButtonEl,
      },
      toolbar: {
        ...toolbarProps,
        showDelete,
        onDeleteClick,
        setColumnsButtonEl,
      },
      columnsManagement: {
        disableResetButton: true,
        disableShowHideToggle: true,
        getTogglableColumns: (columns: GridColDef[]) =>
          columns
            .filter(
              (column) =>
                column.field !== GRID_CHECKBOX_SELECTION_COL_DEF.field,
            )
            .map((column) => column.field),
      },
    }),
    [columnsButtonEl, onDeleteClick, showDelete, toolbarProps],
  );

  const handleDeleteApprove = useCallback(() => {
    setIsConfirmModalOpen(false);
    if (toolbarProps.onDeleteClick) toolbarProps.onDeleteClick(selectedRows);
  }, [toolbarProps, selectedRows]);

  const onPaginationModalChange = useCallback(
    (model: GridPaginationModel): void =>
      handlePagination(model.page, model.pageSize),
    [handlePagination],
  );

  const getRowId = useCallback(
    (row: GridValidRowModel) => row[rowIdField],
    [rowIdField],
  );

  const rowClickHandler = useCallback(
    (row: GridValidRowModel) => onRowClick(row.row._id),
    [onRowClick],
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) =>
      params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd",
    [],
  );

  const onFilterModelChange = useCallback(
    (model) => {
      if (model.quickFilterValues) {
        handleQuickSearch(model.quickFilterValues.join(" "));
      }
    },
    [handleQuickSearch],
  );

  const handleColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel): void => {
      const newHiddenColumns = { ...columnVisibilityModel, ...model };
      if (onColumnVisibilityModelChange) {
        onColumnVisibilityModelChange(newHiddenColumns);
      }
    },
    [columnVisibilityModel, onColumnVisibilityModelChange],
  );

  const closeConfirmDialog = useCallback(
    () => setIsConfirmModalOpen(false),
    [],
  );

  return (
    <>
      <StripedDataGrid
        initialState={initialState}
        loading={loading}
        paginationMode="server"
        rowCount={maxCount}
        onPaginationModelChange={onPaginationModalChange}
        paginationModel={paginationModel}
        columns={columnsWithDefaults}
        rows={rows}
        getRowId={getRowId}
        onRowClick={rowClickHandler}
        getRowClassName={getRowClassName}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
        slots={slots}
        slotProps={slotProps}
        onFilterModelChange={onFilterModelChange}
        filterMode={"server"}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        columnVisibilityModel={columnVisibilityModel}
        sx={{ overflow: "hidden" }}
      />
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        onApprove={handleDeleteApprove}
        onClose={closeConfirmDialog}
        title={t("components.deleteDialog.title")}
        text={t("components.deleteDialog.textFirstPart", {
          count: selectedRows.length,
        })}
        text_2={t("components.deleteDialog.textSecondPart")}
        positiveButtonText={t("components.deleteDialog.deleteButton")}
        negativeButtonText={t("components.deleteDialog.cancelButton")}
      />
    </>
  );
};
