import { UsersResponseBody } from "@ero/app-common/v2/routes/models/user";
import { ResourceLabelContentArg } from "@fullcalendar/resource";
import { Avatar, Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";

export const useResources = (users: UsersResponseBody["data"]) => {
  const resources = useMemo(
    () =>
      users.map((user, index) => ({
        id: `${user._id}`,
        index,
        ...user,
      })) ?? [],
    [users],
  );

  const getResourceLabelContent = useCallback(
    (arg: ResourceLabelContentArg) => {
      const { firstName, lastName, userName } = arg.resource.extendedProps;
      return (
        <Stack
          key={arg.resource.id}
          direction="row"
          alignItems="center"
          gap={1}
          padding={1}
        >
          <Avatar
            sx={{ width: 24, height: 24 }}
            src={arg.resource.extendedProps.avatar}
          >
            {arg.resource.extendedProps.userName[0].toUpperCase()}
          </Avatar>
          <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
            {firstName ? `${firstName} ${lastName}` : userName}
          </Typography>
        </Stack>
      );
    },
    [],
  );

  const result = useMemo(
    () => ({ resources, getResourceLabelContent }),
    [getResourceLabelContent, resources],
  );

  return result;
};
