import { type spec_shape } from "@ero/app-common/routes/validators";
import { createMachine, deleteMachines, getMachines, updateMachine } from "Api";
import { errorToast } from "Services";
import { getTableParamsAfterDeletion } from "Utils";
import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import {
  MACHINES_ACTION_TYPES,
  type ICreateMachineRequestAction,
  type IDeleteMachinesRequestAction,
  type IGetInitialMachinesRequestAction,
  type IGetMachinesRequestAction,
  type IUpdateMachineRequestAction,
} from "./action-types";
import {
  getMachinesInitialError,
  getMachinesInitialSuccess,
  getMachinesSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { machinesSpec as machinesSpec_ } from "./specs";
const machinesSpec = machinesSpec_ as unknown as z.infer<typeof spec_shape>;

export function* getInitialMachinesSaga({
  payload,
}: IGetInitialMachinesRequestAction) {
  const { params } = payload;

  try {
    const store = yield select();

    const { data } = yield call(getMachines, {
      params,
      search: store.machines.searchData,
      spec: machinesSpec,
    });

    yield put(getMachinesInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getMachinesInitialError());

    errorToast(undefined, undefined, error);
  }
}

export function* getMachinesSaga({ payload }: IGetMachinesRequestAction) {
  const { params, withSuccess, search, filters } = payload;

  try {
    const { data } = yield call(getMachines, {
      params,
      search,
      filters,
      spec: machinesSpec,
    });

    yield put(
      getMachinesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        filters,
        search,
      }),
    );

    if (withSuccess) {
      yield put(setSuccess());

      yield put(resetMeta());
    }
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* createMachineSaga({ payload }: ICreateMachineRequestAction) {
  const { machine } = payload;

  try {
    yield put(setLoading());

    yield call(createMachine, machine);

    const store = yield select();

    const { data } = yield call(getMachines, {
      params: store.machines.listMeta,
      search: store.machines.searchData,
      spec: machinesSpec,
    });

    yield put(
      getMachinesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: store.machines.listMeta,
        search: store.machines.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* deleteMachinesSaga({ payload }: IDeleteMachinesRequestAction) {
  const { ids } = payload;

  try {
    yield put(setLoading());

    yield call(deleteMachines, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.machines.listMeta,
      store.machines.list.length,
      ids.ids.length,
    );

    const { data } = yield call(getMachines, {
      params: listMeta,
      search: store.machines.searchData,
      spec: machinesSpec,
    });

    yield put(
      getMachinesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta,
        search: store.machines.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateMachinesSaga({ payload }: IUpdateMachineRequestAction) {
  const { machine, id } = payload;

  try {
    yield put(setLoading());

    yield call(updateMachine, machine, id);

    const store = yield select();

    const { data } = yield call(getMachines, {
      params: store.machines.listMeta,
      search: store.machines.searchData,
      spec: machinesSpec,
    });

    yield put(
      getMachinesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: store.machines.listMeta,
        search: store.machines.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    if (!machine) {
      errorToast(i18n.t("errors.noCompanyPosition"));
      return;
    }

    errorToast(undefined, undefined, error);
  }
}

export default function* machinesSagas() {
  yield all([
    takeEvery(
      MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_REQUEST,
      getInitialMachinesSaga,
    ),
    takeEvery(MACHINES_ACTION_TYPES.GET_MACHINES_REQUEST, getMachinesSaga),
    takeEvery(MACHINES_ACTION_TYPES.CREATE_MACHINE_REQUEST, createMachineSaga),
    takeEvery(MACHINES_ACTION_TYPES.UPDATE_MACHINE_REQUEST, updateMachinesSaga),
    takeEvery(
      MACHINES_ACTION_TYPES.DELETE_MACHINES_REQUEST,
      deleteMachinesSaga,
    ),
  ]);
}
