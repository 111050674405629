import { FaqsResponseBody } from "@ero/app-common/v2/routes/models/faq";
import { type InitialScreenMetaType } from "Types";
import { HELP_ACTION_TYPES, type HelpAction } from "./action-types";

type HelpStateType = {
  faqs: FaqsResponseBody["data"];
  initialMeta: InitialScreenMetaType;
};

const initialState: HelpStateType = {
  faqs: [],
  initialMeta: {
    loading: true,
    error: false,
  },
};

export const helpReducer = (state = initialState, action: HelpAction) => {
  switch (action.type) {
    case HELP_ACTION_TYPES.GET_FAQS_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case HELP_ACTION_TYPES.GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: action.payload.faqs,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case HELP_ACTION_TYPES.GET_FAQS_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
};
