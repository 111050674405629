import { SxProps, Theme } from "@mui/material";
import { OptionType } from "Types/internal";
import React, { useEffect, useState } from "react";
import { StaticSelectWithAutoComplete } from "./StaticSelectWithAutoComplete";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  disableClearable?: boolean;
  fetchOptions: () => Promise<OptionType[]>;
  sx?: SxProps<Theme>;
  placeholder?: string;
  onChange?: (value: OptionType | null) => void;
  valueSource?: "value" | "label";
};

export const SelectWithAutoCompleteComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (props, ref) => {
  const { fetchOptions, ...rest } = props;
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const fetchedOptions = await fetchOptions();
      setOptions(fetchedOptions);
      setLoading(false);
    })();
  }, [fetchOptions]);

  return (
    <StaticSelectWithAutoComplete
      {...rest}
      ref={ref}
      loading={loading}
      options={options}
    />
  );
};

export const SelectWithAutoComplete = React.forwardRef(
  SelectWithAutoCompleteComponent,
);
SelectWithAutoComplete.displayName = "SelectWithAutoComplete";
