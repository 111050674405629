import { type AxiosError } from "axios";
import { ApiError } from "Enums";
import i18n from "i18next";

export const getErrorMessage = (error: unknown): string => {
  if ((error as AxiosError).isAxiosError && (error as AxiosError).response) {
    const err = error as AxiosError;

    if (typeof err.response?.data === "string") {
      if (err.response.data.split(" ")[1] === "ZIP_ARCHIVE_NO_SHP\n") {
        return i18n.t("notification.uploadError");
      }

      return err.response.data;
    }
    if (err.response?.data && typeof err.response?.data === "object") {
      return (
        getErrorLabelByCode(err.response!.data["code"]) ||
        err.response!.data["message"]
      );
    }
  }

  if (error instanceof Error) {
    return error.message;
  }
  return i18n.t("notification.unknownError");
};

function getErrorLabelByCode(code: ApiError) {
  switch (code) {
    case ApiError.NO_TOKEN_PROVIDED:
      return i18n.t("errors.noTokenProvided");
    case ApiError.TOKEN_EXPIRED:
      return i18n.t("errors.tokenExpired");
    case ApiError.INSUFFICIENT_PERMISSIONS:
      return i18n.t("errors.insufficientPermissions");
    case ApiError.TOKEN_NOT_RECOGNIZED:
      return i18n.t("errors.tokenNotRecognized");
    case ApiError.USER_NOT_VERIFIED:
      return i18n.t("errors.userNotVerified");
    case ApiError.PERMISSION_NOT_RECOGNIZED:
      return i18n.t("errors.permissionNotRecognized");
    case ApiError.SHOULD_BE_NUMBER:
      return i18n.t("errors.shouldBeNumber");
    case ApiError.SHOULD_BE_BOOLEAN:
      return i18n.t("errors.shouldBeBoolean");
    case ApiError.MISSING_KEY:
      return i18n.t("errors.missingKey");
    case ApiError.KEY_NOT_RECOGNIZED:
      return i18n.t("errors.keyNotRecognized");
    case ApiError.BODY_NOT_PARSEABLE:
      return i18n.t("errors.bodyNotParseable");
    case ApiError.WRONG_TYPE:
      return i18n.t("errors.wrongType");
    case ApiError.NOT_FOUND_COMPANY:
      return i18n.t("errors.companyNotFound");
    case ApiError.NOT_FOUND_CUSTOMER:
      return i18n.t("errors.customerNotFound");
    case ApiError.NOT_FOUND_BILLING:
      return i18n.t("errors.billingNotFound");
    case ApiError.NOT_FOUND_POSITION:
      return i18n.t("errors.positionNotFound");
    case ApiError.NOT_FOUND_ORDER:
      return i18n.t("errors.orderNotFound");
    case ApiError.NOT_FOUND_USER:
      return i18n.t("errors.userNotFound");
    case ApiError.NOT_FOUND_MACHINE:
      return i18n.t("errors.machineNotFound");
    case ApiError.NOT_FOUND_JOB:
      return i18n.t("errors.jobNotFound");
    case ApiError.NOT_FOUND_SERVICE:
      return i18n.t("errors.serviceNotFound");
    case ApiError.NOT_FOUND_ROW:
      return i18n.t("errors.rowNotFound");
    case ApiError.NOT_FOUND_PARCEL:
      return i18n.t("errors.parcelNotFound");
    case ApiError.NOT_FOUND_FIELD:
      return i18n.t("errors.fieldNotFound");
    case ApiError.NOT_FOUND_PLOT:
      return i18n.t("errors.plotNotFound");
    case ApiError.NOT_FOUND_FILE:
      return i18n.t("errors.fileNotFound");
    case ApiError.NOT_FOUND_FAQ:
      return i18n.t("errors.faqNotFound");
    case ApiError.NOT_FOUND_PERSON:
      return i18n.t("errors.personNotFound");
    case ApiError.NOT_FOUND_TIMESLOT:
      return i18n.t("errors.timeslotNotFound");
    case ApiError.ALLEGIANCE_CUSTOMER:
      return i18n.t("errors.customerAllegiance");
    case ApiError.ALLEGIANCE_BILLING:
      return i18n.t("errors.billingAllegiance");
    case ApiError.ALLEGIANCE_MACHINE:
      return i18n.t("errors.machineAllegiance");
    case ApiError.ALLEGIANCE_JOB:
      return i18n.t("errors.jobAllegiance");
    case ApiError.ALLEGIANCE_SERVICE:
      return i18n.t("errors.serviceAllegiance");
    case ApiError.ALLEGIANCE_ORDER:
      return i18n.t("errors.orderAllegiance");
    case ApiError.ALLEGIANCE_PARCEL:
      return i18n.t("errors.parcelAllegiance");
    case ApiError.ALLEGIANCE_FIELD:
      return i18n.t("errors.fieldAllegiance");
    case ApiError.ALLEGIANCE_PLOT:
      return i18n.t("errors.plotAllegiance");
    case ApiError.ALLEGIANCE_USER:
      return i18n.t("errors.userAllegiance");
    case ApiError.ALLEGIANCE_TIMESLOT:
      return i18n.t("errors.timeslotAllegiance");
    case ApiError.PARCEL_NOT_ASSIGNED:
      return i18n.t("errors.parcelNotAssigned");
    case ApiError.MACHINE_NOT_ASSIGNED:
      return i18n.t("errors.machineNotAssigned");
    case ApiError.USER_NOT_ASSIGNED:
      return i18n.t("errors.userNotAssigned");
    case ApiError.ALREADY_ASSIGNED_PARCEL:
      return i18n.t("errors.parcelAlreadyAssigned");
    case ApiError.ALREADY_ASSIGNED_MACHINE:
      return i18n.t("errors.machineAlreadyAssigned");
    case ApiError.ALREADY_ASSIGNED_USER:
      return i18n.t("errors.userAlreadyAssigned");
    case ApiError.JOB_MACHINES_SCHEDULE_CONFLICT:
      return i18n.t("errors.jobMachinesScheduleConflict");
    case ApiError.CONFLICTING_TIME_SLOTS:
      return i18n.t("errors.conflictingTimeSlots");
    case ApiError.ZIP_ARCHIVE_NO_SHP:
      return i18n.t("errors.zipArchiveNoShp");
    case ApiError.ZIP_ARCHIVE_NO_DBF:
      return i18n.t("errors.zipArchiveNoDbf");
    case ApiError.ZIP_ARCHIVE_NO_PRJ:
      return i18n.t("errors.zipArchiveNoPrj");
    case ApiError.NO_CSV_FOUND:
      return i18n.t("errors.noCsvFound");
    case ApiError.SHAPEFILE_NOT_STANDARD_COMPLIANT:
      return i18n.t("errors.shapefileNotStandardCompliant");
    case ApiError.CSV_PARSE_ERROR:
      return i18n.t("errors.csvParseError");
    case ApiError.RELATIONSHIP_MAPPING_ERROR:
      return i18n.t("errors.relationshipMapping");
    case ApiError.NO_EXISTING_COUNTERPART:
      return i18n.t("errors.missingCounterpart");
    case ApiError.ORDER_ALREADY_ADDED:
      return i18n.t("errors.orderAlreadyAdded");
    case ApiError.USERNAME_ALREADY_TAKEN:
      return i18n.t("errors.usernameAlreadyTaken");
    case ApiError.SLNR_NO_MATCH:
      return i18n.t("errors.slnrNoMatch");
    case ApiError.IDENTIFIER_NOT_RECOGNIZED:
      return i18n.t("errors.identifierNotRecognized");
    case ApiError.MISSING_REQUIRED_COLUMNS:
      return i18n.t("errors.missingRequiredColumn");
    case ApiError.USER_ALREADY_ACTIVATED:
      return i18n.t("errors.userAlreadyActived");
    case ApiError.CANNOT_RECEIVE_EMAILS:
      return i18n.t("errors.cannotReceiveEmail");
    case ApiError.INVITE_EMAIL_XOR_PASSWORD:
      return i18n.t("errors.inviteEmailXorPassword");
    case ApiError.CANNOT_OVERWRITE_EMAIL_ACCOUNT:
      return i18n.t("errors.cannotOverwriteEmailAccount");
    case ApiError.CANNOT_SELF_DELETE:
      return i18n.t("errors.cannotSelfDelete");
    case ApiError.TIME_CONSTRAINTS_INVALID:
      return i18n.t("errors.timeConstraintsInvalid");
    case ApiError.BROKEN_FILEPATH:
      return i18n.t("errors.brokenFilepath");
    case ApiError.COLLECTION_REFERENCE_DEAD:
      return i18n.t("errors.collectionReferenceDead");
    case ApiError.DATAURI_EXTRACTION_FAILED:
      return i18n.t("errors.datauriExtractionFailed");
    case ApiError.CSV_EMPTY:
      return i18n.t("errors.csvEmpty");
    case ApiError.MODELNAME_UNRECOGNIZED:
      return i18n.t("errors.modelnameNotRecongnized");
    case ApiError.PIPELINE_LOGIC_BROKEN:
      return i18n.t("errors.pipelineLogicBroken");
  }
}
