import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { Person } from "@mui/icons-material";
import { Box, IconButton, List, Popover, Tooltip } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { planningV2Slice } from "Store/planningV2/planningV2slice";
import { UserListItem } from "./userListItem";

export const UserSelection: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const {
    users,
    selectedUsers: previouslySelectedUsers,
    sortedUsers: previouslySortedUsers,
  } = useSelector((state: AppState) => state.planningV2);

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const id = useMemo(
    () => (open ? "toggle-resources-popover" : undefined),
    [open],
  );

  const selectedUsers = useMemo(() => {
    if (previouslySelectedUsers !== undefined) {
      return previouslySelectedUsers;
    }
    return users.map((u) => u._id);
  }, [previouslySelectedUsers, users]);

  const sortedUsers = useMemo(() => {
    const sortedUsers = previouslySortedUsers
      .map((id) => users.find((u) => u._id === id))
      .filter((u): u is UserResponseBody => u !== undefined);
    const unsortedUsers = users.filter(
      (u) => !previouslySortedUsers.includes(u._id),
    );
    return [...sortedUsers, ...unsortedUsers];
  }, [previouslySortedUsers, users]);

  const sortedUserIds = useMemo(
    () => sortedUsers.map((u) => u._id),
    [sortedUsers],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      if (!e.over) {
        return;
      }
      if (e.active.id !== e.over.id) {
        const oldIndex = sortedUserIds.indexOf(e.active.id as number);
        const newIndex = sortedUserIds.indexOf(e.over.id as number);
        dispatch(
          planningV2Slice.actions.setSortedUsers(
            arrayMove(sortedUserIds, oldIndex, newIndex),
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedUserIds],
  );

  const userListItems = useMemo(
    () =>
      sortedUsers.map((user) => {
        // toggle has to be defined here, as otherwise we run into problems with the function
        // not being called due to some weird behavior in combination with dndkit
        const toggle = () => {
          if (selectedUsers.includes(user._id)) {
            dispatch(
              sagaActions.setSelectedUsers(
                selectedUsers.filter(
                  (selectedUser) => selectedUser != user._id,
                ),
              ),
            );
          } else {
            dispatch(
              sagaActions.setSelectedUsers([...selectedUsers, user._id]),
            );
          }
        };
        const selected = selectedUsers.includes(user._id);
        const lastSelected = selected && selectedUsers.length == 1;
        return (
          <UserListItem
            key={user._id}
            user={user}
            handleToggle={toggle}
            selected={selected}
            disabled={lastSelected}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUsers, sortedUsers],
  );

  return (
    <Box textAlign={"end"}>
      <Tooltip title={t("planningV2.calendarColumns")}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Person />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{ paper: { sx: { overflow: "hidden" } } }}
        container={fullscreenContainer}
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <List>
            <SortableContext items={sortedUserIds}>
              {userListItems}
            </SortableContext>
          </List>
        </DndContext>
      </Popover>
    </Box>
  );
};
