import { AccountCircleRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import { Loader } from "Components/loader/loader";
import { AppState } from "Store";
import { Field, Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const FormContent: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { loading } = useSelector((state: AppState) => state.auth);

  return (
    <Form>
      <List>
        <ListItem>
          <Field name="userName">
            {({ field, meta }) => (
              <TextField
                {...field}
                type="text"
                label={t("general.fields.userName")}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
                fullWidth
                error={!!meta.error}
                helperText={meta.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>{<AccountCircleRounded />}</IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            startIcon={loading && <Loader size={30} />}
          >
            {t("general.buttons.send")}
          </Button>
        </ListItem>
        <ListItem>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            color="primary"
            variant="text"
            fullWidth
          >
            {t("general.buttons.back")}
          </Button>
        </ListItem>
      </List>
    </Form>
  );
};
