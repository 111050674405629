import { CompanyDropdown } from "@ero/app-common/models";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { convertToDuration } from "@ero/app-common/util/convertDifficulty";
import { numberAsCurrency } from "@ero/app-common/util/numberFormatter";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { UNIT } from "@ero/app-common/util/Units";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getBillingUnitNameByValue } from "Utils";

export const useColumns = (
  unitOfMeasurement: UNIT,
): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  const columns: GridColDef<GridValidRowModel>[] = useMemo(
    () => [
      {
        field: "internalId",
        headerName: t("general.fields.id"),
      },
      {
        field: "name",
        headerName: t("general.fields.name"),
      },
      {
        field: "description",
        headerName: t("general.fields.description"),
      },
      {
        field: "billingUnit",
        headerName: t("services.fields.billingUnit"),
        valueGetter: (value) =>
          getBillingUnitNameByValue(value, unitOfMeasurement),
      },
      {
        field: "cost",
        headerName: t("services.fields.cost"),
        valueGetter: (value) => numberAsCurrency(value),
      },
      {
        field: "difficulty",
        headerName: t("services.fields.areaPerformance", {
          unit: UnitSymbol[unitOfMeasurement],
        }),
        valueGetter: (value) =>
          convertToDuration(value * 1000, unitOfMeasurement, true),
      },
      {
        field: "notes",
        headerName: t("general.fields.notes"),
      },
      {
        field: "additionalName",
        headerName: t("services.fields.additionalName"),
      },
      {
        field: "serviceKind",
        headerName: t("services.fields.serviceKind"),
        valueGetter: (value: CompanyDropdown) => {
          if (value._id === -1) {
            return "";
          }
          return value.name;
        },
      },
    ],
    [t, unitOfMeasurement],
  );
  return columns;
};

export const defaultHiddenColumns = {
  internalId: false,
};

export const defaultHiddenColumnsCreateOrder = {
  internalId: false,
  description: false,
  cost: false,
  notes: false,
  additionalName: false,
  serviceKind: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "number", access: ["internalId"], default: "-" },
  { type: "string", access: ["name"] },
  { type: "string", access: ["description"] },
  { type: "string", access: ["notes"] },
  { type: "string", access: ["additionalName"] },
];
