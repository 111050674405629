import { getExternalToken } from "Api";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  EXTERNAL_ACCESS_ACTION_TYPES,
  type IGetTokenRequest,
} from "./action-types";
import { getExternalTokenError, getExternalTokenSuccess } from "./actions";

export function* getExternalTokenSaga({ payload }: IGetTokenRequest) {
  const { requestToken } = payload;

  try {
    const { data } = yield call(getExternalToken, requestToken);
    yield put(getExternalTokenSuccess(data.externalAccessToken));
  } catch (_) {
    yield put(getExternalTokenError());
  }
}

export default function* authSagas() {
  yield all([
    takeEvery(
      EXTERNAL_ACCESS_ACTION_TYPES.GET_TOKEN_REQUEST,
      getExternalTokenSaga,
    ),
  ]);
}
