import { centerOfMass, polygon } from "@turf/turf";
import { useMemo } from "react";
import { Coordinate } from "Types";
import {
  ParcelData,
  isParcelWithPosition,
  isParcelWithShape,
} from "../components";

export const useParcelCenter = (parcel: ParcelData): Coordinate | undefined => {
  const center = useMemo(() => {
    if (isParcelWithPosition(parcel)) {
      return parcel.position;
    }
    if (!isParcelWithShape(parcel)) {
      return undefined;
    }
    if (parcel.shape.length < 3) {
      return parcel.shape[0];
    }
    const p = centerOfMass(
      polygon([
        [
          ...parcel.shape.map((coordinate) => [coordinate.lat, coordinate.lng]),
          [parcel.shape[0].lat, parcel.shape[0].lng],
        ],
      ]),
    );
    return { lat: p.geometry.coordinates[0], lng: p.geometry.coordinates[1] };
  }, [parcel]);
  return center;
};
