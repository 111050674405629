import { ServiceOutside } from "@ero/app-common/models";
import {
  convertToDifficulty,
  convertToDuration,
} from "@ero/app-common/util/convertDifficulty";
import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import {
  createService as defaultCreateService,
  resetMeta as defaultResetMeta,
  updateService as defaultUpdateService,
} from "Store/services";
import { RequestBody } from "Types";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { BasicData } from "./screens";
import { getValidationSchema } from "./validationConfig";

interface IProps {
  isOpen: boolean;
  service?: ServiceOutside;
  onClose: () => void;
  onSuccess?: () => void;
  getStoreState?: (store: AppState) => any;
  resetMeta?: () => AnyAction;
  createService?: (data: RequestBody<"/services/create">) => AnyAction;
  updateService?: (
    data: RequestBody<"/services/update">,
    id: number,
  ) => AnyAction;
}

export const ServiceInformationModal: React.FC<IProps> = ({
  isOpen,
  service,
  onClose,
  onSuccess,
  getStoreState = (state: AppState) => state.services,
  resetMeta = defaultResetMeta,
  createService = defaultCreateService,
  updateService = defaultUpdateService,
}: IProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { success, loading } = useSelector(getStoreState);
  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  useEffect(() => {
    if (success) {
      if (onSuccess) onSuccess();
      dispatch(resetMeta());
      onClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onSuccess, onClose, resetMeta]);

  const initialValues = useMemo(
    () => ({
      ...service,
      serviceKind: service?.serviceKind._id ?? "",
      billingUnit: service?.billingUnit ?? "",
      cost: service?.cost !== undefined ? service.cost / 100 : "",
      difficulty: service?.difficulty
        ? convertToDuration(service?.difficulty * 1000, unitOfMeasurement, true)
        : "",
    }),
    [service, unitOfMeasurement],
  );

  const handleFormSubmit = useCallback(
    (values) => {
      const { difficulty, ...update } = values;
      update.difficulty = convertToDifficulty(difficulty, unitOfMeasurement);
      update.cost = update.cost * 100;
      if (service?._id) {
        dispatch(updateService(update, service._id));
      } else {
        dispatch(createService(update));
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [service?._id, createService, unitOfMeasurement, updateService],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      scroll="body"
    >
      <DialogTitle>
        {service?._id ? service.name : t("services.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ isValid, dirty, submitForm }) => (
          <>
            <Form>
              <DialogContent dividers>
                <BasicData />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={!isValid || !dirty || loading}
                  startIcon={!loading && <Save />}
                >
                  {loading && <Loader size={30} />}
                  {t("general.buttons.save")}
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
