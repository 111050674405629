import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  createReleaseNote,
  deleteReleaseNotes,
  getReleaseNotes,
  updateReleaseNote,
} from "Api";
import { errorToast } from "Services";
import { type ApiResponse, type ReleaseNotesListResponse } from "Types";
import { getTableParamsAfterDeletion } from "Utils";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import {
  RELEASENOTES_ACTION_TYPES,
  type ICreateReleaseNoteAction,
  type IDeleteReleaseNotesAction,
  type IGetInitialReleaseNotesRequestAction,
  type IGetReleaseNotesRequestAction,
  type IUpdateReleaseNotesAction,
} from "./action-types";
import {
  getReleaseNotesInitialError,
  getReleaseNotesInitialSuccess,
  getReleaseNotesSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { releaseNotesSpec as releaseNotesSpec_ } from "./specs";
const releaseNotesSpec = releaseNotesSpec_ as unknown as z.infer<
  typeof spec_shape
>;

export function* getInitialReleaseNotesSaga({
  payload,
}: IGetInitialReleaseNotesRequestAction) {
  const { params } = payload;

  try {
    //TODO find out whether this has side effects or can be removed
    yield select();

    const { data }: ApiResponse<ReleaseNotesListResponse> = yield call(
      getReleaseNotes,
      { params, spec: releaseNotesSpec },
    );

    yield put(getReleaseNotesInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getReleaseNotesInitialError());

    errorToast(undefined, undefined, error);
  }
}

export function* getReleaseNotesSaga({
  payload,
}: IGetReleaseNotesRequestAction) {
  const { params, search } = payload;

  try {
    const { data }: ApiResponse<ReleaseNotesListResponse> = yield call(
      getReleaseNotes,
      { params, search, spec: releaseNotesSpec },
    );

    yield put(
      getReleaseNotesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* deleteReleaseNotesSaga({
  payload,
}: IDeleteReleaseNotesAction) {
  const { ids } = payload;

  try {
    yield call(deleteReleaseNotes, ids);

    const store = yield select();

    //Recalculate number of pages after deletion of records
    const listMeta = getTableParamsAfterDeletion(
      store.releaseNotes.listMeta,
      store.releaseNotes.list.length,
      ids.length,
    );

    //Re request relase notes from api after deletion
    const releaseNotesResult: ApiResponse<ReleaseNotesListResponse> =
      yield call(getReleaseNotes, {
        params: listMeta,
        search: store.releaseNotes.searchData,
        spec: releaseNotesSpec,
      });

    //Fire get-releasenotes-success action
    yield put(
      getReleaseNotesSuccess({
        list: releaseNotesResult.data.data,
        maxCount: releaseNotesResult.data.maxCount,
        listMeta,
        search: store.releaseNotes.searchData,
      }),
    );

    //Fire set success action
    yield put(setSuccess());

    //Fire set reset meta action
    yield put(resetMeta());
  } catch (error) {
    //Catch potentially arised error
    yield put(setError());
    errorToast(undefined, undefined, error);
  }
}

export function* updateReleaseNoteSaga({ payload }: IUpdateReleaseNotesAction) {
  const { updateData, id } = payload;

  try {
    yield put(setLoading());

    yield call(updateReleaseNote, updateData, id);

    const store = yield select();

    const releaseNotesResult: ApiResponse<ReleaseNotesListResponse> =
      yield call(getReleaseNotes, {
        params: store.releaseNotes.listMeta,
        search: store.releaseNotes.searchData,
        spec: releaseNotesSpec,
      });

    yield put(
      getReleaseNotesSuccess({
        list: releaseNotesResult.data.data,
        maxCount: releaseNotesResult.data.maxCount,
        listMeta: store.releaseNotes.listMeta,
        search: store.releaseNotes.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* createReleaseNoteSaga({ payload }: ICreateReleaseNoteAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    yield call(createReleaseNote, data);

    const store = yield select();

    const licensesResult: ApiResponse<ReleaseNotesListResponse> = yield call(
      getReleaseNotes,
      {
        params: store.licenses.listMeta,
        search: store.licenses.searchData,
        spec: releaseNotesSpec,
      },
    );

    yield put(
      getReleaseNotesSuccess({
        list: licensesResult.data.data,
        maxCount: licensesResult.data.maxCount,
        listMeta: store.licenses.listMeta,
        search: store.licenses.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export default function* releaseNoteSagas() {
  yield all([
    takeEvery(
      RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_REQUEST,
      getInitialReleaseNotesSaga,
    ),
    takeEvery(
      RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_REQUEST,
      getReleaseNotesSaga,
    ),
    takeEvery(
      RELEASENOTES_ACTION_TYPES.DELETE_RELEASENOTES,
      deleteReleaseNotesSaga,
    ),
    takeEvery(
      RELEASENOTES_ACTION_TYPES.CREATE_RELEASENOTE,
      createReleaseNoteSaga,
    ),
    takeEvery(
      RELEASENOTES_ACTION_TYPES.UPDATE_RELEASENOTE,
      updateReleaseNoteSaga,
    ),
  ]);
}
