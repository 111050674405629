import { type EventInput } from "@fullcalendar/core";
import { isTargetOverDiv } from "Utils";
import { type CalendarProps } from "../types";

export const useDragStop = (
  props: CalendarProps,
  {
    internalEvents,
    setInternalEvents,
    setIsDragging,
  }: {
    internalEvents: EventInput[];
    setInternalEvents: (value: EventInput[]) => void;
    setIsDragging: (value: boolean) => void;
  },
) => {
  const { droppableOutsideContainer, onEventDragStop } = props;

  const onDragStopHandler = (info) => {
    const { jsEvent, event } = info;
    setIsDragging(false);

    if (droppableOutsideContainer) {
      const target = {
        x: jsEvent.x,
        y: jsEvent.y,
      };

      if (isTargetOverDiv(target, droppableOutsideContainer)) {
        setInternalEvents(
          internalEvents.filter((item) => item.id !== event.id),
        );
        if (onEventDragStop) onEventDragStop(event);
        return;
      }
    }

    if (onEventDragStop) onEventDragStop();
  };

  return onDragStopHandler;
};
