import { JOBSTATUS } from "@ero/app-common/enums";
import { type ParcelOutside } from "@ero/app-common/models";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import markerIconDefault from "Assets/icons/map-marker-google_DEFAULT.svg";
import markerIconDone from "Assets/icons/map-marker-google_DONE.svg";
import markerIconInWork from "Assets/icons/map-marker-google_INWORK.svg";
import markerIconOnRoute from "Assets/icons/map-marker-google_ONROUTE.svg";
import markerIconOpen from "Assets/icons/map-marker-google_OPEN.svg";
import markerIconPause from "Assets/icons/map-marker-google_PAUSE.svg";
import { type Coordinate } from "Types";

export function shapeToBounds(shape: Coordinate[]) {
  const LatLngBounds = (window as any).google?.maps?.LatLngBounds;
  return shape.reduce(
    (bounds, coord) => bounds.extend(coord),
    new LatLngBounds(),
  );
}

export function getParcelPosition(
  parcel: Partial<ParcelOutside> | Partial<ParcelResponseBody>,
): Coordinate | undefined {
  const LatLngBounds = (window as any).google?.maps?.LatLngBounds;
  if (LatLngBounds === undefined) {
    return;
  }

  if (parcel?.shape?.length) {
    const center = shapeToBounds(parcel.shape).getCenter();
    return { lat: center.lat(), lng: center.lng() };
  } else {
    return parcel?.position ?? undefined;
  }
}

export function positionEquals(
  a: Coordinate | undefined,
  b: Coordinate | undefined,
) {
  return a?.lat === b?.lat && a?.lng === b?.lng;
}

export function isPathEqual(path1: Coordinate[], path2: Coordinate[]): boolean {
  return (
    path1.length === path2.length &&
    path1.every((coord, i) => positionEquals(coord, path2[i]))
  );
}

export const markerIcons: Record<JOBSTATUS | -1, string> = {
  [JOBSTATUS.DONE]: markerIconDone,
  [JOBSTATUS.IN_WORK]: markerIconInWork,
  [JOBSTATUS.DRIVING_TO_LOCATION]: markerIconOnRoute,
  [JOBSTATUS.PAUSE]: markerIconPause,
  [JOBSTATUS.OPEN]: markerIconOpen,
  [-1]: markerIconDefault,
};
