import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import {
  Edit,
  Engineering,
  Menu,
  PhotoSizeSelectSmall,
  Spa,
  TextSnippet,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { getServicesInitial } from "Store/services";
import { UpdateDialog } from "./components/updateDialog/updateDialog";
import { FIELDS } from "./helper";

interface IOverviewCard {
  job?: JobResponseBody;
}

export const OverviewCard: React.FC<IOverviewCard> = ({ job }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [fieldToUpdate, setFieldToUpdate] = useState<FIELDS | undefined>();

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const { listMeta } = useSelector((state: AppState) => state.services);

  useEffect(() => {
    dispatch(getServicesInitial(listMeta));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseUpdateModal = () => {
    setFieldToUpdate(undefined);
    setUpdateModalOpen(false);
  };
  const handleOpenUpdateModal = (field: FIELDS) => {
    setFieldToUpdate(field);
    setUpdateModalOpen(true);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <List>
          <ListSubheader>{job?.parcel.name}</ListSubheader>
          <ListItem disablePadding>
            <ListItemIcon>
              <PhotoSizeSelectSmall />
            </ListItemIcon>
            <ListItemText
              primary={
                (job?.parcel.size
                  ? convertAreaToCustom(job?.parcel.size, unitOfMeasurement)
                  : "-") +
                " " +
                UnitSymbol[unitOfMeasurement]
              }
              secondary={t("general.fields.area")}
            />
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemIcon>
              <Spa />
            </ListItemIcon>
            <ListItemText
              primary={job?.parcel.grapeVariety?.name ?? "-"}
              secondary={t("parcels.fields.grapeVariety")}
            />
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemIcon>
              <Menu />
            </ListItemIcon>
            <ListItemText
              primary={job?.parcel.rowAmount ?? "-"}
              secondary={t("general.fields.rowAmount")}
            />
          </ListItem>
          <ListSubheader>{t("general.service_one")}</ListSubheader>
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleOpenUpdateModal(FIELDS.SERVICE)}
              >
                <Edit fontSize="small" />
              </IconButton>
            }
          >
            <ListItemIcon>
              <Engineering />
            </ListItemIcon>
            <ListItemText primary={job?.name} />
          </ListItem>
          <ListSubheader>{t("general.fields.notes")}</ListSubheader>
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleOpenUpdateModal(FIELDS.NOTES)}
              >
                <Edit fontSize="small" />
              </IconButton>
            }
          >
            <ListItemIcon>
              <TextSnippet />
            </ListItemIcon>
            <Tooltip title={job?.notes || "-"}>
              <ListItemText
                disableTypography
                sx={{
                  overflow: "hidden",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  maxWidth: 220,
                  textOverflow: "ellipsis",
                }}
                primary={job?.notes || "-"}
              ></ListItemText>
            </Tooltip>
          </ListItem>
        </List>
      </CardContent>
      <UpdateDialog
        open={updateModalOpen}
        onClose={handleCloseUpdateModal}
        fieldToEdit={fieldToUpdate}
        job={job}
      />
    </Card>
  );
};
