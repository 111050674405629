import { Lock, LockOpen, MoreVert, PendingActions } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { ROUTES } from "Constants";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";

type EventMenuProps = {
  eventId: number;
  orderId: number | undefined;
  locked: boolean;
  disabled: boolean;
};

export const EventMenu: React.FC<EventMenuProps> = ({
  eventId,
  orderId,
  locked,
  disabled,
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const openOrderDetails = useCallback(
    () => void navigate(`${ROUTES.MAIN.ORDERS}/${orderId}`),
    [orderId, navigate],
  );

  const lock = useCallback(
    () => {
      handleClose();
      dispatch(
        sagaActions.updateEvent({ id: eventId, update: { locked: true } }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId],
  );

  const unlock = useCallback(
    () => {
      handleClose();
      dispatch(
        sagaActions.updateEvent({ id: eventId, update: { locked: false } }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventId],
  );

  return (
    <>
      <IconButton
        sx={{ position: "absolute", top: 1, right: 1 }}
        size="small"
        onClick={handleClick}
        ref={buttonRef}
      >
        <MoreVert
          sx={(theme) => ({ color: theme.palette.primary.contrastText })}
        />
      </IconButton>
      <Menu
        id={`event-menu-${eventId}`}
        anchorEl={buttonRef.current}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        container={fullscreenContainer}
      >
        <MenuItem disabled={disabled} onClick={locked ? unlock : lock}>
          <ListItemIcon>{locked ? <LockOpen /> : <Lock />}</ListItemIcon>
          <ListItemText
            primary={
              locked
                ? t("planningV2.buttons.unlock")
                : t("planningV2.buttons.lock")
            }
          />
        </MenuItem>
        <Divider />
        <MenuItem disabled={disabled} onClick={openOrderDetails}>
          <ListItemIcon>
            <PendingActions />
          </ListItemIcon>
          <ListItemText primary={t("general.labels.details")} />
        </MenuItem>
      </Menu>
    </>
  );
};
