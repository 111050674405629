import { CustomerOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type GetTableDataParams, type InitialScreenMetaType } from "Types";
import {
  CUSTOMER_PERSONS_ACTION_TYPES,
  type CustomersAction,
} from "./action-types";

type CustomerEmployeesStateType = {
  customerId: number;
  list: CustomerOutside["persons"];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  listUpdateLoading: boolean;
  hiddenColumns: Record<string, boolean>;
  searchData?: SearchSpec;
  columnsOrder?: number[];
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: CustomerEmployeesStateType = {
  customerId: -1,
  list: [],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  searchData: undefined,
  columnsOrder: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  hiddenColumns: {},
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const customerEmployeesReducer = (
  state = initialState,
  action: CustomersAction,
): CustomerEmployeesStateType => {
  switch (action.type) {
    case CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_SUCCESS:
      return {
        ...state,
        customerId: action.payload.customerId,
        list: action.payload.customerPersons,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_REQUEST:
    case CUSTOMER_PERSONS_ACTION_TYPES.DELETE_CUSTOMER_PERSON:
      return {
        ...state,
        listUpdateLoading: true,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_SUCCESS:
      return {
        ...state,
        list: action.payload.customerPersons,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        listUpdateLoading: false,
        searchData: action.payload.search,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    case CUSTOMER_PERSONS_ACTION_TYPES.RESET_STATE:
      return {
        ...initialState,
        hiddenColumns: state.hiddenColumns,
      };
    default:
      return state;
  }
};
