import { MACHINEALIGNMENT, MACHINEKIND } from "@ero/app-common/enums";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OptionType } from "Types";

type MachineDropdownOptions = {
  kindOptions: OptionType[];
  statusOptions: OptionType[];
  alignmentOptions: OptionType[];
  connectivityOptions: OptionType[];
};

export const useMachineDropdownOptions = (): MachineDropdownOptions => {
  const [t] = useTranslation();

  const options = useMemo(
    () => ({
      kindOptions: [
        { value: MACHINEKIND.TRACTOR, label: t("machines.kind.tractor") },
        {
          value: MACHINEKIND.ATTACHMENT,
          label: t("machines.kind.attachment"),
        },
        {
          value: MACHINEKIND.HANDHELD,
          label: t("machines.kind.handheld"),
        },
        { value: MACHINEKIND.OTHER, label: t("machines.kind.other") },
        {
          value: MACHINEKIND.HARVESTER,
          label: t("machines.kind.harvester"),
        },
      ],
      statusOptions: [
        { value: 0, label: t("machines.state.broken") },
        { value: 1, label: t("machines.state.active") },
      ],
      alignmentOptions: [
        {
          value: MACHINEALIGNMENT.FRONT,
          label: t("machines.alignment.front"),
        },
        {
          value: MACHINEALIGNMENT.BACK,
          label: t("machines.alignment.back"),
        },
        {
          value: MACHINEALIGNMENT.MIDDLE,
          label: t("machines.alignment.between"),
        },
      ],
      connectivityOptions: [
        { value: 1, label: t("machines.status.moving") },
        { value: 2, label: t("machines.status.notMoving") },
      ],
    }),
    [t],
  );

  return options;
};
