import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CalendarEventColor } from "Enums";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
interface IEventColorToggle {
  onEventColorChange?: (value: CalendarEventColor) => void;
  showEventColorSwitchers?: boolean;
  appointmentColorization: CalendarEventColor;
}

export const EventColorToggle: React.FC<IEventColorToggle> = ({
  onEventColorChange,
  showEventColorSwitchers,
  appointmentColorization,
}) => {
  const [t] = useTranslation();

  if (!showEventColorSwitchers) {
    return null;
  }

  const handleColorizationChange = (event, colorization) => {
    if (colorization && onEventColorChange) onEventColorChange(colorization);
  };

  return (
    <>
      <Typography variant="subtitle1">
        {t("components.calendar.settingsMenu.colorsHeadline")}
      </Typography>
      <ToggleButtonGroup
        value={appointmentColorization}
        exclusive
        onChange={handleColorizationChange}
      >
        <ToggleButton value={CalendarEventColor.SingleColor}>
          {t("components.calendar.settingsMenu.monocromaticColor")}
        </ToggleButton>
        <ToggleButton value={CalendarEventColor.Status}>
          {t("components.calendar.settingsMenu.statusColors")}
        </ToggleButton>
        <ToggleButton value={CalendarEventColor.GrapeColor}>
          {t("components.calendar.settingsMenu.parcelTypeColors")}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
