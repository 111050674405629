import { ServiceOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import {
  defaultHiddenColumns,
  defaultHiddenColumnsCreateOrder,
} from "Screens/services/tableConfig";
import {
  type ColumnFilterDataType,
  type GetTableDataParams,
  type InitialScreenMetaType,
} from "Types";
import { SERVICES_ACTION_TYPES, type ServicesAction } from "./action-types";

export enum TABLE_TYPES {
  SERVICES_TABLE = "servicesTable",
  CREATE_ORDER_SERVICES_TABLE = "createOrderParcelsTable",
}

export type ServicesStateType = {
  list: ServiceOutside[];
  maxCount: number;
  listMeta: GetTableDataParams;
  initialMeta: InitialScreenMetaType;
  hiddenColumns: {
    [tableId: string]: GridColumnVisibilityModel;
  };
  searchData?: SearchSpec;
  columnsOrder?: number[];
  filters?: ColumnFilterDataType[];
  listUpdateLoading: boolean;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: ServicesStateType = {
  list: [] as ServiceOutside[],
  maxCount: 0,
  listMeta: {
    pageLength: 50,
    page: 0,
  },
  searchData: undefined,
  columnsOrder: undefined,
  filters: undefined,
  hiddenColumns: {
    [TABLE_TYPES.SERVICES_TABLE]: defaultHiddenColumns,
    [TABLE_TYPES.CREATE_ORDER_SERVICES_TABLE]: defaultHiddenColumnsCreateOrder,
  },
  initialMeta: {
    loading: true,
    error: false,
  },
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const servicesReducer = (
  state = initialState,
  action: ServicesAction,
) => {
  switch (action.type) {
    case SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_REQUEST:
      return {
        ...state,
        initialMeta: {
          loading: true,
          error: false,
        },
      };
    case SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_SUCCESS:
      return {
        ...state,
        list: action.payload.services,
        maxCount: action.payload.maxCount,
        initialMeta: {
          loading: false,
          error: false,
        },
      };
    case SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_ERROR:
      return {
        ...state,
        initialMeta: {
          loading: false,
          error: true,
        },
      };
    case SERVICES_ACTION_TYPES.GET_SERVICES_REQUEST:
    case SERVICES_ACTION_TYPES.DELETE_SERVICES:
      return {
        ...state,
        listUpdateLoading: true,
      };
    case SERVICES_ACTION_TYPES.GET_SERVICES_SUCCESS:
      return {
        ...state,
        list: action.payload.services,
        maxCount: action.payload.maxCount,
        listMeta: action.payload.listMeta,
        searchData: action.payload.search,
        filters: action.payload.filters,
        listUpdateLoading: false,
      };
    case SERVICES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case SERVICES_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case SERVICES_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case SERVICES_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };
    case SERVICES_ACTION_TYPES.RESET_SEARCH:
      return {
        ...state,
        searchData: undefined,
      };
    case SERVICES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS: {
      const { tableId, hiddenColumns } = action.payload;
      return {
        ...state,
        hiddenColumns: {
          ...state.hiddenColumns,
          [tableId]: hiddenColumns,
        },
      };
    }
    case SERVICES_ACTION_TYPES.CHANGE_COLUMNS_ORDER:
      return {
        ...state,
        columnsOrder: action.payload.order,
      };
    default:
      return state;
  }
};
