import { useCallback } from "react";
import { type CalendarProps } from "../types";

const noop = () => {};

export const useEventClick = (props: CalendarProps) => {
  const { onEventClick = noop } = props;

  const handleEventClick = useCallback(
    ({ event, jsEvent }) => {
      onEventClick(event.extendedProps.originalItem, event, jsEvent);
    },
    [onEventClick],
  );

  return handleEventClick;
};
