import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { Input } from "Components";
import { addDropdown, removeDropdown } from "Store/organization";
import { Form, Formik, FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

interface IListItems {
  type: string;
  data?: { name: string; _id: number }[];
}

const initialValues = {
  entry: "",
};

export const ListItems: React.FC<IListItems> = ({ type, data }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleDelete = (key: string, _id: number) =>
    dispatch(removeDropdown({ key, _id }));

  const onSubmit = (values: FormikValues) => {
    dispatch(
      addDropdown({
        key: type,
        name: values.entry,
        meta: {},
      }),
    );
  };

  const validationSchema = Yup.object({
    entry: Yup.string().min(1, t("validationErrors.fieldRequired")),
  });

  return (
    <>
      <Stack
        sx={{
          overflow: "auto",
          maxHeight: 250,
          pr: 4,
        }}
        spacing={2}
        mb={2}
      >
        <List>
          {data?.map((item) => (
            <ListItem
              key={item._id}
              divider
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete(type, item._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Divider />
      <Box mt={2} display="flex" flexDirection="column">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Input
              name="entry"
              size="small"
              placeholder={t("general.buttons.newEntry")}
              mandatory
            />
            <LoadingButton type="submit">
              {t("general.buttons.add")}
            </LoadingButton>
          </Form>
        </Formik>
      </Box>
    </>
  );
};
