import { FilterListOutlined } from "@mui/icons-material";
import { Badge, Box, IconButton, Popover } from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { FilterForm } from "./form";
import { initialValues, validationSchema, type ValuesType } from "./formConfig";

interface IProps {
  ref?: any;
  onSubmit: (values: ValuesType) => void;
  onReset: () => void;
  filterCount: number;
}

const FilterPopup: React.FC<IProps> = forwardRef(
  ({ onSubmit, onReset, filterCount }, ref) => {
    const formRef = useRef<FormikProps<ValuesType>>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      },
      [],
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    return (
      <>
        <Badge
          badgeContent={filterCount !== 0 ? filterCount : undefined}
          color="primary"
          sx={{ height: "100%" }}
        >
          <IconButton onClick={handleClick} color="error">
            <FilterListOutlined />
          </IconButton>
        </Badge>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box width={300}>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              ref={ref}
              innerRef={formRef}
              onSubmit={(e) => {
                onSubmit(e);
                handleClose();
              }}
              onReset={() => {
                onReset();
                handleClose();
              }}
              component={FilterForm}
            ></Formik>
          </Box>
        </Popover>
      </>
    );
  },
);

FilterPopup.displayName = "PlanningSidebarFilterPopup";

export { FilterPopup };
