import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";

type DateSelectProps = {
  onDateChange: (date: Date) => void;
};

export const DateSelect: React.FC<DateSelectProps> = ({ onDateChange }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const date = useSelector((state: AppState) => state.planningV2.date);

  const dayJsValue = useMemo(() => dayjs(date), [date]);

  const showingToday = useMemo(
    () => dayJsValue.startOf("day").unix() === dayjs().startOf("day").unix(),
    [dayJsValue],
  );

  const onChange = useCallback((dayJsValue: Dayjs | null) => {
    if (dayJsValue) {
      dispatch(sagaActions.setDate(dayJsValue.unix() * 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = useCallback(
    () =>
      dispatch(
        sagaActions.setDate(dayJsValue.subtract(1, "day").unix() * 1000),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dayJsValue],
  );

  const goForward = useCallback(
    () => dispatch(sagaActions.setDate(dayJsValue.add(1, "day").unix() * 1000)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dayJsValue],
  );

  const goToToday = useCallback(() => {
    dispatch(sagaActions.setDate(Date.now()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // See https://github.com/fullcalendar/fullcalendar/issues/7448
    setTimeout(() => onDateChange(dayJsValue.toDate()), 100);
  }, [dayJsValue, onDateChange]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <IconButton onClick={goBack}>
        <ChevronLeft />
      </IconButton>
      <DatePicker
        value={dayJsValue}
        onChange={onChange}
        slotProps={{
          textField: {
            size: "small",
            variant: "standard",
          },
          popper: {
            container: fullscreenContainer,
          },
        }}
        views={["year", "month", "day"]}
        format="dddd, DD. MMM YYYY"
      />
      <IconButton onClick={goForward}>
        <ChevronRight />
      </IconButton>
      <Button onClick={goToToday} disabled={showingToday}>
        {t("planningV2.buttons.today")}
      </Button>
    </Stack>
  );
};
