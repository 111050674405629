import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const BottomControls: React.FC<PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      height: "fit-content",
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      bottom: 25,
      right: 10,
      gridGap: 10,
    }}
  >
    {children}
  </Box>
);
