import { EventResponseBody } from "@ero/app-common/v2/routes/models/event";
import { Box, Skeleton, Tooltip } from "@mui/material";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import React, { useCallback, useMemo, useState } from "react";
import { EventContent } from "../../../eventContent";
import { EventMenu } from "./components/eventMenu";

type EventProps = {
  event: EventResponseBody;
  start: Date | null;
  end: Date | null;
  loading: boolean;
  selected: boolean;
};

export const Event: React.FC<EventProps> = ({
  event,
  start,
  end,
  loading,
  selected,
}) => {
  const { fullscreenContainer } = useFullscreenContext();

  const [eventDimensions, setEventDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 9999, height: 9999 });

  const showTooltip = useMemo(
    () => eventDimensions.height < 150,
    [eventDimensions],
  );

  const eventContentRefChanged = useCallback((elem: HTMLDivElement | null) => {
    if (elem) {
      setEventDimensions({
        width: elem.clientWidth,
        height: elem.clientHeight,
      });
    }
  }, []);

  if (loading) {
    return (
      <Box sx={{ height: "100%", width: "100%", p: 1, overflowY: "hidden" }}>
        <Skeleton animation="wave" sx={{ fontSize: "1rem" }} />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Box>
    );
  }

  return (
    <>
      <EventMenu
        eventId={event._id}
        orderId={event.order?._id}
        locked={event.locked ?? false}
        disabled={selected}
      />
      <Tooltip
        title={
          showTooltip ? (
            <EventContent
              eventLocked={event.locked}
              orderInfo={event.order}
              start={start}
              end={end}
              showAllInfo
            />
          ) : (
            ""
          )
        }
        sx={{
          height: "100%",
          width: "100%",
        }}
        slotProps={{
          popper: {
            container: fullscreenContainer,
          },
        }}
        arrow
        ref={eventContentRefChanged}
      >
        <EventContent
          eventLocked={event.locked}
          orderInfo={event.order}
          start={start}
          end={end}
          containerHeight={eventDimensions.height}
          containerWidth={eventDimensions.width}
          selected={selected}
        />
      </Tooltip>
    </>
  );
};
