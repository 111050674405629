import { MoreHoriz } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { type CalendarEventColor } from "Enums";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventColorToggle } from "./components/eventColorToggle";
import { HelpButton } from "./components/helpButton";
import { RowScale } from "./components/rowScale";

interface IMenu {
  menuOptions?: React.ReactElement[];
  onEventColorChange?: (value: CalendarEventColor) => void;
  onHelpBtnClick?: () => void;
  onRowScaleChange?: (scale: number) => void;
  rowScaleValue?: number;
  showEventColorSwitchers?: boolean;
  showHelpBtn?: boolean;
  showRowScale?: boolean;
  appointmentColorization: CalendarEventColor;
}

export const Menu: React.FC<IMenu> = ({
  menuOptions = [],
  onEventColorChange,
  onHelpBtnClick,
  onRowScaleChange,
  rowScaleValue,
  showEventColorSwitchers,
  showHelpBtn,
  showRowScale,
  appointmentColorization,
}) => {
  const wrappeRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div ref={wrappeRef}>
      <Fab size="small" sx={{ marginTop: "4px" }} onClick={handleClick}>
        <MoreHoriz />
      </Fab>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box padding={2}>
          <Box>
            <HelpButton
              showHelpBtn={showHelpBtn}
              onHelpBtnClick={onHelpBtnClick}
            />
          </Box>
          <Box>
            <EventColorToggle
              showEventColorSwitchers={showEventColorSwitchers}
              onEventColorChange={onEventColorChange}
              appointmentColorization={appointmentColorization}
            />
          </Box>
          <Box mt={1}>
            <Typography variant="subtitle1">
              {t("components.calendar.settingsMenu.zoomfactor")}
            </Typography>
          </Box>
          <Box pl={2} pr={2}>
            <RowScale
              showRowScale={showRowScale}
              rowScaleValue={rowScaleValue}
              onRowScaleChange={onRowScaleChange}
            />
          </Box>
          {menuOptions.map((item, index) => (
            <Box key={index}>{item}</Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
};
