import { VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import * as Yup from "yup";

export type ValuesType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const initialValues: ValuesType = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    oldPassword: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
    newPassword: Yup.string()
      .required(VALIDATION_ERRORS.REQUIRED)
      .matches(VALIDATION_REGEX.password, {
        message: VALIDATION_ERRORS.NO_SAFE_PASSWORD,
      }),
    confirmPassword: Yup.string()
      .required(VALIDATION_ERRORS.REQUIRED)
      .matches(VALIDATION_REGEX.password, {
        message: VALIDATION_ERRORS.NO_SAFE_PASSWORD,
      })
      .oneOf(
        [Yup.ref("newPassword"), ""],
        VALIDATION_ERRORS.NOT_MATCHED_PASSWORD,
      ),
  });
