import { PickBySpec } from "@ero/app-common/util/Spec";
import { type MachineType } from "Types";

export const machinesSpec = [
  ["internalId"],
  ["name"],
  ["kind"],
  ["model"],
  ["licensePlate"],
  ["features"],
  ["intact"],
  ["notes"],
  ["tuv"],
  ["manufacturer"],
  ["serialNumber"],
  ["avatar"],
  ["performance"],
  ["width"],
  ["height"],
  ["length"],
  ["workingWidth"],
  ["alignment"],
  ["nextMaintenance"],
  ["maintenanceInfo"],
  ["loadingCapacity"],
] as const;

export type LocalMachineType = PickBySpec<MachineType, typeof machinesSpec>;
