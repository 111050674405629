import { type Action } from "redux";
import { type JobMessage } from "Types";

export enum GENERAL_ACTION_TYPES {
  OPEN_WEB_SOCKET = "general/open-web-socket",
  CLOSE_WEB_SOCKET = "general/close-web-socket",
  CHANGE_WS_SUBSCRIPTION = "general/change-ws-subscription",
  UPDATE_JOB_LIVE = "general/update-job-live",
}

export interface IOpenWs extends Action {
  type: GENERAL_ACTION_TYPES.OPEN_WEB_SOCKET;
}

export interface ICloseWs extends Action {
  type: GENERAL_ACTION_TYPES.CLOSE_WEB_SOCKET;
}

export interface IChangeWsSubsctiption extends Action {
  type: GENERAL_ACTION_TYPES.CHANGE_WS_SUBSCRIPTION;
  payload: { subscriptionMessage: string };
}

export interface IUpdateJobLive extends Action {
  type: GENERAL_ACTION_TYPES.UPDATE_JOB_LIVE;
  payload: { message: JobMessage };
}
