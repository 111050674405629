import { JobTelemetryResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import {
  ORDER_DETAILS_ACTION_TYPES,
  type OrderDetailsAction,
} from "./action-types";

export type OrderDetailsStateType = {
  order: OrderResponseBody | undefined;
  success: boolean;
  loading: boolean;
  error: boolean;
  jobUpdating: boolean;
  jobTelemetry: JobTelemetryResponseBody;
  jobTelemetryStatus: {
    success: boolean;
    loading: boolean;
    error: boolean;
  };
};

const initialState: OrderDetailsStateType = {
  order: undefined,
  success: false,
  loading: false,
  error: false,
  jobUpdating: false,
  jobTelemetry: {} as JobTelemetryResponseBody,
  jobTelemetryStatus: { success: false, loading: false, error: false },
};

export const orderDetailsReducer = (
  state = initialState,
  action: OrderDetailsAction,
): OrderDetailsStateType => {
  switch (action.type) {
    case ORDER_DETAILS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };
    case ORDER_DETAILS_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
      };
    case ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        loading: false,
        success: true,
      };
    case ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        loading: false,
        success: true,
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY:
      return {
        ...state,
        jobTelemetry: action.payload.data,
        jobTelemetryStatus: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_LOADING:
      return {
        ...state,
        jobTelemetryStatus: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_SUCCESS:
      return {
        ...state,
        jobTelemetryStatus: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_ERROR:
      return {
        ...state,
        jobTelemetryStatus: {
          success: false,
          loading: false,
          error: true,
        },
      };

    case ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB:
      return { ...state, jobUpdating: true };
    case ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        jobUpdating: false,
        order: state.order
          ? {
              ...state.order,
              jobDetails: {
                ...(state.order?.jobDetails ?? {
                  jobCount: 0,
                  jobCountOpen: 0,
                  jobCountDrivingToLocation: 0,
                  jobCountInWork: 0,
                  jobCountPause: 0,
                  jobCountDone: 0,
                  durationSum: 0,
                  durationSumDone: 0,
                  parcelCount: 0,
                  parcelSize: 0,
                  serviceCount: 0,
                  gemarkungen: [],
                  grapeVarieties: [],
                  regionettes: [],
                  services: [],
                }),
                jobs:
                  state.order?.jobDetails?.jobs.map((job) => {
                    if (job._id === action.payload._id)
                      return { ...job, ...action.payload };
                    else {
                      return job;
                    }
                  }) ?? [],
              },
            }
          : undefined,
      };
    case ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_ERROR:
      return { ...state, jobUpdating: false, error: true };
    case ORDER_DETAILS_ACTION_TYPES.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
