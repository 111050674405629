import { Info } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { POLYGON_EDIT_MODE } from "./utils";

interface IInfoText {
  editMode: POLYGON_EDIT_MODE;
}

export const InfoText: React.FC<IInfoText> = ({ editMode }) => {
  const [infoAlert, setInfoAlert] = useState<{
    isDisplayed: boolean;
    text: string | undefined;
  }>({ isDisplayed: false, text: undefined });
  const [t] = useTranslation();

  useEffect(() => {
    switch (editMode) {
      case POLYGON_EDIT_MODE.NONE:
        setInfoAlert({
          text: undefined,
          isDisplayed: false,
        });
        break;
      case POLYGON_EDIT_MODE.MERGE:
        setInfoAlert({
          text: t("parcels.upload.infoLabels.selectParcels"),
          isDisplayed: true,
        });
        break;
      case POLYGON_EDIT_MODE.SPLIT:
        setInfoAlert({
          text: t("parcels.upload.infoLabels.moveMarkers"),
          isDisplayed: true,
        });
    }
  }, [editMode, t]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ pointerEvents: "none", position: "absolute", top: 15, left: 10 }}
    >
      {infoAlert.isDisplayed && (
        <Chip
          size="small"
          color="info"
          icon={<Info />}
          label={infoAlert.text}
        />
      )}
    </Stack>
  );
};
