import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback } from "react";

export const ServiceCheckbox: React.FC<{
  label: string;
  isChecked?: boolean;
  onChange: (checked: boolean) => void;
}> = ({ label, isChecked = false, onChange }) => {
  const handleChange = useCallback(
    (event) => {
      if (onChange) onChange(event.target.checked);
    },
    [onChange],
  );

  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleChange} />}
      label={label}
    />
  );
};
