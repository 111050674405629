import { LinearProgress } from "@mui/material";
import React from "react";

export const DashboardLoader: React.FC<{ loading: boolean }> = ({
  loading,
}) => {
  return (
    <>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        />
      )}
    </>
  );
};
