import { LocationSearching } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICenterControlV2 {
  onCenter: () => void;
}

export const CenterControlV2: React.FC<ICenterControlV2> = ({ onCenter }) => {
  const [t] = useTranslation();

  return (
    <Tooltip title={t("components.map.controlButtons.center") as string}>
      <Fab onClick={onCenter}>
        <LocationSearching />
      </Fab>
    </Tooltip>
  );
};
