import * as Yup from 'yup';
import { VALIDATION_ERRORS, MAX_LENGTH } from 'Constants';

export const getValidationSchema = () =>
  Yup.object().shape({
    flurstuecksnummer: Yup.string()
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .required(VALIDATION_ERRORS.REQUIRED),
    size: Yup.number().positive(VALIDATION_ERRORS.POSITIVE_NUMBER),
  });
