import { Checkbox } from "@mui/material";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

export const JobCheckbox: React.FC<{
  parcel: number;
  service: number;
  index: number;
}> = ({ parcel, service, index }) => {
  const [field, _, helpers] = useField<
    | {
        parcel: number;
        service: number;
        create: boolean;
      }
    | undefined
  >(`jobs.${index}`);
  const [value, setValue] = useState<{
    parcel: number;
    service: number;
    create: boolean;
  }>({ parcel, service, create: !!field.value?.create });

  const onChange = (_, value) => {
    helpers.setValue({ parcel, service, create: value });
  };

  useEffect(() => {
    if (field.value) setValue(field.value);
  }, [field.value]);

  return (
    <Checkbox
      name={field.name}
      value={value.create}
      checked={value.create}
      onBlur={field.onBlur}
      onChange={onChange}
    />
  );
};
