export {
  ERR as ApiError,
  JOBSTATUS as JobStatus,
  OrderStatus as OrderStatus,
  TELEMETRY_DEVICE as TelemetryDevice,
  TELEMATICS as TelemetryType,
  MessageType as WsMessageType,
} from "@ero/app-common/enums";

export enum JobStatusColor {
  OPEN = "status.open",
  DONE = "status.done",
  DRIVING_TO_LOCATION = "status.driving",
  IN_WORK = "status.inwork",
  PAUSE = "status.pause",
}

export enum CalendarView {
  MONTH = "dayGridMonth",
  WEEK = "resourceTimeGridWeek",
  DAY = "resourceTimeGridDay",
  TIMELINE = "listWeek",
}

export enum CalendarEventColor {
  SingleColor = "singlecolor",
  Status = "status",
  GrapeColor = "grapecolor",
}

export enum ParcelKindColor {
  RED = "#800032",
  WHITE = "#ECF9C0",
}

export enum CalendarSlotDuration {
  FIVE_MINUTES = 0,
  FIVETEEN_MINUTES = 1,
  THIRTY_MINUTES = 2,
  ONE_HOUR = 3,
}
