import { type APISpec } from "@ero/app-common/routes/enforcer";
import { SendImportLinkRequestBody } from "@ero/app-common/v2/routes/models/import";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import {
  ImportParcelsRequest,
  RequestBody,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  IImportParcels,
  IResetSearchAction,
  ISendImportLink,
  PARCELS_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreateParcelAction,
  type IDeleteParcelsAction,
  type IGetInitialParcelsErrorAction,
  type IGetInitialParcelsRequestAction,
  type IGetInitialParcelsSuccessAction,
  type IGetParcelAction,
  type IGetParcelSuccessAction,
  type IGetParcelsRequestAction,
  type IGetParcelsSuccessAction,
  type IResetMetaAction,
  type IResetParcelAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateParcelAction,
} from "./action-types";
import { type LocalParcelType, type LocalViewParcelType } from "./specs";

export const setLoading = (): ISetLoadingAction => ({
  type: PARCELS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: PARCELS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: PARCELS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: PARCELS_ACTION_TYPES.RESET_META,
});

export const resetSearch = (): IResetSearchAction => ({
  type: PARCELS_ACTION_TYPES.RESET_SEARCH,
});

export const resetParcel = (): IResetParcelAction => ({
  type: PARCELS_ACTION_TYPES.RESET_PARCEL,
});

export const getParcelsInitial = (
  params: GetTableDataParams,
): IGetInitialParcelsRequestAction => {
  return {
    type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_REQUEST,
    payload: { params },
  };
};

export const getParcelsInitialSuccess = (
  parcels: LocalParcelType[],
  maxCount: number,
  lastParcelId: number,
): IGetInitialParcelsSuccessAction => ({
  type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_SUCCESS,
  payload: {
    parcels,
    maxCount,
    lastParcelId,
  },
});

export const getParcelsInitialError = (): IGetInitialParcelsErrorAction => ({
  type: PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_ERROR,
});

export const createParcel = (
  data: RequestBody<"/parcels/create">,
): ICreateParcelAction => ({
  type: PARCELS_ACTION_TYPES.CREATE_PARCEL,
  payload: { data },
});

export const updateParcel = (
  updateData: APISpec["/parcels/update"]["body"],
): IUpdateParcelAction => ({
  type: PARCELS_ACTION_TYPES.UPDATE_PARCEL,
  payload: { updateData },
});

export const getParcel = (id: number): IGetParcelAction => ({
  type: PARCELS_ACTION_TYPES.GET_PARCEL_REQUEST,
  payload: { id },
});

export const deleteParcels = (ids: number[]): IDeleteParcelsAction => ({
  type: PARCELS_ACTION_TYPES.DELETE_PARCELS,
  payload: { ids },
});

export const getParcels = ({
  params,
  search,
  filters,
}: GetTableEntitiesType): IGetParcelsRequestAction => ({
  type: PARCELS_ACTION_TYPES.GET_PARCELS_REQUEST,
  payload: { params, search, filters },
});

export const getParcelsSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
  filters,
  lastId,
}: SetTableEntityType<LocalParcelType>): IGetParcelsSuccessAction => ({
  type: PARCELS_ACTION_TYPES.GET_PARCELS_SUCCESS,
  payload: {
    parcels: list,
    maxCount,
    listMeta,
    search,
    filters,
    lastId: lastId || 0,
  },
});

export const getParcelSuccess = ({
  parcel,
}: {
  parcel: LocalViewParcelType;
}): IGetParcelSuccessAction => ({
  type: PARCELS_ACTION_TYPES.GET_PARCEL_SUCCESS,
  payload: { parcel },
});

export const setTableHiddenColumns = (
  tableId: string,
  hiddenColumns: GridColumnVisibilityModel,
): ISetTableHiddenColumns => ({
  type: PARCELS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { tableId, hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: PARCELS_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});

export const importParcels = (data: ImportParcelsRequest): IImportParcels => ({
  type: PARCELS_ACTION_TYPES.IMPORT,
  payload: { data },
});

export const sendImportLink = (data: SendImportLinkRequestBody): ISendImportLink => ({
  type: PARCELS_ACTION_TYPES.SEND_IMPORT_LINK,
  payload: { data },
});
