import { EventsResponseBody } from "@ero/app-common/v2/routes/models/event";
import { OrdersResponseBody } from "@ero/app-common/v2/routes/models/order";
import { UsersResponseBody } from "@ero/app-common/v2/routes/models/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarSlotDuration } from "Enums";
import { OptionType } from "Types";

export type FilterType = {
  customer: string[];
  service: string[];
  grapeVariety: string[];
  grapeVarietyColor: string | undefined;
  regionette: string[];
  mark: string[];
};

export type FilterOptions = {
  customers: OptionType[];
  services: OptionType[];
  grapeVarieties: OptionType[];
  grapeVarietyColors: OptionType[];
  regionettes: OptionType[];
  gemarkungen: OptionType[];
};

type PlanningV2State = {
  date: number;
  users: UsersResponseBody["data"];
  loadingUsers: boolean;
  selectedUsers: number[] | undefined;
  sortedUsers: number[];
  orders: OrdersResponseBody<number[]>["data"];
  loadingOrders: boolean;
  events: EventsResponseBody["data"];
  loadingEvents: boolean;
  filters: FilterType;
  filtersOptions: FilterOptions;
  slotDuration: CalendarSlotDuration;
};

const initialState: PlanningV2State = {
  date: Date.now(),
  users: [],
  loadingUsers: false,
  selectedUsers: undefined,
  sortedUsers: [],
  orders: [],
  loadingOrders: false,
  events: [],
  loadingEvents: false,
  filters: {
    customer: [],
    grapeVariety: [],
    grapeVarietyColor: undefined,
    mark: [],
    regionette: [],
    service: [],
  },
  filtersOptions: {
    customers: [],
    services: [],
    grapeVarieties: [],
    grapeVarietyColors: [],
    regionettes: [],
    gemarkungen: [],
  },
  slotDuration: CalendarSlotDuration.FIVETEEN_MINUTES,
};

type SetLoadingUsersAction = PayloadAction<boolean>;
type SetUsersAction = PayloadAction<UsersResponseBody["data"]>;
type SetSelectedUsersAction = PayloadAction<number[]>;
type SetSortedUsersAction = PayloadAction<number[]>;
type SetDateAction = PayloadAction<number>;
type SetOrdersAction = PayloadAction<OrdersResponseBody<number[]>["data"]>;
type SetLoadingOrdersAction = PayloadAction<boolean>;
type SetEventsAction = PayloadAction<EventsResponseBody["data"]>;
type SetLoadingEventsAction = PayloadAction<boolean>;
type SetFiltersAction = PayloadAction<FilterType>;
type SetFiltersOptionsAction = PayloadAction<FilterOptions>;
type SetSlotDurationAction = PayloadAction<CalendarSlotDuration>;

export const planningV2Slice = createSlice({
  name: "planningV2",
  initialState,
  reducers: {
    setLoadingUsers: (state, action: SetLoadingUsersAction) => {
      state.loadingUsers = action.payload;
    },
    setUsers: (state, action: SetUsersAction) => {
      state.users = action.payload;
      state.loadingUsers = false;
    },
    setSelectedUsers: (state, action: SetSelectedUsersAction) => {
      state.selectedUsers = action.payload;
    },
    setSortedUsers: (state, action: SetSortedUsersAction) => {
      state.sortedUsers = action.payload;
    },
    setDate: (state, action: SetDateAction) => {
      state.date = action.payload;
    },
    setLoadingOrders: (state, action: SetLoadingOrdersAction) => {
      state.loadingOrders = action.payload;
    },
    setOrders: (state, action: SetOrdersAction) => {
      state.orders = action.payload;
      state.loadingOrders = false;
    },
    setLoadingEvents: (state, action: SetLoadingEventsAction) => {
      state.loadingEvents = action.payload;
    },
    setEvents: (state, action: SetEventsAction) => {
      state.events = action.payload;
      state.loadingEvents = false;
    },
    setFilters: (state, action: SetFiltersAction) => {
      state.filters = action.payload;
    },
    setFiltersOptions: (state, action: SetFiltersOptionsAction) => {
      state.filtersOptions = action.payload;
    },
    setSlotInterval: (state, action: SetSlotDurationAction) => {
      state.slotDuration = action.payload;
    },
  },
});
