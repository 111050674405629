import { PlotOutside } from "@ero/app-common/models";
import {
  RequestBody,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  ICreatePlotForParcelAction,
  IDeletePlotsOfParcelAction,
  PLOTS_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreatePlotAction,
  type IDeletePlotsAction,
  type IGetInitialPlotsErrorAction,
  type IGetInitialPlotsRequestAction,
  type IGetInitialPlotsSuccessAction,
  type IGetPlotsForParcelRequestAction,
  type IGetPlotsRequestAction,
  type IGetPlotsSuccessAction,
  type IResetMetaAction,
  type IResetStateAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdatePlotAction,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: PLOTS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: PLOTS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: PLOTS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: PLOTS_ACTION_TYPES.RESET_META,
});

export const getPlotsInitial = (
  fieldId: string,
  params: GetTableDataParams,
): IGetInitialPlotsRequestAction => {
  return {
    type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_REQUEST,
    payload: {
      fieldId,
      params,
    },
  };
};

export const getPlotsInitialSuccess = (
  plots: PlotOutside[],
  maxCount: number,
): IGetInitialPlotsSuccessAction => ({
  type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_SUCCESS,
  payload: {
    plots,
    maxCount,
  },
});

export const getPlotsInitialError = (): IGetInitialPlotsErrorAction => ({
  type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_ERROR,
});

export const createPlot = (
  data: RequestBody<"/plots/create">,
): ICreatePlotAction => ({
  type: PLOTS_ACTION_TYPES.CREATE_PLOT,
  payload: { data },
});

export const createPlotForParcel = (
  data: RequestBody<"/plots/create">,
): ICreatePlotForParcelAction => ({
  type: PLOTS_ACTION_TYPES.CREATE_PLOT_FOR_PARCEL,
  payload: { data },
});

export const updatePlot = (
  updateData: RequestBody<"/plots/update">,
  id: number,
): IUpdatePlotAction => ({
  type: PLOTS_ACTION_TYPES.UPDATE_PLOT,
  payload: { updateData, id },
});

export const deletePlots = (ids: number[]): IDeletePlotsAction => ({
  type: PLOTS_ACTION_TYPES.DELETE_PLOTS,
  payload: { ids },
});

export const deletePlotsOfParcel = (
  ids: number[],
): IDeletePlotsOfParcelAction => ({
  type: PLOTS_ACTION_TYPES.DELETE_PLOTS_OF_PARCEL,
  payload: { ids },
});

export const getPlots = ({
  params,
  search,
}: GetTableEntitiesType): IGetPlotsRequestAction => ({
  type: PLOTS_ACTION_TYPES.GET_PLOTS_REQUEST,
  payload: { params, search },
});

export const getPlotsForParcel = (
  id: number,
): IGetPlotsForParcelRequestAction => ({
  type: PLOTS_ACTION_TYPES.GET_PLOTS_FOR_PARCEL_REQUEST,
  payload: { id },
});

export const getPlotsSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
}: SetTableEntityType<PlotOutside>): IGetPlotsSuccessAction => ({
  type: PLOTS_ACTION_TYPES.GET_PLOTS_SUCCESS,
  payload: { plots: list, maxCount, listMeta, search },
});

export const resetState = (): IResetStateAction => ({
  type: PLOTS_ACTION_TYPES.RESET_STATE,
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
): ISetTableHiddenColumns => ({
  type: PLOTS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: PLOTS_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});
