import { CustomerOutside } from "@ero/app-common/models/Customer";
import { Button, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Grid2/Grid2";
import { ConfirmDialog, Input } from "Components";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { PersonModal } from "Components/personModal";
import { useStaticDropdownValues } from "Hooks/dropdownValues";
import { deleteCustomerPerson } from "Store/customerPersons/actions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PersonItem } from "./personItem";

interface IProps {
  persons: CustomerOutside["persons"];
  mainContactId?: number;
  editMode: boolean;
}

export const GeneralInformation: React.FC<IProps> = ({
  persons,
  mainContactId,
  editMode,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [isCustomerEmployeeModalOpen, setIsCustomerEmployeeModalOpen] =
    useState(false);
  const [personToUpdateId, setPersonToUpdateId] = useState<number>();
  const [personToDeleteId, setPersonToDeleteId] = useState<number>();
  const [expandedPerson, setExpandedPerson] = useState<number | undefined>(
    mainContactId,
  );

  const { values: categories, loading: loadingCategories } =
    useStaticDropdownValues("category");

  return (
    <>
      <Container maxWidth={editMode ? "xl" : "lg"}>
        <Grid2 container spacing={4}>
          <Grid2
            size={editMode ? 4 : 6}
            container
            spacing={2}
            alignContent={"flex-start"}
          >
            <Grid2 size={3}>
              <Input
                //in the UI it is shown as "Internal ID", but saved as 'label'
                //NOT internalId (the company-scoped counter)
                name="label"
                type="text"
                label={t("customers.fields.internalId")}
              />
            </Grid2>
            <Grid2 size={9}>
              <StaticSelectWithAutoComplete
                name="category"
                options={categories}
                label={t("customers.fields.category")}
                loading={loadingCategories}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="companyName"
                type="text"
                mandatory={true}
                label={t("customers.fields.name")}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="street"
                type="text"
                label={t("customers.fields.street")}
              />
            </Grid2>
            <Grid2 size={3}>
              <Input
                name="zipCode"
                type="text"
                label={t("customers.fields.zipCode")}
              />
            </Grid2>
            <Grid2 size={9}>
              <Input
                name="city"
                type="text"
                label={t("customers.fields.city")}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="phone"
                type="text"
                label={t("customers.fields.phone")}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="mobile"
                type="text"
                label={t("customers.fields.mobile")}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="email"
                type="email"
                label={t("general.fields.email")}
              />
            </Grid2>
          </Grid2>
          <Grid2 size={editMode ? 4 : 6}>
            <Input
              label={t("general.fields.notes")}
              multiline
              name="notes"
              rows={10}
            />
          </Grid2>
          {editMode && (
            <>
              <Grid2
                spacing={2}
                container
                size={4}
                alignContent={"flex-start"}
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
              >
                <Grid2 size={12}>
                  <Typography variant="h5">
                    {t("customers.fields.persons")}
                  </Typography>
                </Grid2>
                {persons.length === 0 && (
                  <Grid2 size={12}>
                    <Typography variant="h6">
                      {t("customers.noPersons")}
                    </Typography>
                    <Typography variant="body1">
                      {t("customers.noPersonsHint")}
                    </Typography>
                  </Grid2>
                )}
                {persons.map((p) => (
                  <Grid2 size={12} key={p._id}>
                    <PersonItem
                      person={p}
                      mainContact={p._id == mainContactId}
                      setPersonIdToUpdate={setPersonToUpdateId}
                      setPersonIdToDelete={setPersonToDeleteId}
                      expanded={p._id === expandedPerson}
                      setExpanded={setExpandedPerson}
                    />
                  </Grid2>
                ))}
                <Grid2 size={12}>
                  <Button
                    color="primary"
                    onClick={() => setIsCustomerEmployeeModalOpen(true)}
                  >
                    {t("general.buttons.add")}
                  </Button>
                </Grid2>
              </Grid2>
            </>
          )}
        </Grid2>
      </Container>
      <PersonModal
        isOpen={isCustomerEmployeeModalOpen || !!personToUpdateId}
        onClose={() => {
          setPersonToUpdateId(undefined);
          setIsCustomerEmployeeModalOpen(false);
        }}
        person={persons.find((p) => p._id == personToUpdateId)}
        mainContactId={mainContactId}
      />
      <ConfirmDialog
        isOpen={!!personToDeleteId}
        onApprove={() => {
          if (personToDeleteId) {
            dispatch(deleteCustomerPerson([personToDeleteId]));
            setPersonToDeleteId(undefined);
          }
        }}
        onClose={() => setPersonToDeleteId(undefined)}
        title={t("components.deleteDialog.title")}
        text={t("components.deleteDialog.textFirstPart", {
          count: 1,
        })}
        text_2={t("components.deleteDialog.textSecondPart")}
        positiveButtonText={t("components.deleteDialog.deleteButton")}
        negativeButtonText={t("components.deleteDialog.cancelButton")}
      />
    </>
  );
};
