import * as Yup from "yup";

export type ValuesType = {
  customer: string[];
  service: string[];
  grapeVariety: string[];
  grapeVarietyColor?: string;
  regionette: string[];
  mark: string[];
};

export const validationSchema = Yup.object<ValuesType>().shape({
  customer: Yup.array(Yup.string()),
  service: Yup.array(Yup.string()),
  grapeVariety: Yup.array(Yup.string()),
  grapeVarietyColor: Yup.string(),
  regionette: Yup.array(Yup.string()),
  mark: Yup.array(Yup.string()),
});

export const initialValues: ValuesType = {
  customer: [],
  service: [],
  grapeVariety: [],
  grapeVarietyColor: undefined,
  regionette: [],
  mark: [],
};
