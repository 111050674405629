import {
  booleanWithin,
  polygon as createPolygon,
  difference,
  featureCollection,
  getCoords,
  lineIntersect,
  lineOffset,
  lineString,
  lineToPolygon,
  point,
} from "@turf/turf";
import {
  Feature,
  FeatureCollection,
  LineString,
  MultiPolygon,
  Polygon,
  Position,
} from "geojson";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

enum CUT_SIDE {
  FIRST = 0,
  SECOND = 1,
}

const THICK_LINE_UNITS = "kilometers";
const THICK_LINE_WIDTH = 0.001;

export const useCutPolygon = () => {
  const [t] = useTranslation();

  const cutPolygon = useMemo(
    () =>
      (
        polygon: Feature<Polygon>,
        line: Feature<LineString>,
      ): FeatureCollection<Polygon> | undefined => {
        const resultingPolygons: Feature<Polygon, { id: number }>[] = [];
        const offsetLines: Feature<any>[] = [];

        const intersectPoints = lineIntersect(polygon, line);
        const lineIntersectsPolygon = intersectPoints.features.length > 0;
        if (!lineIntersectsPolygon) {
          throw new Error("Unable to split polygon", {
            cause: t(
              "notification.error.polygonSplitting.cause.noIntersectingPoints",
            ),
          });
        }

        const lineCoords = getCoords(line);

        const endpointsInsideOfPolygon =
          booleanWithin(point(lineCoords[0]), polygon) ||
          booleanWithin(point(lineCoords[lineCoords.length - 1]), polygon);
        if (endpointsInsideOfPolygon) {
          throw new Error("Unable to split polygon", {
            cause: t(
              "notification.error.polygonSplitting.cause.endPointInside",
            ),
          });
        }

        offsetLines[0] = lineOffset(line, THICK_LINE_WIDTH, {
          units: THICK_LINE_UNITS,
        });

        offsetLines[1] = lineOffset(line, -THICK_LINE_WIDTH, {
          units: THICK_LINE_UNITS,
        });

        [CUT_SIDE.FIRST, CUT_SIDE.SECOND].forEach((cutSide) => {
          const oppositeCutSide = (cutSide + 1) % 2;

          const splitHelperPolyCoords = [
            ...line.geometry.coordinates,
            ...offsetLines[cutSide].geometry.coordinates.reverse(),
            line.geometry.coordinates[0],
          ];

          const thickLineString = lineString(splitHelperPolyCoords);
          const thickLinePolygon = lineToPolygon(thickLineString);
          const clipped = difference(
            featureCollection<Polygon | MultiPolygon>([
              polygon,
              thickLinePolygon,
            ]),
          );

          const cutPolyGeoms: Position[][] = [];

          clipped?.geometry.coordinates.forEach((coords) => {
            const polyg = createPolygon(coords);
            const intersect = lineIntersect(
              polyg,
              offsetLines[oppositeCutSide],
            );

            if (intersect.features.length > 0) {
              cutPolyGeoms.push(...polyg.geometry.coordinates);
            }
          });

          cutPolyGeoms.forEach((geometry, index) => {
            resultingPolygons.push(createPolygon([geometry], { id: index }));
          });
        });

        if (resultingPolygons.length > 0) {
          return featureCollection(resultingPolygons);
        } else {
          throw new Error("Unable to split polygon", {
            cause: t("notification.error.polygonMerging.cause.operationFailed"),
          });
        }
      },
    [t],
  );

  return cutPolygon;
};
