import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import {
  getParcelColor,
  getParcelStatus,
} from "@ero/app-common/v2/utils/ParcelColor";
import { Card, CardContent } from "@mui/material";
import { MarkerClusterer } from "@react-google-maps/api";
import { FullScreenControlV2, MapParcel, MapV2 } from "Components";
import { TopControls } from "Components/map/components/controls";
import { AppState } from "Store";
import { Coordinate } from "Types";
import { markerIcons } from "Utils";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

const PARCEL_CLUSTER_MAXZOOM = 14;

export const MapCard: React.FC = () => {
  const map = useRef<google.maps.Map>();

  const { companyData } = useSelector((store: AppState) => store.auth);
  const jobs = useSelector(
    (state: AppState) => state.orderDetails.order?.jobDetails?.jobs,
  );

  const [zoom, setZoom] = useState<number>(14);

  const onZoomChanged = useCallback(() => {
    const currentZoom = map.current?.getZoom();
    if (currentZoom) setZoom(currentZoom);
  }, [map]);

  const showPolygon = useMemo(
    () => (zoom ? zoom > PARCEL_CLUSTER_MAXZOOM : false),
    [zoom],
  );

  const parcels = useMemo(() => {
    const uniqueIds = new Set();

    const parcels = jobs?.reduce((acc, job) => {
      const parcelId = job.parcel._id;
      if (!uniqueIds.has(parcelId)) {
        uniqueIds.add(parcelId);
        const parcelObj = { ...job.parcel };
        parcelObj["jobs"] = [job];
        acc.push(parcelObj as ParcelResponseBody);
      } else {
        const parcel = acc.find((parcel) => parcel._id === parcelId);
        if (parcel) parcel["jobs"].push(job);
      }

      return acc;
    }, [] as ParcelResponseBody[]);

    return parcels;
  }, [jobs]);

  const parcelFigures = useMemo(
    () => (
      <MarkerClusterer
        options={{
          maxZoom: PARCEL_CLUSTER_MAXZOOM,
          averageCenter: true,
        }}
      >
        {(clusterer) => (
          <>
            {parcels?.map((parcel) => {
              const parcelStatus = getParcelStatus(parcel.jobs);
              return (
                <MapParcel
                  key={parcel._id}
                  showPolygon={showPolygon}
                  parcel={parcel}
                  markerProps={{
                    clusterer,
                    customIcon: markerIcons[parcelStatus],
                  }}
                  fillColor={getParcelColor(parcelStatus)}
                />
              );
            })}
          </>
        )}
      </MarkerClusterer>
    ),
    [parcels, showPolygon],
  );

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      if (parcels) {
        const coordinates: Coordinate[] = parcels
          .reduce<(Coordinate | Coordinate[])[]>((acc, parcel) => {
            const coords = parcel.shape?.length
              ? parcel.shape
              : parcel.position;
            if (coords) acc.push(coords);
            return acc;
          }, [])
          .flat();

        if (coordinates.length === 0) return;

        const bounds = new google.maps.LatLngBounds();
        for (const coordinate of coordinates) {
          if (coordinate) bounds.extend(coordinate);
        }
        map.current = mapInstance;
        map.current.fitBounds(bounds);
      }
    },
    [parcels],
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%", padding: "0px !important" }}>
        <MapV2
          id="orders-details-map"
          center={companyData.machinePosition}
          zoom={zoom}
          onLoad={onLoad}
          onZoomChanged={onZoomChanged}
        >
          <>{parcelFigures}</>
          <TopControls>
            <FullScreenControlV2 mapRef={map} />
          </TopControls>
        </MapV2>
      </CardContent>
    </Card>
  );
};
