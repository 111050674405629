import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import {
  type EventApi,
  type EventInput,
  type OverlapFunc,
} from "@fullcalendar/core";
import { ROUTES } from "Constants";
import dayjs from "dayjs";
import { CalendarEventColor } from "Enums";
import { useCallback, type Dispatch, type SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setScrollPosition,
  setShowStatusColor,
  updateJob,
  updateMultipleJobs,
} from "Store/planning";
import {
  type CalendarDateRange,
  type CalendarEventInput,
  type UpdateCalendarJobType,
} from "Types";
import { getJobDurationInMs } from "../../sidebar/components/listItem/duration";
import {
  getResourceChanges,
  getResourceIds,
  type MarkedEventType,
} from "../helpers";

interface IUseEvents {
  droppableContainerEl?: HTMLDivElement;
  jobs: JobResponseBody[];
  jobEvents: CalendarEventInput[];
  markedEvent: MarkedEventType;
  setEventUpdating: React.Dispatch<React.SetStateAction<MarkedEventType>>;
  setPlannedJob: Dispatch<SetStateAction<JobResponseBody | undefined>>;
  openSelectMachineModal: () => void;
}

export const useEvents = ({
  droppableContainerEl,
  jobs,
  jobEvents,
  markedEvent,
  setEventUpdating,
  setPlannedJob,
  openSelectMachineModal,
}: IUseEvents) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeDayTimeslots = useCallback(
    (
      updateData: UpdateCalendarJobType,
      originalBaseJob?: JobResponseBody,
      relatedEvents?: EventApi[],
      machine?: number,
    ) => {
      const changedJobsForChange: UpdateCalendarJobType[] =
        relatedEvents?.map((event) => {
          const initialJob = jobs.find((item) => item._id === +event.id);

          const data = {
            id: +event.id,
            dateRange: {
              start: dayjs(event.start).valueOf(),
              end: dayjs(event.end).valueOf(),
            },
            ...getResourceChanges(
              event.getResources(),
              getResourceIds(initialJob!).map((item) => ({ id: item })),
            ),
          };

          if (machine !== undefined) data["machine"] = machine;

          return data;
        }) || [];

      changedJobsForChange.push(updateData);
      changedJobsForChange.sort(
        (first, second) => first.dateRange.start - second.dateRange.start,
      );

      if (
        originalBaseJob &&
        Number(originalBaseJob.start) < updateData.dateRange.start
      ) {
        changedJobsForChange.reverse();
      }

      dispatch(updateMultipleJobs({ jobsForChange: changedJobsForChange }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobs],
  );

  const onEventClick = useCallback(
    (job: JobResponseBody, _, jsEvent) => {
      // hack to prevent event callback handler to work before icon click
      if (
        jsEvent.target.tagName === "path" ||
        jsEvent.target.tagName === "svg"
      ) {
        return;
      }

      dispatch(setScrollPosition(job.start));

      navigate(`${ROUTES.MAIN.ORDERS}/${job.order}`, {
        state: {
          jobId: job._id,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );

  const updateOrder = useCallback(
    (
      event: EventApi,
      localDateRange: CalendarDateRange,
      assignedDrivers,
      unassignedDrivers,
    ) => {
      const order: OrderResponseBody = event.extendedProps.originalItem;
      let nextJobStart = localDateRange.start;

      if (order.jobDetails) {
        const jobsToUpdate: UpdateCalendarJobType[] =
          order.jobDetails.jobs.map((job) => {
            const duration = getJobDurationInMs(
              job.parcel?.size,
              job.difficulty,
            );
            const data = {
              id: job._id,
              dateRange: {
                start: nextJobStart,
                end: nextJobStart + duration,
              },
              assignedDrivers,
              unassignedDrivers,
            };

            const JOB_GAP = 300000; // 5 minutes

            nextJobStart = nextJobStart + duration + JOB_GAP;

            return data;
          }) || [];

        jobsToUpdate.sort(
          (first, second) => first.dateRange.start - second.dateRange.start,
        );

        dispatch(updateMultipleJobs({ jobsForChange: jobsToUpdate }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateJobs = useCallback(
    (
      event: EventApi,
      localDateRange: CalendarDateRange,
      assignedDrivers,
      unassignedDrivers,
      revert: () => void,
      relatedEvents?: EventApi[],
      localEvents?: EventInput[],
    ) => {
      const previousPlannedJob = jobs.find((item) => item._id === +event.id);
      const currentPlannedJob: JobResponseBody =
        event?.extendedProps.originalItem;

      const didMultipleEventsMoved =
        relatedEvents?.length ||
        (+event.id === markedEvent.id &&
          previousPlannedJob?.start !== localDateRange.start &&
          previousPlannedJob?.end !== localDateRange.end);

      const isAnyDriver = event.getResources()[0].id === "0";

      const updateData = {
        id: +event.id,
        dateRange: localDateRange,
        assignedDrivers,
        unassignedDrivers,
      };

      if (assignedDrivers.length || isAnyDriver) updateData["machine"] = -1;

      setEventUpdating({
        id: +event.id,
        dayRange: {
          start: localDateRange.start as Milliseconds,
          end: dayjs(localDateRange.end).endOf("day").valueOf() as Milliseconds,
        },
        selectedResource:
          event
            .getResources()
            .map((item) => item.id)
            .pop() || "",
      });

      const similarEventOnADay = localEvents?.find((localEvent) => {
        const item = localEvent.originalItem;
        const movedEvent = event.extendedProps?.originalItem;

        if (item._id !== movedEvent._id) {
          if (
            new Date(item.start).getDate() === event?.start?.getDate() &&
            new Date(item.start).getMonth() === event?.start?.getMonth() &&
            new Date(item.start).getFullYear() ===
              event?.start?.getFullYear() &&
            item.name === movedEvent.name &&
            item.machine._id !== -1
          ) {
            if (assignedDrivers.length > 0) {
              return item.employees[0]?._id === assignedDrivers[0];
            } else {
              return item.employees[0]?._id === movedEvent.employees[0]?._id;
            }
          }
        }

        return false;
      });

      if (!isAnyDriver && similarEventOnADay) {
        const machineId = similarEventOnADay.originalItem.machine._id;
        updateData["machine"] = machineId;

        if (didMultipleEventsMoved) {
          changeDayTimeslots(
            updateData,
            previousPlannedJob,
            relatedEvents,
            machineId,
          );
          return;
        }
      }

      if (didMultipleEventsMoved) {
        changeDayTimeslots(updateData, previousPlannedJob, relatedEvents);
      } else {
        dispatch(
          updateJob({
            jobData: updateData,
            revertEvent: revert,
          }),
        );

        setPlannedJob(currentPlannedJob);

        const hasAssignedAtLeastOneDriver = assignedDrivers.length;
        const shouldOpenSelectMachineModal =
          hasAssignedAtLeastOneDriver && !similarEventOnADay;
        if (shouldOpenSelectMachineModal) {
          openSelectMachineModal();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changeDayTimeslots,
      jobEvents,
      jobs,
      markedEvent.id,
      setEventUpdating,
      setPlannedJob,
      openSelectMachineModal,
    ],
  );

  const onEventDrag = useCallback(
    (
      event: EventApi,
      revert: () => void,
      oldEvent?: EventApi,
      relatedEvents?: EventApi[],
      localEvents?: EventInput[],
    ) => {
      const start = dayjs(event.start);
      const end =
        event.end === null || dayjs(event.end).isSame(event.start)
          ? dayjs(start).add(1, "h")
          : dayjs(event.end);

      const localDateRange: CalendarDateRange = {
        start: start.valueOf(),
        end: end.valueOf(),
      };

      const { assignedDrivers, unassignedDrivers } = getResourceChanges(
        event.getResources(),
        oldEvent?.getResources(),
      );

      if (event.extendedProps?.type === "order") {
        updateOrder(event, localDateRange, assignedDrivers, unassignedDrivers);
      } else {
        updateJobs(
          event,
          localDateRange,
          assignedDrivers,
          unassignedDrivers,
          revert,
          relatedEvents,
          localEvents,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changeDayTimeslots,
      jobEvents,
      jobs,
      markedEvent.id,
      setEventUpdating,
      setPlannedJob,
      openSelectMachineModal,
    ],
  );

  const eventOverlap: OverlapFunc = useCallback((_, movingEvent) => {
    return !!movingEvent;
  }, []);

  const onEventDragStart = useCallback(() => {
    droppableContainerEl?.classList.add("droppable-zone");
  }, [droppableContainerEl]);

  // todo doc (error case ???)
  const onEventDragStop = useCallback(
    (event?: EventApi) => {
      droppableContainerEl?.classList.remove("droppable-zone");

      if (!event) {
        return;
      }

      const { assignedDrivers, unassignedDrivers } = getResourceChanges(
        [],
        event?.getResources(),
      );

      dispatch(
        updateJob({
          jobData: {
            id: +event.id,
            dateRange: {
              start: -1,
              end: -1,
            },
            assignedDrivers,
            unassignedDrivers,
            machine: -1,
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [droppableContainerEl?.classList],
  );

  const onEventColorChange = useCallback((eventColor: CalendarEventColor) => {
    dispatch(setShowStatusColor(eventColor));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    eventOverlap,
    onEventColorChange,
    onEventClick,
    onEventDrag,
    onEventDragStart,
    onEventDragStop,
  };
};
