import {
  OrderResponseBody,
  type AddOrderRequestBody,
  type DeleteOrdersRequestQuery,
  type OrdersRequestQuery,
  type OrdersResponseBody,
} from "@ero/app-common/v2/routes/models/order";
import {
  ORDERS_ACTION_TYPES,
  type ICreateOrdersRequestAction,
  type IDeleteOrdersRequestAction,
  type IGetOrdersRequestAction,
  type IGetOrdersSuccessAction,
  type IResetMetaAction,
  type IResetSearchAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetOrdersPaginationModelAction,
  type ISetSearchAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: ORDERS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: ORDERS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: ORDERS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: ORDERS_ACTION_TYPES.RESET_META,
});

export const setSearch = (search: string): ISetSearchAction => ({
  type: ORDERS_ACTION_TYPES.SET_SEARCH,
  payload: { search },
});

export const resetSearch = (): IResetSearchAction => ({
  type: ORDERS_ACTION_TYPES.RESET_SEARCH,
});

export const setPaginationModel = ({
  currentPage,
  numberOfRows,
}): ISetOrdersPaginationModelAction => ({
  type: ORDERS_ACTION_TYPES.SET_PAGINATION_MODEL,
  payload: { currentPage, numberOfRows },
});

export const getOrders = (
  payload: OrdersRequestQuery
): IGetOrdersRequestAction => {
  return {
    type: ORDERS_ACTION_TYPES.GET_ORDERS_REQUEST,
    payload,
  };
};

export const getOrdersSuccess = ({
  data,
  search,
  createdOrder,
  lastOrderId,
  maxCount,
}: OrdersResponseBody & {
  search?: string;
  createdOrder?: OrderResponseBody;
}): IGetOrdersSuccessAction => ({
  type: ORDERS_ACTION_TYPES.GET_ORDERS_SUCCESS,
  payload: {
    data,
    search,
    lastOrderId,
    createdOrder,
    maxCount,
  },
});

export const createOrder = (
  order: AddOrderRequestBody
): ICreateOrdersRequestAction => ({
  type: ORDERS_ACTION_TYPES.CREATE_ORDERS_REQUEST,
  payload: {
    order,
  },
});

export const deleteOrder = (
  orders: DeleteOrdersRequestQuery
): IDeleteOrdersRequestAction => ({
  type: ORDERS_ACTION_TYPES.DELETE_ORDERS_REQUEST,
  payload: orders,
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
  subTable?: string
): ISetTableHiddenColumns => ({
  type: ORDERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns, subTable },
});
