import { GRAPEVARIETYCOLOR } from "@ero/app-common/enums";
import {
  convertAreaToCustom,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import { SqMeter } from "@ero/app-common/util/Units";
import { Flag, Lock } from "@mui/icons-material";
import { Chip, Grid2, Tooltip, Typography } from "@mui/material";
import { EllipsisText } from "Components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";

type EventContentProps = {
  orderInfo?: {
    _id: number;
    name: string;
    grapeVarieties: {
      name: string;
      color: GRAPEVARIETYCOLOR;
      count: number;
    }[];
    customer: { companyName: string };
    parcelsSize: number | undefined;
    parcelsCount: number | undefined;
    gemarkungen: string[];
    notes: string;
  };
  start?: Date | null;
  end?: Date | null;
  dateRestrictions?: string;
  eventLocked?: boolean;
  showAllInfo?: boolean;
  selected?: boolean;
  containerWidth?: number;
  containerHeight?: number;
};

export const EventContent = React.forwardRef<HTMLDivElement, EventContentProps>(
  (
    {
      orderInfo,
      start,
      end,
      dateRestrictions,
      eventLocked,
      showAllInfo = false,
      selected = false,
      containerHeight,
      containerWidth,
      ...props
    },
    ref,
  ) => {
    const [t, i18n] = useTranslation();

    const { unitOfMeasurement } = useSelector(
      (state: AppState) => state.auth.companyData,
    );

    const { startTime, endTime } = useMemo(
      () => ({
        startTime: start
          ? new Intl.DateTimeFormat(i18n.language, {
              timeStyle: "short",
            }).format(start)
          : "",
        endTime: end
          ? new Intl.DateTimeFormat(i18n.language, {
              timeStyle: "short",
            }).format(end)
          : "",
      }),
      [i18n.language, start, end],
    );

    const grapeVarietyChips = useMemo(
      () =>
        orderInfo?.grapeVarieties?.map((grapeVariety) => (
          <Grid2 key={grapeVariety.name}>
            <Chip
              label={`${grapeVariety.count} ${
                grapeVariety.name?.split("(")[0]
              }`}
              size="small"
              variant="outlined"
              sx={(theme) => ({
                ...(grapeVariety.color === GRAPEVARIETYCOLOR.RED
                  ? {
                      backgroundColor: theme.palette.grapeVariety.red,
                      color: theme.palette.primary.contrastText,
                    }
                  : {
                      backgroundColor: theme.palette.grapeVariety.white,
                      color: theme.palette.text.primary,
                    }),
              })}
            />
          </Grid2>
        )),
      [orderInfo?.grapeVarieties],
    );

    const isShortEvent = useMemo(
      () => containerHeight && containerHeight > 60,
      [containerHeight],
    );

    const isMediumEvent = useMemo(
      () => containerHeight && containerHeight > 120,
      [containerHeight],
    );

    const isLongEvent = useMemo(
      () => containerHeight && containerHeight > 150,
      [containerHeight],
    );

    const isWideEvent = useMemo(
      () => (containerWidth ?? 400) >= 400,
      [containerWidth],
    );

    const showTime = useMemo(() => start && end, [end, start]);

    const isLongOrderName = useMemo(
      () => orderInfo?.name && orderInfo?.name.length > 50,
      [orderInfo?.name],
    );

    const showCompanyName = useMemo(
      () => showAllInfo || isShortEvent,
      [isShortEvent, showAllInfo],
    );

    const showParcelCountAndSize = useMemo(
      () => showAllInfo || isMediumEvent,
      [isMediumEvent, showAllInfo],
    );

    const showEventDetails = useMemo(
      () => showAllInfo || isLongEvent,
      [isLongEvent, showAllInfo],
    );

    return (
      <Grid2
        ref={ref}
        {...props}
        container
        sx={{
          p: 0.5,
          height: "100%",
          overflow: "hidden",
          alignContent: "flex-start",
        }}
        width={showAllInfo ? "100%" : undefined}
        justifyContent={"space-between"}
      >
        {((showTime && !isWideEvent) || (showTime && showAllInfo)) && (
          <Grid2 size={12} pb={1} pt={0.5}>
            <Typography variant="body2">
              {startTime} - {endTime}
            </Typography>
          </Grid2>
        )}
        <Grid2
          size={11}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {(selected || eventLocked) && (
            <Grid2>
              {selected && <Flag fontSize={"small"} htmlColor={"yellow"} />}
              {eventLocked && <Lock fontSize={"small"} />}
            </Grid2>
          )}
          <Grid2 size={9}>
            <Tooltip
              title={
                !isLongOrderName || (!isLongEvent && start && end)
                  ? ""
                  : orderInfo?.name
              }
            >
              <Typography
                variant="body1"
                sx={{
                  ...(showAllInfo && start && end
                    ? {}
                    : {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }),
                  fontWeight: 500,
                }}
              >
                {orderInfo?.name}
              </Typography>
            </Tooltip>
          </Grid2>
          {showTime && isWideEvent && !showAllInfo && (
            <Grid2 pb={1} pt={0.5}>
              <Typography variant="body2">
                {startTime} - {endTime}
              </Typography>
            </Grid2>
          )}
        </Grid2>
        {showCompanyName && (
          <Grid2 size={12}>
            <Typography variant="subtitle2">
              {orderInfo?.customer?.companyName}
            </Typography>
          </Grid2>
        )}
        {showParcelCountAndSize && (
          <>
            <Grid2 size={12}>
              <Typography variant="body2">
                {orderInfo?.parcelsSize &&
                  convertAreaToCustom(
                    orderInfo.parcelsSize as SqMeter,
                    unitOfMeasurement,
                  ) + " "}
                {orderInfo?.parcelsSize && UnitSymbol[unitOfMeasurement]}
                {orderInfo?.parcelsSize && "; "}
                {orderInfo?.parcelsCount + " "}
                {orderInfo?.parcelsCount &&
                  t("general.parcel", { count: orderInfo.parcelsCount })}
              </Typography>
            </Grid2>
          </>
        )}
        {showEventDetails && (
          <Grid2 container spacing={0.5}>
            <Grid2 size={12}>
              <Typography variant="caption">
                {orderInfo?.gemarkungen?.join(", ")}
              </Typography>
            </Grid2>
            <Grid2 container spacing={0.5} size={12}>
              {grapeVarietyChips}
            </Grid2>
            {dateRestrictions && (
              <Grid2 size={12}>
                <Typography variant="caption">{dateRestrictions}</Typography>
              </Grid2>
            )}
            <Grid2 size={12}>
              <EllipsisText
                text={orderInfo?.notes || ""}
                maxLines={2}
              ></EllipsisText>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    );
  },
);

EventContent.displayName = "EventContent";
