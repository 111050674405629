import { Coordinate } from "@ero/app-common/util/Coordinate";
import { polygon } from "@turf/turf";
import { Feature, GeoJsonProperties, Polygon } from "geojson";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPolygonArea } from "Utils";
import { useMergePolygons } from "./turf/polygonMerge";
import { PolygonWithSize } from "./types";

export const useMerge = () => {
  const [t] = useTranslation();

  const mergePolygons = useMergePolygons();

  const merge = useMemo(
    () =>
      (parcelPolygons: Coordinate[][]): PolygonWithSize => {
        if (parcelPolygons.length < 2) {
          throw new Error("Unable to merge parcels", {
            cause: t(
              "notification.error.polygonMerging.cause.onlyOneParcelSelected",
            ),
          });
        }

        const polygons: Feature<Polygon, GeoJsonProperties>[] =
          parcelPolygons.map((shape) =>
            polygon([shape.map((coord) => [coord.lng, coord.lat])]),
          );

        const merged = mergePolygons(polygons);

        const shape: Coordinate[] = merged.geometry.coordinates[0].map(
          (coords) =>
            ({
              lng: coords[0] as number,
              lat: coords[1] as number,
            } as Coordinate),
        );

        const size = getPolygonArea(shape);

        return { shape, size };
      },
    [mergePolygons, t],
  );

  return merge;
};
