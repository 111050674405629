import { getCustomers } from "Api";
import { FetchOptionsFunc, OptionType } from "Types";
import {
  filterSpecShorthand,
  offset2pagination,
  searchSpecShorthand,
} from "Utils";

export const useCustomerOptions = (pageLength?: number) => {
  const fetchCustomerOptions: FetchOptionsFunc = async ({
    query,
    id,
    offset,
  }) => {
    const pagination = offset2pagination(offset, pageLength || 20);
    try {
      const search = searchSpecShorthand(query, [
        { access: ["companyName"], type: "string" },
        { access: ["label"], type: "string" },
      ]);
      const filters = filterSpecShorthand(id, ["_id"]);
      const {
        data: { data, maxCount },
      } = await getCustomers({
        params: pagination,
        search,
        filters,
        spec: [["companyName"], ["label"], ["email"]],
      });
      return {
        chunk: data.map((customer) => ({
          value: customer._id,
          label: `${customer.companyName}`,
          meta: { email: customer.email },
        })),
        maxCount,
      };
    } catch {
      return { chunk: [], maxCount: 0 };
    }
  };

  return {
    fetchCustomerOptions,
  };
};

export const useAllCustomerOptions = () => {
  const fetchCustomerOptions = async (): Promise<OptionType[]> => {
    try {
      const {
        data: { data },
      } = await getCustomers({
        params: { page: 0, pageLength: -1 },
        spec: [["companyName"], ["label"], ["email"]],
      });
      return data.map((customer) => ({
        value: customer._id,
        label: `${customer.companyName}`,
        meta: { email: customer.email },
      }));
    } catch {
      return [];
    }
  };

  return {
    fetchCustomerOptions,
  };
};
