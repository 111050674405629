import * as Yup from 'yup';

import { VALIDATION_ERRORS } from 'Constants';
import { LOGIN_DTO } from 'Types';

export const initialValues: LOGIN_DTO = {
  userName: '',
  password: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    userName: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
    password: Yup.string().required(VALIDATION_ERRORS.REQUIRED),
  });
