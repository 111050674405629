import { type Milliseconds } from "@ero/app-common/util/Milliseconds";
import { Card, CardContent, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import ReactMarkdown from "react-markdown";

interface IReleaseNoteCard {
  item: string;
  date: Milliseconds;
  text: string;
}

//TODO: Move to sort of helper library in ERO Frontend Project
//Helper function to convert ms to date
const millisecondsToDate = (ts: number): string => {
  if (ts === -1) {
    return "-";
  }
  return moment(ts).format("DD.MM.YYYY");
};

export const ReleaseNoteCard: React.FC<IReleaseNoteCard> = ({
  item,
  date,
  text,
}) => (
  <Card elevation={5}>
    <CardContent>
      <Typography color="text.secondary" gutterBottom>
        {millisecondsToDate(date)}
      </Typography>
      <Typography variant="h5">{item}</Typography>
      <ReactMarkdown>{text}</ReactMarkdown>
    </CardContent>
  </Card>
);
