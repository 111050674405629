import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { useDateRestrictionsSchema } from "Hooks";
import { useMemo } from "react";
import * as Yup from "yup";

export type ValuesType = {
  dateRestrictions: {
    dateMode: DateMode | undefined;
    timeMode: DateTimeMode | undefined;
    date: number | undefined;
    startDate: number | undefined;
    endDate: number | undefined;
    dayTime: {
      startTime: number | undefined;
      endTime: number | undefined;
    };
  };
};

export const useValidationSchema = () => {
  const dateRestrictionsSchema = useDateRestrictionsSchema();

  const schema = useMemo(
    () =>
      Yup.object().shape({
        dateRestrictions: dateRestrictionsSchema,
      }),
    [dateRestrictionsSchema],
  );
  return schema;
};
