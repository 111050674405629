import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Circle, Info } from "@mui/icons-material";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { JobStatus } from "Enums";
import { getJobStatusLabel } from "Utils";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const stepIconComponent: React.ElementType = (status?: JobStatus) => {
  switch (status) {
    case JobStatus.DRIVING_TO_LOCATION:
      return <Circle sx={{ color: "status.driving" }} />;
    case JobStatus.IN_WORK:
      return <Circle sx={{ color: "status.inwork" }} />;
    case JobStatus.PAUSE:
      return <Circle sx={{ color: "status.pause" }} />;
    case JobStatus.DONE:
      return <Circle sx={{ color: "status.done" }} />;
    case JobStatus.OPEN:
    default:
      return <Circle sx={{ color: "status.open" }} />;
  }
};

interface IStatusesList {
  statusOperations?: JobResponseBody["statusOperations"];
}

const dateTimeFormat = new Intl.DateTimeFormat([], {
  dateStyle: "medium",
  timeStyle: "short",
});

export const StatusesList: React.FC<IStatusesList> = ({ statusOperations }) => {
  const [t] = useTranslation();

  const steps = useMemo(() => {
    if (statusOperations)
      if (statusOperations.length > 0) {
        return statusOperations.map((operation, idx) => (
          <Step key={idx} active completed>
            <StepLabel
              StepIconComponent={() => stepIconComponent(operation.status)}
            >
              {getJobStatusLabel(operation.status)}
            </StepLabel>
            <StepContent>
              {dateTimeFormat.format(new Date(operation.timestamp))}
            </StepContent>
          </Step>
        ));
      } else {
        return [
          <Step key="info" completed>
            <StepLabel StepIconComponent={() => <Info />}>
              {t("orders.details.jobDetails.noStatuses")}
            </StepLabel>
          </Step>,
          <Step key="blank" active>
            <StepLabel StepIconComponent={() => <></>}></StepLabel>
          </Step>,
        ];
      }
  }, [statusOperations, t]);

  return (
    <Stepper sx={{ maxHeight: 350, overflowY: "auto" }} orientation="vertical">
      {steps}
    </Stepper>
  );
};
