import { Mail, Phone, Smartphone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { AppState } from "Store";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const ContactCard: React.FC = () => {
  const [t] = useTranslation();

  const { order } = useSelector((state: AppState) => state.orderDetails);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography variant="h6">{order?.customer.companyName}</Typography>
        <List>
          {order?.customer.mainContact && (
            <>
              <ListItem>
                <ListItemText
                  secondary={t("customers.fields.mainContact")}
                  primary={
                    order?.customer.mainContact?.firstName +
                    " " +
                    order?.customer.mainContact?.lastName
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText
                  primary={order?.customer.mainContact?.phone || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Smartphone />
                </ListItemIcon>
                <ListItemText
                  primary={order?.customer.mainContact?.mobile || "-"}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Mail />
                </ListItemIcon>
                <ListItemText
                  primary={order?.customer.mainContact?.email || "-"}
                />
              </ListItem>
            </>
          )}
          {!order?.customer.mainContact && (
            <>
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={order?.customer.phone || "-"} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Smartphone />
                </ListItemIcon>
                <ListItemText primary={order?.customer.mobile || "-"} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Mail />
                </ListItemIcon>
                <ListItemText primary={order?.customer.email || "-"} />
              </ListItem>
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
};
