import { Tooltip, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React, { useEffect, useState } from "react";

type IEllipsisText = {
  text: string;
  variant?: Variant;
  color?: string;
  maxLength?: number;
  maxLines?: number;
};

export const EllipsisText: React.FC<IEllipsisText> = ({
  text = "",
  variant = "caption",
  color,
  maxLength = 150,
  maxLines,
}) => {
  const [isHoverEnabled, setIsHoverEnabled] = useState(false);

  useEffect(() => {
    if (text.length > maxLength) {
      setIsHoverEnabled(true);
    } else {
      setIsHoverEnabled(false);
    }
  }, [text, maxLength]);

  return (
    <Tooltip title={text} disableHoverListener={!isHoverEnabled}>
      <Typography
        color={color}
        variant={variant}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: maxLines?.toString() || "5",
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
