export interface FormikValues {
  parcel: number | string;
  service: number | string;
  notes: string | undefined;
}

export enum ModalContent {
  FORM = 0,
  PARCELSMAP = 1,
}
