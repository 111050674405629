import RectangleIcon from "@mui/icons-material/Rectangle";
import RoomIcon from "@mui/icons-material/Room";
import { Chip, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { useTheme } from "Theme";
import { POLYGON_EDIT_MODE } from "./utils";

interface ILegend {
  editMode: POLYGON_EDIT_MODE;
}

export const Legend: React.FC<ILegend> = ({ editMode }) => {
  const [t] = useTranslation();

  const colorMode = useSelector((store: AppState) => store.auth.colorMode);

  const theme = useTheme(colorMode as "light" | "dark");

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ pointerEvents: "none", position: "absolute", bottom: 25, left: 8 }}
    >
      <Stack direction="column" spacing={1} width={250}>
        <Chip
          size="small"
          icon={
            <RectangleIcon
              sx={{
                fill: theme.palette.status.done,
              }}
            />
          }
          label={t("parcels.upload.infoLabels.readyForImport")}
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            bgcolor: "#e0e0e0",
          }}
        />
        <Chip
          size="small"
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            bgcolor: "#e0e0e0",
          }}
          icon={
            <RectangleIcon
              sx={{
                fill: theme.palette.status.driving,
              }}
            />
          }
          label={t("parcels.upload.infoLabels.inEditMode")}
        />
        {editMode === POLYGON_EDIT_MODE.SPLIT && (
          <Chip
            size="small"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              bgcolor: "#e0e0e0",
            }}
            icon={
              <RoomIcon
                sx={{
                  fill: "rgba(206, 38, 38, 0.733)",
                }}
              />
            }
            label={t("parcels.upload.infoLabels.splitLine")}
          />
        )}
      </Stack>
    </Stack>
  );
};
