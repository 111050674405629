import { SqMeter } from "@ero/app-common/util/Units";
import { convertAreaToCustom } from "@ero/app-common/util/convertArea";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { type AppState } from "Store";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useTotalArea = (jobs: JobResponseBody[]) => {
  const selectedUnit = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const totalArea = useMemo(
    () =>
      convertAreaToCustom(
        jobs
          .map((job) => job.parcel.size ?? 0)
          .reduce((acc: number, curr: number) => acc + curr, 0) as SqMeter,
        selectedUnit,
      ),
    [jobs, selectedUnit],
  );

  return totalArea;
};
