import { PERM } from "@ero/app-common/enums";
import {
  EventResponseBody,
  EventsResponseBody,
} from "@ero/app-common/v2/routes/models/event";
import {
  OrdersRequestQuery,
  OrdersResponseBody,
} from "@ero/app-common/v2/routes/models/order";
import { getEmployeesV2, getOrdersV2Slim } from "Api";
import { addEvent, deleteEvent, getEvents, updateEvent } from "Api/events";
import dayjs from "dayjs";
import i18n from "i18n/i18n";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { errorToast, successToast } from "Services";
import { AppState } from "Store/store";
import { sagaActions } from "./planningV2SagaActions";
import { planningV2Slice } from "./planningV2slice";

export function* fetchUsersSaga(): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingUsers(true));
    const usersResponse = yield call(getEmployeesV2, {
      type: PERM.DRIVER,
      includeTrack: false,
      limit: 99999,
    });
    yield put(planningV2Slice.actions.setUsers(usersResponse.data));
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadUsers"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingUsers(false));
  }
}

export function* setSelectedUsersSaga(
  action: ReturnType<typeof sagaActions.setSelectedUsers>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setSelectedUsers(action.payload));
  // refetch events to force re-rendering of events
  // (reorder layout if event is not wide enough)
  yield put(sagaActions.fetchEvents());
}

export function* fetchOrdersSaga(): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setLoadingOrders(true));
  const { date, filters } = yield select((state: AppState) => state.planningV2);
  const start = dayjs(date).startOf("day").unix() * 1000;
  const end = dayjs(date).endOf("day").unix() * 1000;
  try {
    const query: OrdersRequestQuery = {
      limit: 999999,
      sortBy: ["date"],
      ordersDateRange: { start, end },
      jobsDateRange: { start: -1, end: -1 },
      ...filters,
    };
    const ordersResponse = yield call(getOrdersV2Slim, query);
    yield put(
      planningV2Slice.actions.setOrders(
        ordersResponse.data.filter(
          (order: OrdersResponseBody["data"][0]) =>
            order.jobDetails !== undefined,
        ),
      ),
    );
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadOrders"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingOrders(false));
  }
}

export function* fetchEventsSaga(): Generator<any, void, any> {
  const calendarDate = yield select((state: AppState) => state.planningV2.date);
  const start = dayjs(calendarDate).startOf("day").unix() * 1000;
  const end = dayjs(calendarDate).endOf("day").unix() * 1000;
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));
    const eventResponse = yield call(getEvents, { limit: 99999, start, end });
    yield put(planningV2Slice.actions.setEvents(eventResponse.data));
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadEvents"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingEvents(false));
  }
}

export function* addEventSaga(
  action: ReturnType<typeof sagaActions.addEvent>,
): Generator<any, void, any> {
  try {
    const newEvent = yield call(addEvent, action.payload.event);
    const events = yield select((state: AppState) => state.planningV2.events);
    yield put(planningV2Slice.actions.setEvents([...events, newEvent]));
    yield put(sagaActions.fetchOrders());
  } catch (error) {
    errorToast(i18n.t("errors.unableToAddEvent"), undefined, error);
    action.payload.revert();
  }
}

export function* updateEventSaga(
  action: ReturnType<typeof sagaActions.updateEvent>,
): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));
    const updatedEvent = yield call(
      updateEvent,
      action.payload.id,
      action.payload.update,
    );
    const events = yield select((state: AppState) => state.planningV2.events);
    const updateIndex = events.findIndex(
      (event) => event._id === updatedEvent._id,
    );
    const updatedEvents = [...events];
    updatedEvents[updateIndex] = updatedEvent;
    yield put(planningV2Slice.actions.setEvents(updatedEvents));
  } catch (error) {
    yield put(planningV2Slice.actions.setLoadingEvents(false));
    errorToast(i18n.t("errors.unableToUpdateEvent"), undefined, error);
    action.payload.revert?.();
  }
}

export function* updateEventsSaga(
  action: ReturnType<typeof sagaActions.updateEvents>,
): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));

    const updatedEvents: EventResponseBody[] = yield all(
      action.payload.events.map(({ id, update }) =>
        call(updateEvent, id, update),
      ),
    );

    const events: EventsResponseBody["data"] = yield select(
      (state: AppState) => state.planningV2.events,
    );

    const newEvents = [
      ...events.map((event) => {
        return (
          updatedEvents.find(
            (updatedEvent) => updatedEvent._id === event._id,
          ) ?? event
        );
      }),
    ];

    yield put(planningV2Slice.actions.setEvents(newEvents));
  } catch (error) {
    yield put(planningV2Slice.actions.setLoadingEvents(false));
    errorToast(i18n.t("errors.unableToUpdateEvent"), undefined, error);
    action.payload.revert();
  }
}

export function* deleteEventSaga(
  action: ReturnType<typeof sagaActions.deleteEvent>,
): Generator<any, void, EventsResponseBody["data"]> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));

    yield all(action.payload.ids.map((id) => call(deleteEvent, id)));
    const events = yield select((state: AppState) => state.planningV2.events);
    const newEvents = events.filter(
      (event) => !action.payload.ids.includes(event._id),
    );
    yield put(planningV2Slice.actions.setEvents(newEvents));
    successToast(i18n.t("planningV2.successfullyRemoved"));
    yield put(sagaActions.fetchOrders());
  } catch (error) {
    errorToast(i18n.t("errors.unableToDeleteEvent"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingEvents(false));
  }
}

export function* setDateSaga(
  action: ReturnType<typeof sagaActions.setDate>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setDate(action.payload));
  yield put(sagaActions.fetchOrders());
  yield put(sagaActions.fetchEvents());
}

export function* setFilters(
  action: ReturnType<typeof sagaActions.setFilters>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setFilters(action.payload));
  yield put(sagaActions.fetchOrders());
}

export function* setSlotInterval(
  action: ReturnType<typeof sagaActions.setSlotInterval>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setSlotInterval(action.payload));
  // refetch events to force re-rendering of events
  // (show tooltips if event length is rather short)
  yield put(sagaActions.fetchEvents());
}

export default function* planningV2Saga() {
  yield takeLatest(sagaActions.fetchEvents.type, fetchUsersSaga);
  yield takeLatest(sagaActions.setSelectedUsers.type, setSelectedUsersSaga);
  yield takeLatest(sagaActions.fetchOrders.type, fetchOrdersSaga);
  yield takeLatest(sagaActions.fetchEvents.type, fetchEventsSaga);
  yield takeLatest(sagaActions.setDate.type, setDateSaga);
  yield takeLatest(sagaActions.addEvent.type, addEventSaga);
  yield takeLatest(sagaActions.updateEvent.type, updateEventSaga);
  yield takeLatest(sagaActions.updateEvents.type, updateEventsSaga);
  yield takeLatest(sagaActions.deleteEvent.type, deleteEventSaga);
  yield takeLatest(sagaActions.setFilters.type, setFilters);
  yield takeLatest(sagaActions.setSlotInterval.type, setSlotInterval);
}
