import { Person } from "@ero/app-common/models";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  return [
    {
      field: "internalId",
      headerName: t("customers.fields.id"),
    },
    {
      field: "companyName",
      headerName: t("customers.fields.name"),
    },
    {
      field: "address",
      headerName: t("customers.fields.address"),
      valueGetter: (value, row) =>
        [
          ...(row.street ? [row.street] : []),
          [row.zipCode, row.city].join(" "),
        ].join(", "),
    },
    {
      field: "email",
      headerName: t("general.fields.email"),
    },
    {
      field: "phone",
      headerName: t("general.fields.phone"),
    },
    {
      field: "mobile",
      headerName: t("customers.fields.mobile"),
    },
    {
      field: "employees",
      headerName: t("customers.fields.persons"),
    },
    {
      field: "notes",
      headerName: t("general.fields.notes"),
    },
    {
      field: "category",
      headerName: t("customers.fields.category"),
      valueGetter: (value: { _id: number; name: string }) =>
        value._id !== -1 ? value.name : "",
    },
    {
      field: "creationDate",
      headerName: t("customers.fields.creationDate"),
      valueGetter: (value) => new Date(value).toLocaleString(),
    },
    {
      field: "mainContact",
      headerName: t("customers.fields.mainContact"),
      valueGetter: (value: Person) =>
        value._id !== -1 ? `${value.firstName} ${value.lastName}` : "",
    },
  ];
};

export const defaultHiddenColumns = {
  internalId: false,
  id: false,
  creationDate: false,
  employees: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "number", access: ["internalId"], default: "-" },
  { type: "string", access: ["companyName"] },
  { type: "string", access: ["street"] },
  { type: "string", access: ["zipCode"] },
  { type: "string", access: ["city"] },
  { type: "string", access: ["email"] },
  { type: "string", access: ["phone"] },
  { type: "string", access: ["mobile"] },
  { type: "string", access: ["notes"] },
];
