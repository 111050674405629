import { Map } from "@mui/icons-material";
import { Container, Grid2, IconButton, Tooltip } from "@mui/material";
import { Input } from "Components";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { useAllParcelOptions } from "Hooks/parcelOptions";
import { useAllServiceOptions } from "Hooks/serviceOptions";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalContent } from "../helpers";
import { IContentProps } from "./types";

export const MainForm: React.FC<IContentProps & { customerId: number }> = ({
  navigate,
  customerId,
}) => {
  const [t] = useTranslation();

  const { fetchParcelOptions } = useAllParcelOptions(customerId);
  const { fetchServiceOptions } = useAllServiceOptions();

  return (
    <Container maxWidth="sm">
      <Grid2 container direction="column" spacing={2}>
        <Grid2>
          <Grid2 container>
            <Grid2 size={11}>
              <SelectWithAutoComplete
                name="parcel"
                label={t("general.labels.parcel")}
                fetchOptions={fetchParcelOptions}
                required
              />
            </Grid2>
            <Grid2
              size={1}
              sx={{ display: "flex", alignItems: "flex-start", pt: 1 }}
            >
              <Tooltip title={t("general.labels.mapView") as string}>
                <IconButton
                  onClick={() => {
                    navigate(ModalContent.PARCELSMAP);
                  }}
                >
                  <Map />
                </IconButton>
              </Tooltip>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={11}>
              <SelectWithAutoComplete
                name="service"
                label={t("general.service", { count: 1 })}
                fetchOptions={fetchServiceOptions}
                required
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 container alignItems="center">
          <Grid2 size={11}>
            <Input label="Notizen" name="notes" multiline />
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};
