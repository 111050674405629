import { DateMode } from "@ero/app-common/enums/DateTimeMode";
import { DeleteOrdersRequestQuery } from "@ero/app-common/v2/routes/models/order";
import { GridRowId } from "@mui/x-data-grid";
import { ConfirmDialog } from "Components";
import { BaseDataTable } from "Components/tableV2/baseDataTable/BaseDataTable";
import { ROUTES } from "Constants";
import useDidMountEffect from "Hooks/useDidMountEffect";
import { type AppState } from "Store";
import {
  deleteOrder,
  getOrders,
  resetMeta,
  resetSearch,
  setPaginationModel,
  setSearch,
  setTableHiddenColumns,
} from "Store/orders";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateOrderDialog } from "./components/createOrderDialog/createOrderDialog";
import { useColumns } from "./ordersConfig";

export const Orders: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { state: routerState } = useLocation();

  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const {
    list: ordersList,
    listUpdateLoading: tableLoading,
    hiddenColumns,
    maxCount,
    search,
    loading,
    offset,
    limit,
    createdOrder,
  } = useSelector((state: AppState) => state.orders);

  useEffect(() => {
    if (routerState?.create) setIsCreateOrderModalOpen(true);
  }, [routerState]);

  useEffect(() => {
    if (routerState?.currentPage && routerState?.numberOfRows) {
      dispatch(setPaginationModel(routerState));
    } else {
      dispatch(resetMeta());
    }
    if (routerState?.search) {
      dispatch(setSearch(routerState.search));
    } else {
      dispatch(resetSearch());
    }

    dispatch(getOrders({}));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    setIsCreateOrderModalOpen(false);

    if (createdOrder?.jobDetails?.jobCount) setIsConfirmModalOpen(true);
  }, [createdOrder?.jobDetails?.jobCount]);

  const paginationModel = useMemo(
    () => ({ page: offset / limit, pageSize: limit }),
    [limit, offset],
  );

  const onCloseCreateOrderDialog = useCallback(() => {
    if (routerState?.create) {
      navigate(-1);
    } else {
      setIsCreateOrderModalOpen(false);
    }
  }, [navigate, routerState?.create]);

  const onCloseChangeScreenConfirmModal = useCallback(
    () => setIsConfirmModalOpen(false),
    [],
  );

  const handleTableRowsDelete = useCallback((ids: readonly GridRowId[]) => {
    if (ids.length === 0) {
      return;
    }

    const stringIds = ids.map((id) => id.toString());

    dispatch(deleteOrder({ id: stringIds } as DeleteOrdersRequestQuery));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableToolbarProps = useMemo(
    () => ({
      onAddClick: () => setIsCreateOrderModalOpen(true),
      onDeleteClick: handleTableRowsDelete,
    }),
    [handleTableRowsDelete],
  );

  const setRouterState = useCallback((state) => {
    navigate(ROUTES.MAIN.ORDERS, {
      state,
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      dispatch(setPaginationModel({ currentPage, numberOfRows }));
      dispatch(getOrders({}));
      setRouterState({
        search,
        currentPage,
        numberOfRows,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, setRouterState],
  );

  const handleTableSearch = useCallback(
    (search: string) => {
      dispatch(setSearch(search));
      dispatch(getOrders({ search }));
      setRouterState({
        search,
        currentPage: offset / limit,
        numberOfRows: limit,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, offset, setRouterState],
  );

  const handleRowClick = useCallback(
    (id: number) => {
      navigate(`${ROUTES.MAIN.ORDERS}/${id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onChangeColumnsView = useCallback((values: Record<string, boolean>) => {
    dispatch(setTableHiddenColumns(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeScreenHandler = useCallback((): void => {
    if (!createdOrder) return;
    const createdOrdersDateRestrictions = createdOrder.dateRestrictions;

    let createdOrdersDate: number | undefined;
    switch (createdOrdersDateRestrictions.dateMode) {
      case DateMode.FIXEDDATE:
        createdOrdersDate = createdOrdersDateRestrictions.date;
        break;
      case DateMode.DATERANGE:
        createdOrdersDate = createdOrdersDateRestrictions.startDate;
        break;
      default:
        createdOrdersDate = undefined;
    }

    navigate(`${ROUTES.MAIN.PLANNING}`, {
      state: {
        orderId: createdOrder._id,
        initialDate: createdOrdersDate,
      },
    });

    setIsConfirmModalOpen(false);
  }, [createdOrder, navigate]);

  const columns = useColumns();

  return (
    <>
      <BaseDataTable
        searchQuery={routerState?.search}
        columnVisibilityModel={hiddenColumns}
        paginationModel={paginationModel}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={loading || tableLoading}
        maxCount={maxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={handleRowClick}
        rows={ordersList}
        toolbarProps={tableToolbarProps}
      />
      {isCreateOrderModalOpen && (
        <CreateOrderDialog
          open={isCreateOrderModalOpen}
          onClose={onCloseCreateOrderDialog}
        />
      )}
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        onApprove={changeScreenHandler}
        onClose={onCloseChangeScreenConfirmModal}
        title={t("orders.created")}
        text={t("orders.navigateToCalendar")}
      />
    </>
  );
};
