import { IconButton, useTheme } from "@mui/material";
import React from "react";

import { LANGUAGE } from "@ero/app-common/enums/language";
import { Tooltip } from "@mui/material";
import { AppState } from "Store";
import { setLanguage } from "Store/auth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const flags = Object.values(LANGUAGE).reduce((map, language) => {
  map[language] = (
    <img src={`https://flagcdn.com/${language}.svg`} width="30" />
  );
  return map;
}, {} as { key: LANGUAGE; value: JSX.Element });

export const LangChooseSwitch: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state: AppState) => state.auth.selectedLang);

  const toggleState = () => {
    if (language == LANGUAGE.DE) dispatch(setLanguage(LANGUAGE.EN));
    else dispatch(setLanguage(LANGUAGE.DE));
  };

  const theme = useTheme();

  return (
    <>
      <Tooltip title={t("auth.login.changeLanguage") as string}>
        <IconButton
          onClick={toggleState}
          size="large"
          sx={{
            width: "52px",
            height: "52px",
            boxShadow: "0px 10px 20px #00000029",
            background: theme.palette.background.default,
            "&:hover": {
              background: theme.palette.background.paper,
            },
          }}
        >
          {flags[language]}
        </IconButton>
      </Tooltip>
    </>
  );
};
