import { Add, Remove } from "@mui/icons-material";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IZoomControlV2 {
  zoom: number;
  onZoomChanged: (zoom: number) => void;
}

export const ZoomControlV2: React.FC<IZoomControlV2> = ({
  zoom,
  onZoomChanged,
}) => {
  const [t] = useTranslation();

  const increaseZoom = useCallback(
    (zoom: number) => {
      if (zoom !== 20) onZoomChanged(zoom + 1);
    },
    [onZoomChanged],
  );

  const decreaseZoom = useCallback(
    (zoom: number) => {
      if (zoom !== 1) onZoomChanged(zoom - 1);
    },
    [onZoomChanged],
  );

  return (
    <ButtonGroup orientation="vertical" color="inherit" variant="contained">
      <Tooltip title={t("components.map.controlButtons.zoomIn")}>
        <Button
          onClick={() => increaseZoom(zoom)}
          sx={{
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            height: 50,
            color: "rgba(0, 0, 0, 0.87)",
            bgcolor: "#e0e0e0",
            "&:hover": { bgcolor: "#f5f5f5" },
          }}
        >
          <Add />
        </Button>
      </Tooltip>
      <Tooltip title={t("components.map.controlButtons.zoomOut")}>
        <Button
          onClick={() => decreaseZoom(zoom)}
          sx={{
            borderBottomLeftRadius: "50%",
            borderBottomRightRadius: "50%",
            height: 50,
            color: "rgba(0, 0, 0, 0.87)",
            bgcolor: "#e0e0e0",
            "&:hover": { bgcolor: "#f5f5f5" },
          }}
        >
          <Remove />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
