import { PlotOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type Action } from "redux";
import { RequestBody, type GetTableDataParams } from "Types";

export enum PLOTS_ACTION_TYPES {
  GET_INITIAL_PLOTS_REQUEST = "plots/get-plots-initial-request",
  GET_INITIAL_PLOTS_SUCCESS = "plots/get-plots-initial-success",
  GET_INITIAL_PLOTS_ERROR = "plots/get-plots-initial-error",
  GET_PLOTS_REQUEST = "plots/get-plots-request",
  GET_PLOTS_FOR_PARCEL_REQUEST = "plots/get-plots-for-parcel-request",
  GET_PLOTS_SUCCESS = "plots/get-plots-success",
  CREATE_PLOT = "plots/create-plot",
  CREATE_PLOT_FOR_PARCEL = "plots/create-plot-for-parcel",
  UPDATE_PLOT = "plots/update-plot",
  DELETE_PLOTS = "plots/delete-plots",
  DELETE_PLOTS_OF_PARCEL = "plots/delete-plots-of-parcel",
  SET_LOADING = "plots/set-loading",
  SET_SUCCESS = "plots/set-success",
  RESET_META = "plots/reset-meta",
  SET_ERROR = "plots/set-error",
  RESET_STATE = "plots/reset-state",
  SET_TABLE_HIDDEN_COLUMNS = "plots/set-table-hidden-columns",
  CHANGE_COLUMNS_ORDER = "plots/change-columns-order",
}

export interface ISetLoadingAction extends Action {
  type: PLOTS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: PLOTS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: PLOTS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: PLOTS_ACTION_TYPES.RESET_META;
}

export interface IGetInitialPlotsRequestAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_REQUEST;
  payload: {
    fieldId: string;
    params: GetTableDataParams;
  };
}

export interface IGetInitialPlotsSuccessAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_SUCCESS;
  payload: {
    plots: PlotOutside[];
    maxCount: number;
  };
}

export interface IGetInitialPlotsErrorAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_ERROR;
}

export interface IGetPlotsRequestAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_PLOTS_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface IGetPlotsForParcelRequestAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_PLOTS_FOR_PARCEL_REQUEST;
  payload: { id: number };
}

export interface IGetPlotsSuccessAction extends Action {
  type: PLOTS_ACTION_TYPES.GET_PLOTS_SUCCESS;
  payload: {
    plots: PlotOutside[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface ICreatePlotAction extends Action {
  type: PLOTS_ACTION_TYPES.CREATE_PLOT;
  payload: { data: RequestBody<"/plots/create"> };
}

export interface ICreatePlotForParcelAction extends Action {
  type: PLOTS_ACTION_TYPES.CREATE_PLOT_FOR_PARCEL;
  payload: { data: RequestBody<"/plots/create"> };
}

export interface IUpdatePlotAction extends Action {
  type: PLOTS_ACTION_TYPES.UPDATE_PLOT;
  payload: { updateData: RequestBody<"/plots/update">; id: number };
}

export interface IDeletePlotsAction extends Action {
  type: PLOTS_ACTION_TYPES.DELETE_PLOTS;
  payload: { ids: number[] };
}

export interface IDeletePlotsOfParcelAction extends Action {
  type: PLOTS_ACTION_TYPES.DELETE_PLOTS_OF_PARCEL;
  payload: { ids: number[] };
}

export interface IResetStateAction extends Action {
  type: PLOTS_ACTION_TYPES.RESET_STATE;
}

export interface ISetTableHiddenColumns extends Action {
  type: PLOTS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IChangeColumnsOrder extends Action {
  type: PLOTS_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type PlotsAction =
  | IGetInitialPlotsRequestAction
  | IGetInitialPlotsSuccessAction
  | IGetInitialPlotsErrorAction
  | IGetPlotsRequestAction
  | IGetPlotsForParcelRequestAction
  | IGetPlotsSuccessAction
  | ICreatePlotAction
  | IDeletePlotsAction
  | IDeletePlotsOfParcelAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IResetStateAction
  | ISetTableHiddenColumns
  | IChangeColumnsOrder;
