import { FindInPageTwoTone } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

interface INoSearchResultsPlaceholder {
  title: string;
  text: string;
}
export const NoSearchResultsPlaceholder: React.FC<
  INoSearchResultsPlaceholder
> = ({ title, text }) => {
  return (
    <Box mt={4} textAlign="center">
      <FindInPageTwoTone fontSize="large" color="primary" />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};
