import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Box } from "@mui/material";
import React, { Dispatch, useMemo } from "react";
import { Steps } from "../steps";
import { GeneralScreen } from "./generalScreen/generalScreen";
import { JobCreationScreen } from "./jobCreationScreen/jobCreationScreen";
import { SelectDateScreen } from "./selectDateScreen/selectDateScreen";
import { SelectParcelScreen } from "./selectParcelScreen/selectParcelScreen";
import { SelectServiceScreen } from "./selectServiceScreen/selectServiceScreen";

interface IDialogContents {
  selectedParcels: number[];
  setSelectedParcels: Dispatch<React.SetStateAction<number[]>>;
  selectedServices: number[];
  setSelectedServices: Dispatch<React.SetStateAction<number[]>>;
  allParcelsOfCustomer: ParcelsResponseBody["data"];
  setAllParcelsOfCustomer: Dispatch<
    React.SetStateAction<ParcelsResponseBody["data"]>
  >;
  activeStep: Steps;
  isFullScreen: boolean;
}

export const DialogContents: React.FC<IDialogContents> = ({
  selectedParcels,
  setSelectedParcels,
  selectedServices,
  setSelectedServices,
  allParcelsOfCustomer,
  setAllParcelsOfCustomer,
  activeStep,
  isFullScreen,
}) => {
  const content = useMemo(() => {
    switch (activeStep) {
      case Steps.GENERAL:
        return <GeneralScreen setSelectedParcels={setSelectedParcels} />;
      case Steps.PARCELS:
        return (
          <SelectParcelScreen
            allParcelsOfCustomer={allParcelsOfCustomer}
            setAllParcelsOfCustomer={setAllParcelsOfCustomer}
            selectedParcels={selectedParcels}
            setSelectedParcels={setSelectedParcels}
            isFullScreen={isFullScreen}
          />
        );
      case Steps.SERVICES:
        return (
          <SelectServiceScreen
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
          />
        );
      case Steps.OVERVIEW:
        return (
          <Box p={2}>
            <JobCreationScreen
              allParcelsOfCustomer={allParcelsOfCustomer}
              selectedServices={selectedServices}
              selectedParcels={selectedParcels}
            />
          </Box>
        );
      case Steps.DATE:
        return <SelectDateScreen />;
    }
  }, [
    allParcelsOfCustomer,
    setAllParcelsOfCustomer,
    activeStep,
    selectedServices,
    selectedParcels,
    setSelectedParcels,
    setSelectedServices,
    isFullScreen,
  ]);

  return content;
};
